import React from "react";
import { StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
  root: {
    flex: "1",
    backgroundImage: 'url("./images/banner_papper.png")',
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    background: "cover",
    "@media(max-width: 795px)": {
      backgroundSize: "cover",
      background: "cover",
      backgroundImage: 'url("./images/header-25-de-mayo-responsive-A.png")',
    },
  },
  Content: {
    minHeight: 600,
    "@media(max-width: 795px)": {
      minHeight: 500,
    },
  },
});

const BannerPaper = ({ children }: { children: any }) => {
  return (
    <div className={css(styles.root)}>
      <div className={css(styles.Content)}>{children}</div>
    </div>
  );
};
export default BannerPaper;
