import React from "react";
import { Link, useLocation } from "react-router-dom";


import {
  Header,
  Container,
  BannerPaper,
  BorderedButton,
  CollapsableSection,
  ImageBox,
  BandcampPlayer,
} from "../ui";
import { StyleSheet, css } from "aphrodite";
import { useState } from "react";
import { useEffect } from "react";
import Img from "../ui/components/Img";
import AudioPlayer from "../ui/components/AudioPlayer";

const styles = StyleSheet.create({
  Content: {
    flex: 1,
    margin: "0",
    maxWidth: "100%",
    boxSizing: "border-box",
    minHeight: 100,
    backgroundColor: "#fff2a6",
    paddingBottom: 100,
    // height: 450,
    width: "100%",
    position: "relative",
  },
  Column: {
    flex: 1,
    //paddingTop: 44,
    width: 430,
    "@media(max-width: 795px)": {
      paddingTop: 0,
      paddingLeft: 14,
      paddingRight: 14,
      width: "auto",
      maxWidth: 500,
      margin: "auto",
    },
  },
  ColumnText: {
    fontSize: "1rem",
    textAlign: "center",
    fontFamily: "Work Sans",
    lineHeight: "24px",
  },
  ColumnContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems:"center",
    "@media(max-width: 795px)": {
      flexDirection: "column",
    },
  },
  MarginText: {
    marginRight: 40,
  },
  BannerImg: {
    maxWidth: 220,
    //marginTop: 20,
  },
  Title: {
    fontFamily: "Alegreya",
    fontSize: 74,
    fontWeight: "bold",
    "@media(max-width:795px)": {
      fontSize: "48px",
      lineHeight: "65px",
    },
  },
  SubTitle: {
    fontFamily: "Alegreya",
    fontSize: 36,
    whiteSpace:"nowrap",
    "@media(max-width:950px)": {
      whiteSpace:"normal",
    },
    "@media(max-width:795px)": {
      fontSize: "28px",
    },
  },
  Description:{
    fontFamily:"Work Sans",
    fontSize:"18px",
    lineHeight: "28px",
    fontWeight:500,
    marginTop:20,
  },
  FloatingBannerSpan: {
    width: "100%",
    marginTop: 20,
    textAlign: "center",
    fontFamily: "Work Sans, sans-serif;",
    fontWeight: 600,
    fontSize: "1rem",
    fontStyle: "italic",
    display: "block",
    whiteSpace: "nowrap",
    "@media(max-width: 500px)": {
      whiteSpace: "normal",
    },
  },
  TitlesContainer: {
    textAlign: "center",
    marginTop: 60,
  },
  PlayerContainer: {
    marginTop: 40,
    marginBottom: 20,
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "500px",
  },
  ButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    marginTop: 72,
  },
  Button: {
    marginRight: 20,
    //marginBottom: 20,
    minHeight: 28,
    padding: "0px 10px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    "@media(max-width: 440px)": {
      marginBottom: 10,
      marginTop: 10,
    },
  },
  SectionsContainer: {
    marginTop: 50,
  },
  HighlightedItem: {
    paddingRight: 2,
    marginRight: 1,
    backgroundColor: "#FFF",
  },
  TextContent: {
    maxWidth: 900,
    width: "100%",
    fontSize: 18,
    fontFamily: "Work Sans",
    lineHeight: "28px",
  },
  TextContentContainer: {
    display: "flex",
    justifyContent: "center",
  },
  CenteredContent: {
    display: "flex",
    justifyContent: "center",
  },
  BlueText: {
    fontFamily: "Alegreya",
    lineHeight: "34px",
    fontSize: 27,
    fontWeight:400,
    color: "#0f6cfc",
    marginTop: 50,
    marginBottom: 50,
  },
  MarginTopContent: {
    marginTop: 40,
    marginBottom: 40,
  },
  PartituraText: {
    color: "#0f6cfc",
    fontFamily: "Work Sans",
    //fontWeight: 600,
    fontSize: 21,
  },
  LineaPartitura: {
    marginTop: 40,
    marginBottom: 20,
    fontFamily: "Alegreya",
    color: "#000",
    fontWeight: 500,
    fontSize: 27,
    "@media(max-width: 795px)": {
      lineHeight: "35px",
    },
  },

  NotaFlotante: {
       //position: "relative",
       top: -25,
       color: "#0f6cfc",
       fontFamily: "Work Sans",
       fontWeight: 600,
       fontSize: 20,
       /*LC */
       left:0,
       position: "absolute",
       width: "100%",
       textAlign:"center",
  },
  SilabaSubrayada: {
    textDecoration: "underline",
  },
  ImgAllWidth: {
    width: 700,
    marginBottom: 72,
    marginTop: 50,
  },
  ImgDefault: {
    marginBottom: 30,
    marginTop: 30,
  },
  BoldTitle: {
    fontFamily: "Alegreya",
    fontSize: 25,
    fontWeight: "bold",
    marginTop: 60,
    marginBottom: 20,
  },
  BoldTitleNoMarginTop:{
    fontFamily: "Alegreya",
    fontSize: 25,
    fontWeight: "bold",
    marginBottom: 20,
  },
  SemiBoldText: {
    fontWeight: 600,
  },
  TwoColumnsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    "@media(max-width: 939px)": {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  TwoColumnsColumnContainer: {
    marginTop: 62,
    paddingBottom: 20,
  },
  ImageColumnObra: {
    marginTop: 10,
    maxWidth: 427,
  },
  PictureBoxContainerText: {
    display:"flex",
    justifyContent:"space-evenly",
  },
  Desktop: {
    display: "block",
    "@media(max-width: 795px)": {
      display: "none",
    },
  },
  Responsive: {
    display: "none",
    "@media(max-width: 795px)": {
      display: "block",
    },
  },
  marginAuto: {
    display: "block",
    margin: "40px auto",
  },
  DobleBox:{
    display:"flex",

  },
  BannerTextJulio:{
    //paddingBottom: 44,
    paddingTop: 50,
    fontSize: "1rem",
    textAlign: "center",
    fontFamily: "Work Sans",
    lineHeight: "24px",
  },
  width100:{
    width:"100%",
  },
  MarginTopButtom:{
    margin:"44px 0",
  },
  rela:{
    position:"relative",
  },
  nowrap:{
    whiteSpace:"nowrap",
    "@media(max-width: 400px)": {
      lineHeight: "70px",
    },
  },
  rotate180:{
    transform: "rotate(180deg)",
  },
  mixer:{
    textDecoration:"underline",
    color: "#0f6cfc",
    fontWeight: 600,
  }
});

const mapWidthNotes = {
  F: 9.53,
  Bb: 28.08,
  C7: 27.41,
  G: 12.53,
  Dm: 33.86,
  A7: 25.05,
};

const Note = ({ text, note }: { text: string, note: string }) => {
  /*const marginRight = mapWidthNotes[note]
    ? -mapWidthNotes[note]
    : -1 * note.length * 16;
  const stylesNote = StyleSheet.create({
    Note: {
       left: -1 * (text.length * 12 - (text.length * 12 + marginRight) / 2),
      marginRight: marginRight, 
    },
  });*/
  if(text ==""){
    return(
    <div style={{display:"inline-block", position:"relative"}}>
        <span className={css(styles.SilabaSubrayada)}>{text}</span>
        <span className={css(styles.NotaFlotante,styles.rela)}>{note}</span>
      </div>
    )  
  }else{
    return (
      <div style={{display:"inline-block", position:"relative"}}>
        <span className={css(styles.SilabaSubrayada)}>{text}</span>
        <span className={css(styles.NotaFlotante)}>{note}</span>
      </div>
    );
  }
  
};

const NineJulyFestScreen = () => {
  const [sectionSelected, setSectionSelected] = useState(null);
  const [windowSize, setWindowSize] = useState({
    windowWidth: window.innerWidth,
  });
  const [columnSelected, setColumnSelected] = useState(0);

  const managedState = windowSize?.windowWidth <= 795;

  const handleResize = (e) => {
    setWindowSize({ windowWidth: window.innerWidth });
  };

  const windowOpen= ()=>{
    window.open("files/PartituraLa-primavera-Vivaldi-y-Chamame.pdf");
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleToggle = (section) => {
    console.log(section);
    if (sectionSelected === section) {
      setSectionSelected(null);
      return;
    }
    setSectionSelected(section);
  };
  return (
    <div className={css(styles.Content)}>
      <BannerPaper>
        {/* <span className={css(styles.FloatingBannerSpan)}>
          Nuestra patria antes y ahora
        </span> */}
        <Container>
          <div className={css(styles.ColumnContainer)}>
            
            <span className={css(styles.BannerTextJulio)}>
                  
                Español: vengo a civilizarte. <br />
                Indio: yo me civilizo si quiero... ¿quieren guerra?<br />
                Español: si, queremos ¿y qué hay? ¿qué mirás? Ustedes buscan guerra porque están en el país de ustedes.<br />
                Indio: ¡y vos que te hacés el guapito porque venís de España, gallego pichi<br />
                <Img
                src="/images/linea-historias-grandiosas.png"
                className={css(styles.width100,styles.rotate180)}
              />
            </span>
            <div
              className={css(
                styles.Column,
                styles.ColumnText,
                //styles.MarginText
              )}
            >
              
              <span className={css(styles.FloatingBannerSpan)}> 
                Para descolonizarnos
                </span> 
              
              <Img
                className={css(styles.BannerImg)}
                src="/images/nena.png"
              />
                
            </div>
            
          </div>
        </Container>
      </BannerPaper>
      <Container>
        <div className={css(styles.TitlesContainer)}>
          <div className={css(styles.Title)}>9 de julio.</div>
          <div className={css(styles.Title)}>Rap independiente</div>
          <div className={css(styles.SubTitle)}>
            –Con tecladito y armónica–
          </div>
          <div className={css(styles.PlayerContainer)}>
            <BandcampPlayer
              trackSrc="track=3819269011"
              size="small"
              height={"42px"}
              width={"100%"}
            />
          </div>
          <div className={css(styles.ButtonsContainer)}>
            <BorderedButton
              text={"descargar canción"}
              onClick={() => {
                window.open("files/rap-Independiente.mp3");
              }}
              styles={styles.Button}
            />
          </div>
        </div>
      </Container>
      <div className={css(styles.SectionsContainer)}>
        <CollapsableSection
          fixed={managedState}
          // opened={sectionSelected === "a"}
          /*toggle={() => {
            handleToggle("a");
          }}*/
          letter="A."
          title="Me toca el acto en una semana"
          pictureUrl="/images/letter_banner/a-me-toca-el-acto.png"
          pictureHoverUrl="/images/letter_banner/a-me-toca-el-acto-act.png"
        >
          <Container>
            <div className={css(styles.TextContentContainer)}>
              <div className={css(styles.TextContent)}>
              Para preparar una actuación con poco tiempo, transmitir la versión completa grabada del Rap que
                compusieron alumnos de 9 años.<br /><br />
                Al empezar, las chicas y los chicos sentados adelante en el suelo, gritan solamente “¡independencia!”
                avisados por el “in, in” que anticipa la palabra. Después de hacerlo dos veces, los que se sienten
                bailarines se ubican para realizar una coreografía. Los que prefieren no bailar, van más atrás, a
                participar del juego de las sillas.
                <br />
                <br />
                <ImageBox
                  textContainerStyles={styles.PictureBoxContainerText}
                  //styles={styles.ImgDefault}
                  pictureUrl="/images/baile-y-sillas.png"
                  
                />
                <br />
                <div className={css(styles.BoldTitle)}>Coreografía</div>
                Lo más eficaz suele ser que los cuatro o cinco alumnos que eligieron bailar realicen los movimientos
                que les surgen espontáneamente estableciendo una misma acción sólo en el estribillo. Por ejemplo:
                cada vez que se escucha “in, in, independencia, yo me independizo”, todos hunden la cintura como si
                alguien los golpeara en el estómago y luego se enderezan rápidamente, quietos y erguidos como
                estatuas, sonriendo, y luego vuelven a moverse.

                <div className={css(styles.BoldTitle)}>Juegos de las sillas</div>
                Los chicos corren alrededor de una rueda de sillas mientras se escucha la música. Cada vez que la
                maestra grita ¡ya! se sientan cada uno en una. La maestra saca una silla y al próximo ¡Ya! se ubican
                todos compartiendo los asientos que hay. Al ir sacando más sillas terminará la canción cuando estén
                los chicos trepados como pueden, ayudándose unos a otros para no caer de sólo dos sillas. Si el
                grado es muy numeroso se harán dos ruedas de sillas. Y entonces, quietos como estatuas, mirando al
                público se pueden ver como una hermosa composición corporal de ayuda mutua.
                <br />
                <br />
                En una forma muy divertida se transmite una buena idea de unidad y colaboración mientras cada
                persona hace lo que quiere con su cuerpo. Es un buen mensaje ya que en muchos actos los chicos se
                encuentran tan rígidos corporalmente que parecen festejar la humillación en lugar de la
                independencia. Cuando se acerca el final y el coro de la grabación dice: “yo hago lo que puedo, yo
                hago lo que quiero, etc.”, la maestra hace un gesto: “¡vamos!” y se acercan todos al borde del
                escenario para decir las palabras con la grabación y pegar un gran salto en el último “in, in,
                independencia”.
                <br />
                <br />
              </div>
            </div>
          </Container>
        </CollapsableSection>

        <CollapsableSection
          fixed={managedState}
          
          //opened={sectionSelected === "b"}
          /*toggle={() => {
            handleToggle("b");
          }}*/
          letter="B."
          title="Voy a probar sobre la grabación"
          pictureUrl="/images/letter_banner/b-voy-a-probar.png"
          pictureHoverUrl="/images/letter_banner/b-voy-a-probar-act.png"
        >
          <Container>
            <div className={css(styles.TextContentContainer)}>
              <div className={css(styles.TextContent)}>
              <ImageBox
                  textContainerStyles={styles.PictureBoxContainerText}
                  //styles={styles.ImgDefault}
                  pictureUrl="/images/realizacion-musical.png"
                  
                /> 
                <br />
                <div
                  className={css(
                    styles.CenteredContent,
                    styles.MarginTopContent
                  )}
                >
                  <BorderedButton
                    text={"descargar base para karaoke"}
                    onClick={() => {
                      window.open("files/rap-independiente-karaoke.mp3");
                    }}
                    styles={styles.Button}
                  />
                </div>
                <span>
                Contando con algunos ensayos, los chicos harán una realización musical y necesitaremos:
                </span><br /><br />
                <span className={css(styles.HighlightedItem)}>1.</span>
                <span className={css(styles.SemiBoldText)}>
                Cuatro solistas.
                </span>{" "}
                En lo posible las graciosas y los divertidos del grado, para decir con voces
                ocurrentes una estrofa cada uno:
                <br />
                <br />
                <div className={css(styles.BlueText)}>
                  Dependiente de la tele <br />
                  de la coca<br />
                  de la guita<br />
                  de la moda<br />
                  ¡in, in, independencia!<br />
                  yo me independizo.<br /><br />

                  Dependiente de los likes<br />
                  Seguidores<br />
                  Si es viral<br />
                  Si es youtuber<br />
                  ¡in, in, independencia!<br />
                  yo me independizo. Men<br /><br />

                  Dependiente del que manda<br />
                  del que asusta<br />
                  del que grita<br />
                  del que insulta<br />
                  ¡in, in, independencia!<br />
                  yo me independizo.<br />
                  Dependiente de tu madre<br />
                  de tu padre<br />
                  de tu hermano<br />
                  ¡y del gato!<br />
                  ¡in, in, independencia!<br />
                  yo me independizo.<br />
                </div>
                <span className={css(styles.HighlightedItem)}>2.</span>
                <span className={css(styles.SemiBoldText)}>Algunos y algunas percusionistas.</span><br /> 
                Para acompañar el rap:<br /> 
                • Latas de cacao o leche en polvo (contar 1, 2, 3) y tocar «pum»<br /> 
                • Baldes de plástico tocar «tun, tun, páa»<br /> 
                Para acompañar la cumbia:<br /> 
                • Panderetas o maracas hechas con latitas
                <br />
                <br />
                <span className={css(styles.HighlightedItem)}>3.</span>
                <span className={css(styles.SemiBoldText)}>Varias relatoras y relatores de fútbol.</span>
                <br />
                <br />
                
                <span className={css(styles.HighlightedItem)}>4.</span>
                <span className={css(styles.SemiBoldText)}>
                Solistas para hacer ritmos,
                </span>{" "}
                con la voz (beatbox), si hay micrófono.
                <br />
                <br />
                <span className={css(styles.HighlightedItem)}>5.</span>
                <span className={css(styles.SemiBoldText)}>Dos tecladistas, </span>
                improvisarán escalas con las teclas negras para acompañar la cumbia. Aunque
                nunca hayan tocado un teclado, comprobarán que cualquier melodía de la escala pentatónica de
                cinco notas, suena tremenda sobre esas guitarras.
                <br />
                <br />
                Los chicos que faltaron y no pudieron ensayar también tendrán su responsabilidad: gritar fuerte “in,
                in, independencia” para que los demás se acuerden de hacerlo.
                <br />
                <br />
                Un coro final integrado por todos los chicos juntos dirá el último texto empezando suavemente y
                cada vez más fuerte hasta terminar en un grito:
                <div className={css(styles.BlueText)}>
                Yo hago lo que puedo<br />
                yo hago lo que quiero<br />
                yo hago lo que creo<br />
                yo hago lo que siento<br />
                yo hago lo que es bueno<br />
                in, in, independencia<br />
                (en este último verso todos los chicos saltarán cuatro veces con fuerza en el piso acentuando las
                palabras).
                </div>
                La grabación de la base en karaoke tiene un aviso de un ¡va! para indicar la entrada de cada solista.
                <br />
                <br />
              </div>
            </div>
          </Container>
        </CollapsableSection>

        <CollapsableSection
          
          fixed={managedState}
          // opened={sectionSelected === "c"}
          /* toggle={() => {
            handleToggle("c");
          }}*/
          letter="C."
          title="Aprendo la canción y me animo sin pista"
          pictureUrl="/images/letter_banner/c-aprendo-la-cancion.png"
          pictureHoverUrl="/images/letter_banner/c-aprendo-la-cancion-act.png"
        >
          <Container>
            <div className={css(styles.TextContentContainer)}>
              <div className={css(styles.TextContent)}>
                Los docentes que cantan con alegría y tocan percusión, guitarra o piano con comodidad pueden
                ensayar esta partitura prescindiendo del celular o computadora.
                <br />
                <div
                  className={css(
                    styles.CenteredContent,
                    styles.MarginTopContent
                  )}
                >
                  {/* <div className={css(styles.ButtonsContainer)}> */}
                  <BorderedButton
                    text={"descargar letra"}
                    onClick={() => {
                      window.open("files/rap-independiente-letra.docx");
                    }}
                    styles={styles.Button}
                  />
                  {/* </div> */}
                  
                </div>
                De acuerdo a la edad y experiencia del grado se podrá hacer una versión simplificada con voces y
                percusión solamente, o una versión completa dándoles partes a los alumnos y alumnas que tengan
                más desenvoltura con instrumentos. El docente acompañará con acordes en Re# menor (en guitarra,
                poner transporte en el primer traste y tocar Re menor) y los chicos en el teclado solo tocan las
                teclas negras, de a uno. También hemos visto versiones de este rap realizadas solo con las voces y
                un teclado –órgano electrónico de los que vienen con sección de ritmos– siguiendo este esquema:
                <br />
                <br />
                <span className={css(styles.HighlightedItem)}>1.</span>
                <span className={css(styles.SemiBoldText)}>
                Empieza el ritmo que se elige y selecciona,
                </span>{" "}
                de rock, de salsa, o samba. Si no hay instrumento en la
                escuela, será el pianito aportado por una familia, conectado al equipo del colegio, o al parlante del auto.
                <br />
                <br />
                <span className={css(styles.HighlightedItem)}>2.</span>
                <span className={css(styles.SemiBoldText)}>Sobre el ritmo, </span>
                los solistas dicen el texto del Rap y todos los chicos gritan “in, in, independencia”
                <br />
                <br />
                <span className={css(styles.HighlightedItem)}>3.</span>
                <span className={css(styles.SemiBoldText)}>Apretando la tecla de acompañamiento, </span>
                se agrega al ritmo la armonía que ya viene programada.
                <br />
                <br />
                
                <span className={css(styles.HighlightedItem)}>4.</span>
                <span className={css(styles.SemiBoldText)}>
                Vuelven a repetir todo el texto.
                </span>{" "}
                <br />
                <br />
                <span className={css(styles.HighlightedItem)}>5.</span>
                <span className={css(styles.SemiBoldText)}>Final: </span>
                todos dicen el último texto, “yo hago lo que puedo, etc.”
                <br />
                <br />
                Un grado de una escuela de Rosario lo hizo con tecladito ¡y sin la maestra de música! que tenía otro
                acto en ese mismo momento.
                <br />
                <br />
              </div>
            </div>
          </Container>
        </CollapsableSection>
        <CollapsableSection
          
          fixed={managedState}
          // opened={sectionSelected === "d"}
          /* toggle={() => {
            handleToggle("d");
          }}*/
          letter="D."
          title="Me encanta hacer algo distinto"
          pictureUrl="/images/letter_banner/d-me-encanta-hacer.png"
          pictureHoverUrl="/images/letter_banner/d-me-encanta-hacer-act.png"
        >
          <Container>
            <div className={css(styles.TextContentContainer)}>
              <div className={css(styles.TextContent)}>
                <div className={css(styles.BoldTitleNoMarginTop)}>Invitando al público</div>
                Con los padres que concurren al acto podemos realizar distintas actividades:
                <br />
                <br />
                <span className={css(styles.HighlightedItem)}>1.</span>
                <span className={css(styles.SemiBoldText)}>
                Repartirles copias con la letra del rap
                </span>{" "}
                que muestran los chicos en el acto y a continuación de ellos
                gritar ¡vamos! ¡lo leemos todos! y repetimos el tema juntos.
                <br />
                <br />
                <span className={css(styles.HighlightedItem)}>2.</span>
                <span className={css(styles.SemiBoldText)}>Enseñarles, desde el escenario, un pasito facilísimo para bailarlo:</span><br />
                1 – 2 (pasos adelante)<br />
                3 – 4 (palmear en el lugar)<br />
                1 – 2 (pasos atrás)<br />
                3 – 4 (palmear en el lugar)
                <br />
                <br />
                Y si los vemos felices con el baile ¡no hay más que continuarlo! A veces un maestro sabe pedir
                consignas por micrófono: “se armó el fiestón”, -dice- “¡una persona grande con dos chicos! ¡ahora
                copiando al grande! ¡moviendo los pies! ¡con la mano en la cabeza del otro!, etc.”
                Y nos animamos a poner una buena cumbia para terminar llamando a la directora, a la portera, los
                chicos, los grandes y los abuelos también, para bailar juntos y acordarnos de que la escuela puede
                ser un lugar para alegrarnos la vida.
                <br />
                <br />
                Y ¡hasta la próxima fiesta!, y una fila moviendo las manos para ir saliendo del recinto.
                <div className={css(styles.BoldTitle)}>Invitando a algunos músicos</div>
                Hemos visto muchas veces a madres, padres y hermanos músicos, incluirse en los actos.
                Algunos tienen un conjunto y los invitamos. En la segunda parte del rap pueden acompañar con su
                música, habiéndoles enviado previamente la grabación. Y después les pedimos “¿nos regalan un tema
                de su repertorio, por favor?”<br/><br/>
                Otros son músicos de fogón y están felices de aportar su alegría de tocar. Y agregamos en nuestro
                rap una percusión, una trompeta, una guitarra, un violín, o lo que aparezca. Ya hemos escuchado
                variadas y hermosas versiones de este tema, que tomaban distintos rumbos al incluirse
                instrumentistas de orquesta sinfónica, o del folklore, del tango, o del rock.

                <div className={css(styles.BoldTitle)}>Improvisación con rap</div>
                El rap con sus variantes de ritmo y poesía, hip hop y freestyle, es un género que surge como una
                forma de expresión libre jugando con el lenguaje y con lo que se siente, sin un excesivo control. Es
                por eso que en muchos lugares es una canción contestataria, en la que afloran problemas
                generacionales y sociales. A los chicos con gran inhibición para mostrar su voz cantando los pone
                muy felices “rapear”.<br/><br/>
                El texto de este rap para el 9 de julio, surgió al improvisar con los chicos sobre sus problemas de
                independencia. Hay otros temas sobre los cuales pueden hacer lo mismo, celebrando lo que les
                guste, sin que haya un acto necesariamente organizado. Por ejemplo festejar el día de la primavera, o
                agradecer a la Madre tierra. A veces con soltar una primera frase se dispara la creación.
                <br />
                <br />
                
                <div>
                <span className={css(styles.SemiBoldText)}>Día de la primavera</span><br />
                No me di cuenta y zas! llegó la primavera<br />
                ¿te digo como era?<br />
                …………………<br />
                te digo lo que brota<br />
                lo que explota, lo que estalla:<br />
                la alegría y la pavada<br />
                las ganas de correr<br />
                el olor de mi sudor<br />
                mirar el cielo<br />
                y no hacer nada<br />
                espiar a mi tortuga<br />
                salir afuera<br />
                reírme a carcajadas<br />
                (continuarlo)...<br />
                </div>
                <br />
                <br />
                <div>
                <span className={css(styles.SemiBoldText)}>Día de la Pachamama</span><br />
                ¡Es que dicen que la tierra te da taaaanto…!<br />
                ¡Vamo a cuidarla!<br />
                ………………………<br />
                En la ventana metimos semillas de un tomate.<br />
                Y creció. Me lo comí yo.<br />
                Mi mamá dice que está podrida<br />
                De la tierra debajo de la cama,<br />
                Que quiere tierra para sembrarla y cuidarla<br />
                Mi papá dice que vamos a poner<br />
                Otra vez los cajones con tierra<br />
                En el baldío…<br />
                Y tenemos lechuga rica, ¡ja! Ensaladita.<br />
                Mi abuela dice que todos volvemos a la tierra<br />
                Y que si siguen tirando glifosato<br />
                Cuando se muera va a tragar veneno a cada rato…<br />
                ¡Es que dicen que la tierra te da taaaanto…!<br />
                ¡Vamo a cuidarla!”<br />
                </div>
                <br />
                <br />
              </div>
            </div>
          </Container>
        </CollapsableSection>
        <CollapsableSection
          fixed={managedState}
          
          // opened={sectionSelected === "d"}
          /* toggle={() => {
            handleToggle("d");
          }}*/
          letter="E."
          title="Fiesta por pantalla"
          pictureUrl="/images/letter_banner/e-acto-por-pantalla.png"
          pictureHoverUrl="/images/letter_banner/e-acto-por-pantalla-act.png"
        >
          <Container>
            <div className={css(styles.TextContentContainer)}>
              <div className={css(styles.TextContent)}>
                <div className={css(styles.BoldTitleNoMarginTop)}>
                Para preparar una actuación en poco tiempo 
                </div>
                Lo único que necesitamos son unos divertidos videos o fotos que nos enviarán los chicos.
                <br /><br />
                Mientras se escucha la grabación del rap de la independencia se verán imágenes de las familias.
                Harán un video o foto con la mamá, los hermanos, la hermana postiza, el abuelo y el gato o quienes
                puedan juntarse ¡sentados uno arriba de otro en una única silla! y con un escrito en la pantalla que
                diga SER INDEPENDIENTE EN TIEMPOS DE PANDEMIA.
                <br /><br />
                <ImageBox
                  styles={styles.ImgDefault}
                  pictureUrl="/images/ser-independiente-en-tiempos.png"
                />
                <br /><br />
                Cuando en la grabación se escucha que empieza el baile, después del "goool", pondremos a todos los
                chicos bailando en su cuadradito durante la clase. Hip hop, saltos, murga, lo que quieran. Y cuando
                escuchamos el coro de muchas voces que dice "yo hago lo que quiero, yo hago lo que puedo, etc", los
                chicos van acercando su cara a la pantalla con sus manos como bocinas y terminan gritando fuerte.
                Sus sonidos no se escucharán pero se percibirán sus intenciones.
                <br /><br />
                <ImageBox
                  styles={styles.ImgDefault}
                  pictureUrl="/images/pantalla-chicos-bailan.png"
                />
                <br /><br />
                ¿Y a perseguir durante dos semanas a toda la clase para que envíen la foto o video familiar?<br />
                No.<br />
                Les explicamos que haremos una práctica de independencia: cada uno decidirá si quiere mandar esa
                foto o no. Cuando pasen unos días la maestra enviará un mensaje a los que no enviaron nada “¿por
                qué decidiste no hacer? Porque estas cansada de la pantalla, o porque querías jugar a los jueguitos
                apps, o porque tu familia está muy ocupada, o porque siempre están usando el único celular, o
                porque querías ver qué pasa si no se hace la tarea?” Cuando la maestra edita el festejo que enviará,
                con las fotos familiares y después los chicos bailando, al final de la canción nombra y agradece a los
                chicos que bailaron, a los que mandaron la imagen familiar y nombra y agradece aclarando las
                distintas respuestas de los que no enviaron nada y tuvieron el coraje de contar su razón de decisión
                independiente.
                <br />
                <div className={css(styles.BoldTitle)}>
                Para preparar una actuación con más tiempo 
                </div>
                Disfrutando de juegos y aprendizajes, la maestra podrá armar una grabación entera con los chicos
                explorando distintas posibilidades
                <br />
                <br />
                <span className={css(styles.HighlightedItem)}>1.</span>{" "}
                <span className={css(styles.SemiBoldText)}>Se escuchan bases de rap y los chicos van cantando o rapeando.</span>{" "}<br />
                (Desde la página web, reproducen bases, con distintas combinaciones y capas de sonido.)
                <br />
                <br />
                • Con el texto escrito en la pantalla, van probando las estrofas que les gustan, divirtiéndose en la
                exploración espontánea de voces y ritmos.<br />
                • Sin ningún texto escrito, se lanzan a rapear libremente. Algunos se animan primero, otros van de a
                poco, y también están los que tienen experiencia de freestyle y quieren probarlo.<br />
                • Se les propone que digan o escriban en un papelito qué cree cada uno que quiere decir la palabra
                independencia. Lo dicen, lo rapean, lo gritan o lo cantan. Y la maestra anota las ideas o graba esa
                clase para registrarlas, para después unirlas en un solo texto. Esta letra se puede escribir en la
                página y descargar como archivo.
                <br />
                <br />
                <span className={css(styles.HighlightedItem)}>2.</span>{" "}
                <span className={css(styles.SemiBoldText)}> Armando bases de rap</span>{" "}<br />
                <Link className={css(styles.mixer)} target="_blank" to="/mixer9dejulio">Los chicos abren esta página</Link> y juegan a combinar sonidos de las bases, eligen y comentan las que
                más les interesan.
                <br />
                <br />
                Las bases para jugar tienen estas tres opciones:<br />
                • Bases beatbox<br />
                • Bases electrónicas<br />
                • Melodías y aires de cumbia     
                <br />
                <br />
                <span className={css(styles.HighlightedItem)}>3.</span>{" "}
                <span className={css(styles.SemiBoldText)}> Poniendo la base desde la página</span>{" "}
                (compartiendo el sonido desde la plataforma usada para las
                clases por internet) arman equipos que van rotando. Cada grupo tiene cantantes o raperos que van
                cantando o rapeando sobre la bases que uno, el “DJ”, va jugando en vivo desde su compu
                (compartiendo el audio).
                <br />
                <br />
                <span className={css(styles.HighlightedItem)}>4.</span>{" "}
                <span className={css(styles.SemiBoldText)}> Editando una producción para enviar el 9 de julio</span>{" "}<br />
                La maestra fue grabando las exploraciones de estas clases, y agregando o no algún material que pide
                que le envíen, selecciona y ordena las ideas que surgieron. A veces tiene un plan previo. Pero no
                podrá evitar los imprevistos: las cosas que quedaron poco interesantes, las grabaciones que sufrieron
                cortes de luz y de internet, y las maravillosas ideas que se les ocurren a los chicos.
                <br />
                <br />
              </div>
            </div>
          </Container>
        </CollapsableSection>
      </div>
    </div>
  );
};

export default NineJulyFestScreen;
