import React, { useEffect, useState } from "react";
import { StyleSheet, css } from "aphrodite";
import { Link } from "react-router-dom";

const buttonStyles = StyleSheet.create({
  root: {
    border: "1px solid #1d75fd",
    padding: 10,
    color: "#1d75fd",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "#1d75fd",
    },
    ":hover .text": {
      color: "#fff2a6",
    },
  },
  text: {
    fontFamily: "Work Sans",
    fontSize: 13,
    textTransform: "uppercase",
    fontWeight: 600,
    ":hover": {
      color: "#fff2a6",
    },
  },
});

const BorderedButton = ({
  text,
  onClick,
  styles,
}: {
  text: string;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  styles?: any;
}) => {
  return (
    <div className={css(buttonStyles.root, styles)} onClick={onClick}>
      <span className={"text " + css(buttonStyles.text)}> {text}</span>
    </div>
  );
};
export default BorderedButton;
