import React,{useState} from 'react';
import Img from './Img'
import { StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
    gifsClass: {
      width:"100%",
    },
  })

const Gifs = ()=>{

    
    let screenWitdh = window.screen.width;
    
    console.log(screenWitdh);
    return (
        <>
        {
            screenWitdh < 1024 ? ( <Img className={css(styles.gifsClass)} src={'/images/gifs/intro-web-responsive.gif'}/>) : ( <Img className={css(styles.gifsClass)} src={'/images/gifs/intro-web-final.gif'}/>)
        }
        </>
    )
}

export default Gifs;