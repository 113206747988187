import React, { useEffect, useState } from "react";
import { StyleSheet, css } from "aphrodite";
import { Link } from "react-router-dom";
import Img from "../components/Img";

const styles = StyleSheet.create({
  root: {
    flex: "1",
  },
  Img: {
    width: "100%",
  },
  Desktop: {
    display: "block",
    "@media(max-width: 650px)": {
      display: "none",
    },
  },
  Responsive: {
    display: "none",
    "@media(max-width: 650px)": {
      display: "flex",
    },
  },
});

const Banner = () => {
  return (
    <div className={css(styles.root)}>
      <Img
        className={css(styles.Img, styles.Desktop)}
        src="/images/header-el-proyecto.png"
        alt="banner"
      />
      <Img
        className={css(styles.Img, styles.Responsive)}
        src="/images/header-el-proyecto-responsive.png"
        alt="banner"
      />
    </div>
  );
};
export default Banner;
