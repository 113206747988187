import React from "react";
import { StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
    VidPepa: {
        marginTop: 20,
        marginBottom: 20,
        width: "100%",
        height: 506,
        "@media(max-width: 945px)": {
            height: 400,
        },
        "@media(max-width: 700px)": {
            height: 300,
        },
        "@media(max-width: 550px)": {
            height: 200,
        },
    },
});

const Video = ({ src }) => {
    return (
        <iframe
            //width="560" height="315"
            src={src}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className={css(styles.VidPepa)}
        ></iframe>
    );
};

export default Video;
