import React, { useEffect, useState } from "react";
import { StyleSheet, css } from "aphrodite";
import { Link } from "react-router-dom";
import Img from "../components/Img";

const boxStyles = StyleSheet.create({
    root: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        border: "4px solid #FFF",
        cursor: "pointer",
        width: "100%",
    },
    Img: { width: "100%" },
    text: {
        backgroundColor: "white",
        padding: 20,
        fontFamily: "Work Sans",
        fontSize: "1rem",
        lineHeight: "22px",
        color: "black",
        textAlign: "center",
        margin: "-3px",
    },
    UnCuarto: {
        width: "25%",
        textAlign: "center",
    },
    HeaderTabla: {
        padding: "10px",
        fontWeight: 600,
    },
});

const ImageBox = ({
    text,
    pictureUrl,
    styles,
    textContainerStyles,
    titulo,
}: {
    text?: string;
    pictureUrl: string;
    styles?: any;
    textContainerStyles?: any;
    titulo?: string;
}) => {
    let guion = "-";
    let arrayText = text?.split(guion);

    return (
        <div className={css(boxStyles.root, styles)}>
            <span className={css(boxStyles.HeaderTabla)}>{titulo}</span>
            <Img src={pictureUrl} className={css(boxStyles.Img)} alt="fiesta" />
            {arrayText != undefined && (
                <div className={css(boxStyles.text, textContainerStyles)}>
                    {arrayText.length > 1 ? (
                        arrayText?.map((par) => {
                            return (
                                <span className={css(boxStyles.UnCuarto)}>
                                    {par}
                                </span>
                            );
                        })
                    ) : (
                        <span>{arrayText[0]}</span>
                    )}
                </div>
            )}
        </div>
    );
};
export default ImageBox;
