import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Header,
  Container,
  BannerPaper,
  BorderedButton,
  CollapsableSection,
  ImageBox,
  BandcampPlayer,
} from "../ui";
import { StyleSheet, css } from "aphrodite";
//import ReactPlayer from "react-player";
import { useState } from "react";
import { useEffect } from "react";
import Img from "../ui/components/Img";
import Video from "modules/home/Video";

const styles = StyleSheet.create({
  Content: {
    flex: 1,
    margin: "0",
    maxWidth: "100%",
    boxSizing: "border-box",
    minHeight: 100,
    backgroundColor: "#fff2a6",
    paddingBottom: 100,
    // height: 450,
    width: "100%",
    position: "relative",
  },
  Column: {
    flex: 1,
    paddingTop: 44,
    width: 430,
    "@media(max-width: 795px)": {
      paddingTop: 0,
      paddingLeft: 14,
      paddingRight: 14,
      width: "auto",
      maxWidth: 500,
      margin: "auto",
    },
  },
  ColumnText: {
    fontSize: "1rem",
    textAlign: "center",
    fontFamily: "Work Sans",
    lineHeight: "24px",
  },
  ColumnContainer: {
    display: "flex",
    flexDirection: "row",
    "@media(max-width: 795px)": {
      flexDirection: "column",
    },
  },
  MarginText:{
    marginRight: 40,
  },
  BannerImg: {
    width: "100%",
    marginTop: 20,
  },
  Title: {
    fontFamily: "Alegreya",
    fontSize: 74,
    fontWeight: "bold",
    "@media(max-width:795px)": {
      fontSize: "54px",
      lineHeight: "65px",
    },
  },
  SubTitle: {
    fontFamily: "Alegreya",
    fontSize: 36,
    "@media(max-width:795px)": {
      fontSize: "28px",
    },
  },
  FloatingBannerSpan: {
    position: "relative",
    width: "100%",
    textAlign: "center",
    top: 278,
    fontFamily: "Work Sans, sans-serif;",
    fontWeight: 600,
    fontSize: "1rem",
    fontStyle: "italic",
    display: "block",
    "@media(max-width: 795px)": {
      paddingTop: 25,
      position: "static",
    },
  },
  TitlesContainer: {
    textAlign: "center",
    marginTop:60,
  },
  PlayerContainer: {
    marginTop: 40,
    marginBottom: 20,
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "500px",
  },
  ButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap:"wrap",
    marginTop: 72,
  },
  Button: {
    marginRight: 20,
    //marginBottom: 20,
    minHeight: 28,
    padding: "0px 10px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    "@media(max-width: 440px)": {
      marginBottom: 10,
      marginTop: 10,
    },
  },
  SectionsContainer: {
    marginTop: 50,
  },
  HighlightedItem: {
    paddingRight: 2,
    marginRight: 1,
    backgroundColor: "#FFF",
  },
  TextContent: {
    maxWidth: 900,
    width: "100%",
    fontSize: 18,
    fontFamily: "Work Sans",
    lineHeight: "28px",
  },
  TextContentContainer: {
    display: "flex",
    justifyContent: "center",
  },
  CenteredContent: {
    display: "flex",
    justifyContent: "center",
  },
  BlueText: {
    fontFamily: "Alegreya",
    lineHeight: "34px",
    fontSize: 27,
    //fontWeight:400,
    color: "#0f6cfc",
    marginTop: 50,
    marginBottom: 50,
  },
  BlueText2: {
    color: "#0f6cfc",
    fontFamily: "Work Sans",
    fontWeight: 600,
    fontSize: 21,
    marginTop: 50,
    marginBottom: 50,
  },
  MarginTopContent: {
    marginTop: 40,
    marginBottom: 40,
  },
  PartituraText: {
    color: "#0f6cfc",
    fontFamily: "Work Sans",
    fontWeight: 600,
    fontSize: 21,
  },
  LineaPartitura: {
    marginTop: 40,
    marginBottom: 20,
    fontFamily: "Alegreya",
    color: "#000",
    fontWeight: 500,
    fontSize: 27,
    "@media(max-width: 795px)": {
      lineHeight: "50px",
    },
  },

  NotaFlotante: {
    //position: "relative",
    top: -25,
    color: "#0f6cfc",
    fontFamily: "Work Sans",
    fontWeight: 600,
    fontSize: 20,
    /*LC */
    left:0,
    position: "absolute",
    width: "100%",
    textAlign:"center",

  },
  SilabaSubrayada: {
    textDecoration: "underline",
  },
  ImgAllWidth: {
    width: 700,
    marginBottom: 72,
    marginTop: 50,
  },
  ImgDefault: {
    marginBottom: 60,
    marginTop: 60,
  },
  BoldTitle: {
    fontFamily: "Alegreya",
    fontSize: 25,
    fontWeight: "bold",
    marginTop: 60,
    marginBottom: 20,
  },
  BoldTitleNoMarginTop:{
    fontFamily: "Alegreya",
    fontSize: 25,
    fontWeight: "bold",
    marginBottom: 20,
  },
  SemiBoldText:{
    fontWeight: 600,
  },
  TwoColumnsContainer: {
    width: "100%",
    display: "flex",
    justifyContent:"space-between",
    "@media(max-width: 939px)": {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  TwoColumnsColumnContainer: {
    marginTop: 62,
    paddingBottom: 20,
  },
  ImageColumnObra: {
    marginTop: 10,
    maxWidth: 427,
  },
  PictureBoxContainerText: {
    minHeight: 110,
  },
  Desktop: {
    display: "block",
    "@media(max-width: 795px)": {
      display: "none",
    },
  },
  Responsive: {
    display: "none",
    "@media(max-width: 795px)": {
      display: "block",
    },
  },
  marginAuto:{
    display:"block",
    margin:"40px auto",
  }
});

const mapWidthNotes = {
  F: 9.53,
  Bb: 28.08,
  C7: 27.41,
  G: 12.53,
  Dm: 33.86,
  A7: 25.05,
};

const Note = ({ text, note }: { text: string; note: string }) => {
  const marginRight = mapWidthNotes[note]
    ? -mapWidthNotes[note]
    : -1 * note.length * 16;
  const stylesNote = StyleSheet.create({
    Note: {
      //left: -1 * (text.length * 12 - (text.length * 12 + marginRight) / 2),
      //marginRight: marginRight,
      
    },
  });

  return (
    <div style={{display:"inline-block", position:"relative"}}>
      <span className={css(styles.SilabaSubrayada)}>{text}</span>
      <span className={css(styles.NotaFlotante, stylesNote.Note)}>{note}</span>
    </div>
  );
};

const TwentyFiveMayFestScreen = () => {
  const [sectionSelected, setSectionSelected] = useState(null);
  const [windowSize, setWindowSize] = useState({
    windowWidth: window.innerWidth,
  });
  const [columnSelected, setColumnSelected] = useState(0);

  const managedState = windowSize?.windowWidth <= 795;

  const handleResize = (e) => {
    setWindowSize({ windowWidth: window.innerWidth });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleToggle = (section) => {
    console.log(section)
    if (sectionSelected === section) {
      setSectionSelected(null);
      return;
    }
    setSectionSelected(section);
  };
  return (
    <div className={css(styles.Content)}>
      <BannerPaper>
        <span className={css(styles.FloatingBannerSpan)}>
          Nuestra patria antes y ahora
        </span>
        <Container>
          <div className={css(styles.ColumnContainer)}>
            <div className={css(styles.Column, styles.ColumnText,styles.MarginText)}>
              {!managedState || columnSelected === 0 ? (
                <span>
                  {" "}
                  La gente de antes era feliz y no como ahora, que no se acaba
                  más la crisis. Era tan feliz que de cada vaca sacaban un
                  churrasquito, lo hacían vuelta y vuelta y tiraban lo demás.
                  Los esclavos hacían la comida mientras tocaba la orquesta y se
                  tomaba mate dulce.
                </span>
              ) : undefined}
              <Img
                className={css(styles.BannerImg, styles.Desktop)}
                src='/images/banner_left_25_may.png'
              />
              {managedState && columnSelected === 0 && (
                <Img
                  onClick={() => setColumnSelected(1)}
                  className={css(styles.BannerImg, styles.Responsive)}
                  src='/images/header-25-de-mayo-responsive-1.png'
                />
              )}
            </div>
            <div className={css(styles.Column, styles.ColumnText)}>
              {!managedState || columnSelected === 1 ? (
                <span>
                  Las luces que ponían eran con velas de aceite de potro que
                  largarían olor a podrido, que por eso la gente se iba a
                  acostar temprano para no sentir el olor.
                </span>
              ) : undefined}
              <Img
                className={css(styles.BannerImg, styles.Desktop)}
                src='/images/banner_right_25_may.png'
              />
              {managedState && columnSelected === 1 && (
                <Img
                  onClick={() => setColumnSelected(0)}
                  className={css(styles.BannerImg, styles.Responsive)}
                  src='/images/header-25-de-mayo-responsive-2.png'
                />
              )}
            </div>
          </div>
        </Container>
      </BannerPaper>
      <Container>
        <div className={css(styles.TitlesContainer)}>
          <div className={css(styles.Title)}>25 de mayo.</div>
          <div className={css(styles.Title)}>Chacarera de la revolución</div>
          <div className={css(styles.SubTitle)}>
            –Con latas y cajas de cartón–
          </div>
          <div className={css(styles.PlayerContainer)}>
            <BandcampPlayer
              trackSrc='track=3251284832'
              size='small'
              height={"42px"}
              width={"100%"}
            />
          </div>
          <div className={css(styles.ButtonsContainer)}>
            <BorderedButton
              text={"descargar partitura"}
              onClick={() => {
                window.open("files/chacarera-del-25-partitura-completa.pdf");
              }}
              styles={styles.Button}
            />
            <BorderedButton
              text={"descargar canción"}
              onClick={() => {
                window.open("files/chaca_25.mp3");
              }}
              styles={styles.Button}
            />
          </div>
        </div>
      </Container>
      <div className={css(styles.SectionsContainer)}>
        <CollapsableSection
          fixed={managedState}
         // opened={sectionSelected === "a"}
          /*toggle={() => {
            handleToggle("a");
          }}*/
          letter='A.'
          title='Me toca el acto en una semana'
          pictureUrl='/images/letter_banner/a-me-toca-el-acto.png'
          pictureHoverUrl='/images/letter_banner/a-me-toca-el-acto-act.png'
        >
          <Container>
            <div className={css(styles.TextContentContainer)}>
              <div className={css(styles.TextContent)}>
                Para preparar una actuación con poco tiempo lo único que tenemos
                que conseguir son:
                <br />
                • Cascabeles o llaves atadas
                <br />
                • Algunas cajas de cartón (de supermercado) <br />
                • Palos para golpearlas (o trozos de manguera) <br />• Celular o
                computadora para conectar al equipo de la escuela. <br />
                <br />
                Siete chicos tendrán unos carteles con las seis estrofas y el
                estribillo de la chacarera. Los que quieran tocarán las cajas
                como bombos. Otros elegirán tocar las llaves.
                <br />
                <br />
                Sin muchas explicaciones, la maestra pondrá la grabación e irá
                haciendo con el grupo lo siguiente:
                <br />
                <br />
                <span className={css(styles.HighlightedItem)}>1.</span>{" "}
                <span className={css(styles.SemiBoldText)}>Introducción:</span> acompañar con las cajas eligiendo los ritmos que
                surjan más naturalmente. <br /><br />
                <span className={css(styles.HighlightedItem)}>2.</span> <span className={css(styles.SemiBoldText)}>Canción:</span>{" "}
                si no hay tiempo de aprenderse toda la letra, cantar solamente:
                “la revolución de mayo" al final de cada estrofa y el
                estribillo: “Patria chica, patria grande"... etc., que va
                acompañado por los cascabeles sonando sin parar.
                <br /><br />
                <span className={css(styles.HighlightedItem)}>3.</span>{" "}
                <span className={css(styles.SemiBoldText)}>Intermedio:</span> repetir los acompañamientos con caja. <br /><br />
                <span className={css(styles.HighlightedItem)}>4.</span> <span className={css(styles.SemiBoldText)}>Canción:</span>{" "}
                hacer esta segunda parte igual que la primera. <br /><br />
                <span className={css(styles.HighlightedItem)}>5.</span> <span className={css(styles.SemiBoldText)}>Cuando
                termina la chacarera:</span> repetir todo el tiempo este ostinato
                hablado rítmicamente: “¡qué, qué, qué pasa!" <br /><br />
                <span className={css(styles.HighlightedItem)}>6.</span> <span className={css(styles.SemiBoldText)}>Cuando
                comience de nuevo la chacarera:</span> haremos otra vez todo el tema,
                pero al empezar la primera estrofa miraremos al chico que tenga
                el cartel con ese texto para que lo muestre y todos lo puedan
                leer ayudados por un “¡vamos a cantar juntos!"". Así vamos
                agregando todas las estrofas de la canción, cantando con toda la
                escuela.<br /><br />
              </div>
            </div>
          </Container>
        </CollapsableSection>

        <CollapsableSection
          fixed={managedState}
          //opened={sectionSelected === "b"}
          /*toggle={() => {
            handleToggle("b");
          }}*/
          letter='B.'
          title='Voy a probar sobre la grabación'
          pictureUrl='/images/letter_banner/b-voy-a-probar.png'
          pictureHoverUrl='/images/letter_banner/b-voy-a-probar-act.png'
        >
          <Container>
            <div className={css(styles.TextContentContainer)}>
              <div className={css(styles.TextContent)}>
                Contando con algunos ensayos los chicos harán una realización
                musical en vivo, tocando y cantando sobre esta base grabada.
                <br />
                <div
                  className={css(
                    styles.CenteredContent,
                    styles.MarginTopContent
                  )}
                >
                  <BorderedButton
                    text={"descargar base para karaoke"}
                    onClick={() => {
                      window.open("files/chaca_25_karaoke.mp3");
                    }}
                    styles={styles.Button}
                  />
                </div>
                <span className={css(styles.HighlightedItem)}>1.</span>
                <span className={css(styles.SemiBoldText)}>Introducción:</span> según la edad y características del grado, el
                maestro elegirá hacer algunos de estos acompañamientos o todos:
                <br />
                Bombo: un alumno con gran soltura rítmica y ganas, tocará todo
                el tiempo una base de chacarera. <br />
                Mesita de un aula: otro alumno realizará un “solo" golpeando con
                palos o baquetas las patas, el estante, o la tapa de la mesita.
                <br />
                Latas de conserva vacías: apenas empieza la grabación el maestro
                les hará una seña a unos seis chicos que tirarán de golpe una
                prolija torre de latas al suelo (¡les encanta la sorpresa que
                provocan!). Las revoluciones consisten en desarmar lo que no
                funcionó y volver a construir. Las recogerán silenciosamente y
                armarán de nuevo una torre; antes de que empiece el canto, otra
                seña y ¡otra tirada de latas!
                <br /><br />
                <span className={css(styles.HighlightedItem)}>2.</span>
                <span className={css(styles.SemiBoldText)}>Canción:</span> todos los chicos cantarán la primera parte entrando
                justito cuando termina la introducción, mientras, continuará
                tocando el del bombo:
                <br />
                <br />
                <div className={css(styles.BlueText)}>
                  "Cuidado, chicos, cuidado, que todos vienen gritando,
                  <br />
                  es que no la para nadie a la Revolución de Mayo.
                  <br />
                  <br />
                  Entrás callado al Cabildo, te imaginas el pasado,
                  <br />
                  y acordate, te emociona, la Revolución de Mayo.
                  <br />
                  <br />
                  Con las madres de la plaza, tantas marchas caminando, <br />
                  pá encontrarse en un abrazo, la Revolución de Mayo.
                  <br />
                  Patria chica, patria grande, yo pongo mi corazón,
                  <br />
                  para seguirla buscando, a nuestra Revolución."
                  <br /><br />
                </div>
                <span className={css(styles.HighlightedItem)}>3.</span>
                <span className={css(styles.SemiBoldText)}>Intermedio:</span> sobre la base rítmica grabada, un alumno improvisará
                ritmos golpeando con el puño y la mano abierta sobre las teclas
                blancas de un piano (esto se llama cluster). Otros dos tocarán
                de vez en cuando unos silbatos (¡como haciendo lío!).
                <br /><br />
                <span className={css(styles.HighlightedItem)}>4.</span>
                <span className={css(styles.SemiBoldText)}>Canción:</span> todos cantarán otra vez, la segunda parte, entrando
                después de la introducción y con el bombo:
                <br />
                <div className={css(styles.BlueText)}>
                  "Para mantenerse vivo y no quedarse estancado, <br />
                  para cambiarse por dentro, la Revolución de Mayo. <br />
                  <br />
                  Que los reyes españoles, deuda externa y otros pagos, <br />
                  pa’no darle al que te roba, la Revolución de Mayo. <br />
                  <br />
                  El pueblo quiere saber, de qué se trata, hermano. <br />
                  Esta pregunta nos deja, la Revolución de Mayo. <br />
                  Patria chica, patria grande, yo pongo mi corazón, <br />
                  para seguirla buscando a nuestra Revolución." <br /><br />
                </div>
                <span className={css(styles.HighlightedItem)}>5.</span>
                <span className={css(styles.SemiBoldText)}>Repetir el ostinato hablado</span> "¡qué, qué, qué pasa!"...
                <br /><br />
                <span className={css(styles.HighlightedItem)}>6.</span>
                <span className={css(styles.SemiBoldText)}>Comenzará nuevamente la música</span> y repetiremos todo el arreglo con
                el agregado de los carteles con las estrofas, para que el
                público cante con nosotros.
                <br /><br />
              </div>
            </div>
          </Container>
        </CollapsableSection>

        <CollapsableSection
          fixed={managedState}
         // opened={sectionSelected === "c"}
         /* toggle={() => {
            handleToggle("c");
          }}*/
          letter='C.'
          title='Aprendo la canción y me animo sin pista'
          pictureUrl='/images/letter_banner/c-aprendo-la-cancion.png'
          pictureHoverUrl='/images/letter_banner/c-aprendo-la-cancion-act.png'
        >
          <Container>
            <div className={css(styles.TextContentContainer)}>
              <div className={css(styles.TextContent)}>
                Los docentes que cantan con alegría y tocan percusión, guitarra
                o piano con comodidad pueden ensayar esta partitura
                prescindiendo del celular o computadora. Chacarera para canto,
                cajas de cartón, latas, llaves, mesita de aula, piano, bombo y
                guitarra.
                <br />
                <div
                  className={css(
                    styles.CenteredContent,
                    styles.MarginTopContent
                  )}
                >
                  <BorderedButton
                    text={"descargar letra con cifrado"}
                    onClick={() => {
                      window.open("files/la-revolucion-de-mayo-letra-y-cifrado.pdf");
                    }}
                    styles={styles.Button}
                  />
                </div>
                <div className={css(styles.PartituraText)}>
                  Intro: Dm / Dm / Dm / Dm / Dm / Dm / A7 / Dm // <br />
                  <div className={css(styles.LineaPartitura)}>
                    "Cuida
                    <Note note='Bb' text='do' />, chicos, cui
                    <Note note='C7' text='da' />
                    do, que to
                    <Note note='F' text='dos' /> vienen gri
                    <Note text='tan' note='G' />do,
                  </div>
                  <div className={css(styles.LineaPartitura)}>
                    es que <Note note='F' text='no' /> la{" "}
                    <Note note='G' text='pa' />
                    ra nadie a la Re
                    <Note note='A7' text='vo' />
                    lución de <Note note='Dm' text='Ma' />
                    yo.
                  </div>
                  <div className={css(styles.BlueText2)}>Interludio: Intro</div>
                  <div className={css(styles.LineaPartitura)}>
                    Entrás <Note note='Bb' text='ca' />
                    llado al Ca
                    <Note note='C7' text='bil' />
                    do, te ima
                    <Note note='F' text='gi' />
                    nas el pa
                    <Note note='G' text='sa' />
                    do,
                  </div>
                  <div className={css(styles.LineaPartitura)}>
                    y acor
                    <Note note='F' text='da' />
                    te, <Note note='G' text='te' /> emociona{" "}
                    <Note note='F' text='_' />
                    la Re
                    <Note note='A7' text='vo' />
                    lución de <Note note='Dm' text='Ma' />
                    yo.
                  </div>
                  <div className={css(styles.BlueText2)}>
                    Interludio: Percusión
                  </div>
                  <div className={css(styles.LineaPartitura)}>
                    Con las <Note note='Bb' text='ma' />
                    dres de la <Note note='C7' text='pla' />
                    za, tantas <Note note='F' text='mar' />
                    chas cami
                    <Note note='G' text='nan' />
                    do
                  </div>
                  <div className={css(styles.LineaPartitura)}>
                    Pa’encon
                    <Note note='F' text='trar' />
                    se en <Note note='G' text='un' /> abra
                    <Note note='F' text='zo' /> la Re
                    <Note note='A7' text='vo' />
                    lución de <Note note='Dm' text='Ma' />
                    yo.
                  </div>
                  <div className={css(styles.LineaPartitura)}>
                    Patria <Note note='Bb' text='chi' />
                    ca, patria <Note note='C7' text='gran' />
                    de, yo pon
                    <Note note='F' text='go' /> mi cora
                    <Note note='G' text='zón' />,
                  </div>
                  <div className={css(styles.LineaPartitura)}>
                    para <Note note='F' text='se' />
                    guir
                    <Note note='G' text='la' /> buscando, a nues
                    <Note note='A7' text='tra' /> Revolu
                    <Note note='Dm' text='ción' />
                    ."
                  </div>
                  <div className={css(styles.BlueText2)}>intro</div>
                  <div className={css(styles.LineaPartitura)}>
                    "Para <Note note='Bb' text='man' />
                    tenerse <Note note='C7' text='vi' />
                    vo y no <Note note='F' text='que' />
                    darse estan
                    <Note note='G' text='ca' />
                    do,
                  </div>
                  <div className={css(styles.LineaPartitura)}>
                    para <Note note='F' text='cam' />
                    biar
                    <Note note='G' text='se' /> por dentro, la Re
                    <Note note='A7' text='vo' />
                    lución de <Note note='Dm' text='Ma' />
                    yo.
                  </div>
                  <div className={css(styles.BlueText2)}>Interludio: intro</div>
                  <div className={css(styles.LineaPartitura)}>
                    Que los <Note note='Bb' text='re' />
                    yes espa
                    <Note note='C7' text='ño' />
                    les, deuda ex
                    <Note note='F' text='ter' />
                    na y otros <Note note='G' text='pa' />
                    gos,
                  </div>
                  <div className={css(styles.LineaPartitura)}>
                    pa’no <Note note='F' text='dar' />
                    la al <Note note='G' text='que' /> te roba, la Re
                    <Note note='A7' text='vo' />
                    lución de <Note note='Dm' text='Ma' />
                    yo.
                  </div>
                  <div className={css(styles.BlueText2)}>
                    Interludio: Percusión
                  </div>
                  <div className={css(styles.LineaPartitura)}>
                    El pue
                    <Note note='Bb' text='blo' /> quie
                    <Note note='G7' text='re' /> sa
                    <Note note='C7' text='ber' />, de qué{" "}
                    <Note note='F' text='se' /> trata, her
                    <Note note='G' text='ma' />
                    nos.
                  </div>
                  <div className={css(styles.LineaPartitura)}>
                    Esta <Note note='F' text='pre' />
                    gun
                    <Note note='G' text='ta' /> nos de
                    <Note note='F' text='ja' />, la Re
                    <Note note='A7' text='vo' />
                    lución de <Note note='Dm' text='Ma' />
                    yo.
                  </div>
                  <div className={css(styles.LineaPartitura)}>
                    Patria <Note note='Bb' text='chi' />
                    ca, patria <Note note='C7' text='gran' />
                    de, yo pon
                    <Note note='F' text='go' /> mi cora
                    <Note note='G' text='zón' />,
                  </div>
                  <div className={css(styles.LineaPartitura)}>
                    para <Note note='F' text='se' />
                    guir
                    <Note note='G' text='la' /> buscando a nues
                    <Note note='A7' text='tra' /> Revolu
                    <Note note='Dm' text='ción' />
                    ."
                  </div>
                </div>
                <ImageBox
                  styles={styles.ImgDefault}
                  pictureUrl='/images/c-maestros-y-publico-2.png'
                />
                Cuando termina la primera chacarera la maestra se acercará al
                público diciendo: “¡qué, qué, qué pasa!" y pidiéndoles con la
                mano que la sigan. Otro maestro que haya querido ayudarnos se
                acercará después a los de la izquierda repitiendo: “patria
                chica, patria grande" y una última maestra se acercará a los de
                la derecha: “hay una revolución". Se escucharán así, los tres
                ostinatos superpuestos, y si todo va bien por ahí pedimos:
                “¡fuertísimo!" y al ratito: “¡muy suave!".
              </div>
            </div>
          </Container>
        </CollapsableSection>
        <CollapsableSection
          fixed={managedState}
         // opened={sectionSelected === "d"}
         /* toggle={() => {
            handleToggle("d");
          }}*/
          letter='D.'
          title='Me encanta hacer algo distinto'
          pictureUrl='/images/letter_banner/d-me-encanta-hacer.png'
          pictureHoverUrl='/images/letter_banner/d-me-encanta-hacer-act.png'
        >
          <Container>
            <div className={css(styles.TextContentContainer)}>
              <div className={css(styles.TextContent)}>
                ¿Podremos celebrar el 25 de mayo sin disfrazar a alguien de
                negrito?
                <br />
                ¿O estaremos festejando siempre la esclavitud para que una nena
                crea que antes no había crisis y eran felices porque había
                esclavos?
                <br />
                <div className={css(styles.BoldTitle)}>Teatro de sombras</div>
                El teatro de sombras es eternamente mágico. A los chicos les
                fascina el achicamiento o agrandamiento de las figuras cerca o
                lejos de la pantalla, y se muestran muy desinhibidos y felices
                porque están escondidos. Además es muy fácil de armar: un lienzo
                barato o unas tres sábanas blancas unidas con unas puntadas,
                colgadas de una soga, y una mesita con una lámpara común o un
                reflector. Se ve muy lindo en lugares oscuritos, pero funciona
                perfectamente en el rincón menos iluminado del gimnasio.
                <ImageBox
                  styles={styles.ImgDefault}
                  pictureUrl='/images/d-teatro-de-sombras.png'
                />
                Después de la presentación apagamos la lámpara y preparamos el
                primer cuadro mientras empieza la música grabada. Sugerimos aquí
                algunas escenas para probar. Entre una y otra se apaga siempre
                la luz para armar la siguiente.
                <div className={css(styles.TwoColumnsContainer)}>
                  <div className={css(styles.TwoColumnsColumnContainer)}>
                    <span className={css(styles.HighlightedItem)}>1.</span>{" "}
                    Música del litoral
                    <ImageBox
                      textContainerStyles={styles.PictureBoxContainerText}
                      styles={styles.ImageColumnObra}
                      pictureUrl='/images/1-musica-del-litoral.png'
                      text='Un bote (de cartulina, llevado por un chico agachado) avanza de derecha a izquierda con un remero (un chico que camina remando con dos palos). Otros chicos acostados sostienen ramas de árboles.'
                    />
                  </div>
                  <div className={css(styles.TwoColumnsColumnContainer)}>
                    <span className={css(styles.HighlightedItem)}>2.</span>{" "}
                    Música del altiplano
                    <ImageBox
                      styles={styles.ImageColumnObra}
                      textContainerStyles={styles.PictureBoxContainerText}
                      pictureUrl='/images/2-musica-del-altiplano.png'
                      text='Un chico camina con las piernas tapadas por
                      la sombra de un burro de cartulina. Adelante caminan otros como subiendo a una montaña. Las montañas recortadas chiquitas en cartulina, parecen enormes puestas lejos del lienzo.'
                    />
                  </div>
                </div>
                <div className={css(styles.TwoColumnsContainer)}>
                  <div className={css(styles.TwoColumnsColumnContainer)}>
                    <span className={css(styles.HighlightedItem)}>3.</span>{" "}
                    Música de rock ciudadano
                    <ImageBox
                      styles={styles.ImageColumnObra}
                      textContainerStyles={styles.PictureBoxContainerText}
                      pictureUrl='/images/3-musica-de-rock-ciudadano.png'
                      text='Un chico pasa varias veces en bicicleta y después abre una sombrilla y una reposera. Otro juega en la playa con arena. Las olitas y edificios de cartulina van agarrados con alfileres de gancho a la tela.'
                    />
                  </div>
                  <div className={css(styles.TwoColumnsColumnContainer)}>
                    <span className={css(styles.HighlightedItem)}>4.</span>{" "}
                    Música étnica toba o mapuche
                    <ImageBox
                      styles={styles.ImageColumnObra}
                      textContainerStyles={styles.PictureBoxContainerText}
                      pictureUrl='/images/4-musica-etnica-toba-o-mapuche.png'
                      text='Por un bosque de árboles hecho con ramas pasa un chico con un hacha, voltea algunos, los carga y se va.'
                    />
                  </div>
                </div>
                <div className={css(styles.TwoColumnsContainer)}>
                  <div className={css(styles.TwoColumnsColumnContainer)}>
                    <span className={css(styles.HighlightedItem)}>5.</span>{" "}
                    Música de tango ciudadano
                    <ImageBox
                      styles={styles.ImageColumnObra}
                      textContainerStyles={styles.PictureBoxContainerText}
                      pictureUrl='/images/5-musica-de-tango-ciudadano.png'
                      text='El farol y la luna agarrados con alfileres. Pasa el colectivo y la gente grita porque no para. Pasa de nuevo y para lejos, lo corren, suben y se van.'
                    />
                  </div>
                  <div className={css(styles.TwoColumnsColumnContainer)}>
                    <span className={css(styles.HighlightedItem)}>6.</span>{" "}
                    Chacarera del 25 de mayo
                    <ImageBox
                      styles={styles.ImageColumnObra}
                      textContainerStyles={styles.PictureBoxContainerText}
                      pictureUrl='/images/6-chacarera-del-25-de-mayo.png'
                      text='El Cabildo agarrado con alfileres y van pasando de a dos a zapatear y bailar un poquito para saludar después asomándose por los costados de la tela.'
                    />
                  </div>
                </div>
                <div className={css(styles.BoldTitle)}>Fotos o videos</div>
                Algunos padres o maestros pueden conseguir fotos o videos de
                distintas zonas del país, inclusive de sus propias familias. Se
                las muestran al público con la música característica de cada
                región, que es presentada por alumnos que imitan la tonada del
                lugar: “Che, loco, le vamo a pasar unas fotos de mi rioba"
                (tango y Buenos Aires); “me vine yegando de ayibita ‘el ceyo
                pa’mostrarles mi gente" (huayno y Valles Calchaquíes), etc. Y
                como cierre bailan la chacarera.
                <br />
                <div className={css(styles.BoldTitle)}>Títeres</div>
                Hay maestros de grado y de plástica que se las ingenian para
                hacer títeres con cualquier cosa: medias, tubos de cartón con
                cinta adhesiva, o un palo y cartulinas pegadas. Para acompañar
                las escenas una vez vi realizar una verdadera “orquesta de
                efectos" que permitía escuchar paisajes sonoros desde atrás de
                la lona colgada en la que se asomaban los muñecos. Así, mientras
                unos títeres venían en bote, otro títere explicaba con acento
                litoraleño de dónde eran, qué alegrías y sufrimientos tenían, y
                la “orquesta de efectos" hacía ruidos de agua junto al
                micrófono, silbidos de pájaros y un “chamamé" con una armónica;
                mientras los títeres corrían como locos entre autos de juguete
                se escuchaban bocinazos, motores, gritos y frenadas de la ciudad
                de Buenos Aires. En fin, era muy divertido escuchar todo tipo de
                sonidos de viento, perros, una radio en la playa y otras
                ocurrencias y al final, como broche humorístico, ver que dejaban
                caer la lona de golpe mostrando a todos los chicos para que los
                aplaudieran.
              </div>
            </div>
          </Container>
        </CollapsableSection>
        <CollapsableSection
          fixed={managedState}
         // opened={sectionSelected === "d"}
         /* toggle={() => {
            handleToggle("d");
          }}*/
          letter='E.'
          title='Fiesta por pantalla'
          pictureUrl='/images/letter_banner/e-acto-por-pantalla.png'
          pictureHoverUrl='/images/letter_banner/e-acto-por-pantalla-act.png'
        >
          <Container>
            <div className={css(styles.TextContentContainer)}>
              <div className={css(styles.TextContent)}>
                <div className={css(styles.BoldTitleNoMarginTop)}>
                  Como festejo, un encuentro por internet, con todos los chicos del grado o incluyendo a
                  algún familiar invitado.
                </div>
                Con los más chiquines, pediremos que salgan a buscar rápido por la casa elementos para hacer
                torres: vasos de plástico, maderitas, cubos. Ponemos en la pantalla un escrito en un cartel que dice:
                EN LAS REVOLUCIONES CAEN LAS IDEAS Y EL ORDEN ESTABLECIDO Y SE CONSTRUYEN TORRES DE
                IDEAS Y ACUERDOS NUEVOS.
                <br/><br/>
                Empezamos a escuchar la grabación del 25 de mayo mientras vemos que los chicos arman sus torres,
                y gritamos ¡Ya! cada vez que se escucha la caída de latas para que derribe cada uno la suya y la
                reconstruya apurado antes de la próxima caída.
                <ImageBox
                  styles={styles.ImgDefault}
                  pictureUrl='/images/torre.png'
                />
                Con los preadolescentes reemplazaremos las torres con pilas de objetos de su dormitorio: zapatillas,
                gorros, collares, cajas de juegos, una maceta, una flauta… Permitimos tomar algo de su hermano o
                quién sea con quien comparta sus espacios. La educación digital nos llevó a incorporar los ámbitos
                de las viviendas, y entre los jovencitos se ríen mucho al reconocerse en sus ideas, creencias y
                costumbres a través de sus pertenencias.
                <ImageBox
                  styles={styles.ImgDefault}
                  pictureUrl='/images/adolescente.png'
                />
                Escucharemos otra vez la grabación del 25 de Mayo, pero previamente ponemos un escrito en la
                pantalla que dice: LAS REVOLUCIONES DE ANTES Y AHORA SON BUENAS CUANDO INTEGRAN LOS
                PEDIDOS DE TODOS. POR ESO BAILAMOS CHACARERAS CON RITMOS AFRICANOS, CON CARAS
                INDÍGENAS, CON IDIOMA ESPAÑOL Y CON PALABRAS QUECHUAS.
                <br/><br/>
                Los chicos tomarán una hoja en blanco con una mano, de espaldas a la ventana o a una lámpara y
                con la otra harán bailar detrás a un muñequito en un verdadero teatro de sombras. La preparación de
                este muñequito se hará una semana antes: el chico dibuja una persona con brazos y piernas abiertas
                sobre papel o cartulina de alguna caja vieja, y alguien lo ayuda a recortarlo y pegarle una ramita o
                palito. Algunos entusiastas han pegado al papel algunas ramitas al costado que parecen árboles, o
                algún cabildo o casita con las ventanas agujereadas.
                <br/><br/>
                <Video src={"https://www.youtube.com/embed/8ZbYsyOWn7U"} />
                <br/><br/>
                Cuando termina toda la grabación, abriendo los micrófonos cantamos todos juntos el último estribillo
                con el ruido por la mezcla de voces: “¡Cuidado chicos cuidado, que todos vienen gritando, es que no
                la para nadie a la revolución de mayo!” Y aplaudimos. 


                <div className={css(styles.BoldTitle)}>
                  Una fiesta, preparada anticipadamente con juegos interactivos, para enviar a las
                  familias y a toda la escuela
                </div>
                Durante el encuentro por internet de una clase o encargándolo como tarea, jugaremos con este
                programa de loops, probando superponer los diferentes ritmos, voces, melodías y armonías de la
                chacarera. Todos investigarán libremente, y probarán grabar mezclas que les gusten más.
                <br />
                <div
                  className={css(
                    styles.CenteredContent,
                    styles.MarginTopContent
                  )}
                > 
                  <Link target="_blank" to='/mixer25demayo'>
                    <BorderedButton text={"abrir programa de loops"} onClick={()=>"/mixer25demayo"} styles={styles.Button}/>
                  </Link>  
                </div>

                Se mandarán y escucharán lo que fueron probando. Entonces puede aparecer una revolución: porque
                alguien grabó algo raro y especial, o porque suena tremendo, o porque le dio algún sentido único que
                a todos les gustó y generó algo diferente.
                <br/><br/>
                Sobre esta base revolucionaria que eligieron, podrán tocar en la siguiente clase cajas, latas, golpear
                mesas, y cantar algunas partes de la chacarera, ya sea ostinatos o algunas partes de la canción.
                Entonces planearemos una grabación y exclamaremos:
                “Vamos a grabar nuestro invento revolucionario para la fiesta. Cuando digo ¡ya! escucharemos
                nuestra base grabada y en el orden que decidimos cada uno hará su participación. Los felicito porque
                inspirados en esta chacarera hemos hecho una obra todos juntos”

                <div className={css(styles.BoldTitle)}>Una fiesta editada </div>

                Con un celular o grabador de aire los chicos grabarán tocando en su casa con cajas y latas y con las
                mezclas de loops que les hayan interesado. Enviarán lo que les guste y el docente editará mezclando
                esas ideas.
                <br/><br/>
                Porque otro tipo de revolución es que la maestra o el maestro creativos se larguen a editar con todo
                lo que fueron explorando y enviando sus alumnos, y armen una hermosa canción nueva, producida
                con toda la perfección que permiten estas nuevas formas de componer para nuestras aulas.
                <br/><br/>
                Para quien se divierte con estas posibilidades hoy existen ¡muchos programas! o aplicaciones y
                tutoriales que facilitan el aprendizaje. Lo básico: cortar, pegar, dividir, regular volúmen,etc, se aprende
                en un ratito. Son herramientas que nos abren para siempre nuevos panoramas en el arte y la
                comunicación, para disfrutar de otras actividades virtuales o no. Y si no nos resultara atractivo el
                manejo de estas tecnologías, armaríamos equipo con alguien que si lo haga, con alegría. Porque como
                siempre, el entusiasmo es el mejor motor para cualquier proyecto.
              </div>
            </div>
          </Container>
        </CollapsableSection>
      </div>
    </div>
  );
};

export default TwentyFiveMayFestScreen;
