import React, { useState, useRef } from "react";
import { StyleSheet, css } from "aphrodite";
import ReCAPTCHA from "react-google-recaptcha";

const styles = StyleSheet.create({
  FormContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  CenteredContainer: {
    display: "flex",
    justifyContent: "center",
  },
  SubmitBtn: {
    border: "1px solid #1d75fd",
    padding: 10,
    cursor: "pointer",
    backgroundColor: "transparent",
    fontFamily: "Work Sans",
    fontSize: 13,
    color: "#1d75fd",
    textTransform: "uppercase",
    fontWeight: 600,
    marginBottom: 50,
    ":hover": {
      backgroundColor: "#1d75fd",
      color: "#fff2a6",
    },
  },
  InputText: {
    height: 40,
    padding: 10,
    //width: "100%",
    maxWidth: 878,
    border: "1px solid black",
    backgroundColor: "transparent",
    fontSize: 18,
    fontFamily: "Work Sans",
    color: "black",
    marginBottom: 20,
    "::placeholder": {
      color: "black",
      fontSize: 18,
    },
    ":focus-visible": {
      outline: "1px solid #1d75fd",
    },
  },
  TextArea: {
    height: 180,
  },
  AlertSuccess: {
    padding: 20,
    backgroundColor: "#04AA6D",
    color: "white",
    opacity: 1,
    transition: "opacity 0.6s",
    marginBottom: 15,
    display: "none",
  },
  AlertError: {
    padding: 20,
    backgroundColor: "#f44336",
    color: "white",
    opacity: 1,
    transition: "opacity 0.6s",
    marginBottom: 15,
    display: "none",
  },
  displayNo: {
    display: "none",
  },
  recaptcha: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    margin: "20px 0",
  },
  errorCaptcha: {
    color: "red",
  },
});

const FormContact = () => {
  const [datos, setDatos] = useState({
    nombre: "",
    email: "",
    mensaje: "",
  });

  const [respuesta, setRespuesta] = useState({});
  const [captchaValido, setCaptchaValido] = useState(null);

  const captcha = useRef(null);

  const handleInputChange = (event) => {
    // console.log(event.target.name)
    // console.log(event.target.value)
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
  };

  const onChange = () => {
    if (captcha.current.getValue()) {
      setCaptchaValido(true);
    }
  };
  const enviarDatos = async (event) => {
    event.preventDefault();
    console.log("enviando datos..." + datos.nombre + " " + datos.email);
    console.log(JSON.stringify(datos));

    if (captcha.current.getValue()) {
      setCaptchaValido(true);
      try {
        const resp = await fetch("./main.php", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(datos),
        });
        const dat = await resp.json();
        console.log(dat);
        setRespuesta(dat);
        document.getElementById("submit").value = dat.message;
      } catch (err) {
        console.log(err);
      }
    } else {
      setCaptchaValido(false);
    }
  };

  return (
    <>
      <form onSubmit={enviarDatos} className={css(styles.FormContainer)}>
        <input
          className={css(styles.InputText)}
          type="text"
          name="nombre"
          placeholder="Nombre y apellido"
          onChange={handleInputChange}
          required
        />
        <input
          className={css(styles.InputText)}
          type="email"
          name="email"
          placeholder="e-mail"
          onChange={handleInputChange}
          required
        />
        <textarea
          required
          className={css(styles.InputText, styles.TextArea)}
          name="mensaje"
          placeholder="Mensaje"
          onChange={handleInputChange}
        />
        <div className={css(styles.recaptcha)}>
          <ReCAPTCHA
            sitekey="6Ld-wzgbAAAAABDoe_O6xssz4ALH00UXmVOf_vOt"
            onChange={onChange}
            ref={captcha}
          />
          {captchaValido === false && (
            <div className={css(styles.errorCaptcha)}>
              Por favor acepta el recaptcha
            </div>
          )}
        </div>

        <div className={css(styles.CenteredContainer)}>
          <input
            className={"text " + css(styles.SubmitBtn)}
            type="submit"
            value="Enviar"
            id="submit"
          />
        </div>
      </form>
      {/* {respuesta.err ? (
        <div className={css(styles.AlertSuccess)}>{respuesta.message}</div>
      ) : (
        <div className={css(styles.AlertSuccess, styles.displayYes)}>
          {respuesta.message}
        </div>
      )} */}
    </>
  );
};

export default FormContact;
