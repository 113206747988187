import React from "react";
import { StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
  root: {
    flex: "1",
    display: "flex",
    justifyContent: "center",
    "@media(max-width: 1023px)": {
      padding: 20,
      display:"block",
    },
  },
  Content: {
    //width: "100%",
    maxWidth: "900px",
    paddingBottom: 20,
    margin: "auto",
  },
});

const Container = ({ children }: { children: any }) => {
  return (
    <div className={css(styles.root)}>
      <div className={css(styles.Content)}>{children}</div>
    </div>
  );
};
export default Container;
