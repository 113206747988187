import React, { useState } from "react";
import "./App.css";
import { StyleSheet, css } from "aphrodite";
import { HashRouter as Router, Switch, Route } from "react-router-dom";

import { HomeScreen } from "./modules/home";
import { NineJulyFestScreen, TwentyFiveMayFestScreen, TwentyJuneFestScreen, TwelveOctoberFestScreen } from "./modules/fests";
import SeventeenAugustFestScreen from './modules/fests/SeventeenAugustFestScreen'
import TeacherDayFestScreen from './modules/fests/TeacherDayFestScreen'
import { ThanksScreen } from "./modules/thanks";
import { ContactScreen } from "./modules/contact";
import { Mixer9DeJulio } from "./modules/mixer/mixer-9dejulio";
import { Mixer25DeMayo } from "./modules/mixer/mixer-25demayo";
import { createBrowserHistory } from "history";
import Gifs from "modules/ui/components/Gifs";
import { Header } from "modules/ui";
import Header2 from "modules/ui/components/Header2";

const styles = StyleSheet.create({
  gifsClass: {
    width: "100vw",
    height: "100vh",
    position: "fixed",
    backgroundColor: "rgb(255,255,255)",
    display: "flex",
    alignItems: "center",
    zIndex: 9999,
  },
})

function App() {
  const history = createBrowserHistory();
  const [gif, setGif] = useState(true);
  const [mix, setMix] = useState(true);
  
  const location = window.location.hash
  console.log(location)
  return (
    <>
      {
        gif && !location.startsWith("#/mixe") && (<div onClick={() => { setGif(false) }} className={css(styles.gifsClass)}><Gifs /></div>)
      }
      {
        mix && location.startsWith("#/mixe") && setMix(false)
      }

      <Router history={history}>
        {/* A <Switch> looks through its children <Route>s and
        renders the first one that matches the current URL. 
        <Header/> */}
         {mix &&<Header2 />}
        <Switch>
          <Route path="/fiesta_25_mayo" exact>
            <TwentyFiveMayFestScreen />{" "}
          </Route>
          <Route path="/fiesta_20_junio" exact>
            <TwentyJuneFestScreen />{" "}
          </Route>
          <Route path="/fiesta_9_julio" exact>
            <NineJulyFestScreen />{" "}
          </Route>
          <Route path="/fiesta_17_agosto" exact>
            <SeventeenAugustFestScreen />
          </Route>
          <Route path="/fiesta_11_septiembre" exact>
            <TeacherDayFestScreen />
          </Route>
          <Route path="/agradecimientos" exact>
            <ThanksScreen />{" "}
          </Route>
          <Route path="/contacto" exact>
            <ContactScreen />{" "}
          </Route>
          <Route path="/mixer9dejulio" exact>
            <Mixer9DeJulio />{" "}
          </Route>
          <Route path="/mixer25demayo" exact>
            <Mixer25DeMayo />{" "}
          </Route>
          <Route path="/fiesta_12_octubre" exact>
            <TwelveOctoberFestScreen />{" "}
          </Route>
          <Route path="/">
            <HomeScreen />{" "}
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
