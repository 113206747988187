import React from "react";
import { Header, Container, Banner2 } from "../ui";
import { StyleSheet, css } from "aphrodite";
import Img from "../ui/components/Img";

const styles = StyleSheet.create({
    Content: {
        flex: 1,
        margin: "0",
        maxWidth: "100%",
        boxSizing: "border-box",
        minHeight: 100,
        backgroundColor: "#fff2a6",
        paddingBottom: 100,
    },
    Title: {
        fontWeight: "bold",
        fontSize: 60,
        fontFamily: "Alegreya",
        textAlign: "center",
        width: "100%",
        marginBottom: 50,
        marginTop: 30,
        "@media(max-width: 795px)": {
            fontSize: "54px",
            lineHeight: "65px",
            marginTop: 40,
            marginBottom: 32,
        },
    },
    SemiBoldText: {
        fontWeight: 600,
    },
    Text: {
        //padding: 50,
        fontFamily: "Work Sans",
        fontSize: 18,
        lineHeight: "1.5",
    },
    AlignCenter: {
        textAlign: "center",
        marginTop: 20,
    },
    AllWidth: {
        width: "100%",
        maxWidth: 300,
    },
});

const ThanksScreen = () => {
    return (
        <div className={css(styles.Content)}>
            <Banner2 />
            <Container>
                <div className={css(styles.Text)}>
                    <div className={css(styles.Title)}>Gracias a</div>
                    Esta oportunidad, esta película fascinante que fue meterse
                    en la cabeza de un pibe y en nuestra historia, con nuestras
                    herramientas de músicos y docentes.
                    <br />
                    <br />
                    Quienes hicieron posible la música de las distintas Fiestas:
                    <br />
                    <br />
                    25 de Mayo
                    <br />
                    <span className={css(styles.SemiBoldText)}>
                        Pedro Spiller, Manuel Spiller, Eli Monteagudo, Mariana
                        Baggio, coro Armendariz y Carina Fernández{" "}
                    </span>
                    por sus voces hermosas en la Chacarera de la Revolución.
                    <br />
                    <br />
                    20 de Junio
                    <br />
                    <span className={css(styles.SemiBoldText)}>
                        Violeta Seoane{" "}
                    </span>
                    por cantar tan lindo el Chamamé de la Bandera, y{" "}
                    <span className={css(styles.SemiBoldText)}>
                        Flor Canelo
                    </span>{" "}
                    por brindar sus grabaciones de los niños que cantan en
                    quechua.
                    <br />
                    <br />
                    9 de Julio
                    <br />
                    <span className={css(styles.SemiBoldText)}>
                        Manuel Spiller{" "}
                    </span>
                    por su interpretación del Rap Independiente, y a{" "}
                    <span className={css(styles.SemiBoldText)}>
                        Pedro Spiller
                    </span>{" "}
                    y su banda futbolera por divertirnos con sus voces:{" "}
                    <span className={css(styles.SemiBoldText)}>
                        Bruno Masaldi, Vicente Fossati, Martina Silva Fregosi y
                        Marco Ivan Masaldi
                    </span>
                    .
                    <br />
                    <br />
                    17 de Agosto
                    <br />
                    <span className={css(styles.SemiBoldText)}>
                        Augusto Perez Guarnieri
                    </span>{" "}
                    por su toque de tambor y sus largas y sabias charlas
                    antropológicas y educativas.
                    <br />
                    <span className={css(styles.SemiBoldText)}>
                        Nazareno Sheines Mazzeo
                    </span>{" "}
                    por su música de pequeñas manos en la palangana.
                    <br />
                    <span className={css(styles.SemiBoldText)}>
                        Matteo Caputo, Florencia Molina y Vedia, Graciela
                        Gutman, Carina Fernández, flía. Armendariz, Gabriel
                        Spiller, Mariana Baggio y Eli Monteagudo
                    </span>
                    , por integrarse a la muchedumbre.
                    <br />
                    <span className={css(styles.SemiBoldText)}>
                        Cayetano Alberto Silva
                    </span>
                    , autor de la querida marcha de San Lorenzo
                    <br />
                    <br />
                    11 de Septiembre
                    <br />
                    <span className={css(styles.SemiBoldText)}>
                        Teo Pojomovsky, Pedro Spiller Monteagudo, Emilia Tognin
                        Macarrone, Olivia Anun Castro y Mora Coria Ruiz
                    </span>
                    , por participar con sus voces frescas y hermosas.
                    <br />
                    <span className={css(styles.SemiBoldText)}>
                        {" "}
                        Simona Alonso{" "}
                    </span>{" "}
                    que sabe jugar con el arte y con su voz.
                    <br />
                    <span className={css(styles.SemiBoldText)}>
                        Laura Migliorisi{" "}
                    </span>{" "}
                    que logró con los chicos tan buen cantar.
                    <br />
                    <span className={css(styles.SemiBoldText)}>
                        Eli Monteagudo{" "}
                    </span>{" "}
                    embellecedora de canciones.
                    <br />
                    <span className={css(styles.SemiBoldText)}>
                        {" "}
                        León Gieco{" "}
                    </span>{" "}
                    por su música amada por docentes, grandes y chicos y todas
                    las familias.
                    <br />A los cantantes de quienes tomamos apenas un trocito
                    de sus interpretaciones, que podrán buscar completas en
                    YouTube:{" "}
                    <span className={css(styles.SemiBoldText)}>
                        {" "}
                        “Muchacha ojos de papel” L.A.Spinetta,”Amor de mis
                        amores” Natalia Lafourcade, “O mio babino caro”
                        Monserrat Caballe, “Canguro” Wos, “Maturana” Mercedes
                        Sosa y “MI vieja” Pappo.{" "}
                    </span>
                    <br />
                    <br />
                    12 de Octubre
                    <br />
                    <span className={css(styles.SemiBoldText)}>
                        Gabriel Spiller
                    </span>
                    , por su hermosura y excelencia en batería… de cocina
                    <br />
                    <span className={css(styles.SemiBoldText)}>
                        Manuel Spiller
                    </span>{" "}
                    creador tan joven de música digital.
                    <br />
                    <span className={css(styles.SemiBoldText)}>
                        Pedro Spiller
                    </span>
                    , músico eficaz para toooooodo lo que le pedimos.
                    <br />
                    <span className={css(styles.SemiBoldText)}>
                        Juani Armendariz
                    </span>
                    , que filmó con el celular a sus primos, atravesados por las
                    aventuras reales de sus
                    <br />
                    vidas.
                    <span className={css(styles.SemiBoldText)}>
                        Poli Bordón, Maruca Hernández y Darío Kullock
                    </span>{" "}
                    porque son docentes ejemplares en su ética y musicalidad.
                    <br />
                    <span className={css(styles.SemiBoldText)}>
                        Mariluz Mandracho
                    </span>{" "}
                    por su pasión por dar a conocer las culturas musicales
                    ancestrales y acercarnos el{" "}
                    <span className={css(styles.SemiBoldText)}>"Uaqué"</span>{" "}
                    (Caminos sonoros de la Patagonia tehuelche/ mapuche de Mario
                    Silva)
                    <br />
                    <span className={css(styles.SemiBoldText)}>
                        Lucila Carabelli
                    </span>{" "}
                    por{" "}
                    <span className={css(styles.SemiBoldText)}>
                        “De dónde vienen esas voces”
                    </span>
                    , Ed. Iamiqué, para que empecemos a deletrear el idioma qom,
                    mapuche y guaraní.
                    <br />
                    <br />
                    Los que colaboraron en distintas tareas:
                    <br />
                    <br />
                    <span className={css(styles.SemiBoldText)}>
                        Ana Zelada , Selva Zabronski
                    </span>
                    , por el diseño gráfico.
                    <br />
                    <span className={css(styles.SemiBoldText)}>
                        Julián Dondero
                    </span>
                    , programador con sonrisa, y equipo de{" "}
                    <span className={css(styles.SemiBoldText)}>FSINet</span>. En
                    especial a Gustavo Bianchetti, por el entusiasmo y
                    paciencia.
                    <br />
                    <span className={css(styles.SemiBoldText)}>
                        Florencia Molina y Vedia
                    </span>
                    , tramitóloga, Santa Expedita.
                    <br />
                    <span className={css(styles.SemiBoldText)}>
                        María Victoria Rey
                    </span>
                    , consultas estéticas, políticas y educativas.
                    <br />
                    <span className={css(styles.SemiBoldText)}>
                        Karina Malvicini
                    </span>
                    , por sacar este libro de un cajón. <br />
                    <span className={css(styles.SemiBoldText)}>
                        Maestro Firpo
                    </span>
                    , por contar lo más serio con humor.
                    <br />
                    <span className={css(styles.SemiBoldText)}>
                        Hugo Fumero
                    </span>
                    , por su presencia musical.
                    <br />
                    <span className={css(styles.SemiBoldText)}>
                        Suri Fumero
                    </span>
                    , por abrirnos los ojos sobre la función salvadora del
                    ámbito escolar.
                    <br />
                    <br />
                    Agradecemos especialmente al equipo de la{" "}
                    <span className={css(styles.SemiBoldText)}>
                        Universidad Nacional de Rosario
                    </span>
                    : libre, pública y gratuita, que nos apoyó con alegría y
                    dedicación.
                    <div className={css(styles.AlignCenter)}>
                        <Img
                            src={"/images/logos-universidad-rosario.png"}
                            className={css(styles.AllWidth)}
                        />
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default ThanksScreen;
