import React from "react";
import { StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
  root: {
    color: "#1d75fd",
    fontFamily: "Alegreya",
    fontSize: 30,
    backgroundColor: "white",
    textAlign: "center",
  },
});

const HighlightedText = ({ children }: { children: any }) => {
  return <span className={css(styles.root)}>{children}</span>;
};
export default HighlightedText;
