import React from "react";
import { Header, Container, Banner3 } from "../ui";
import { StyleSheet, css } from "aphrodite";
import FormContact from "modules/ui/components/FormContact";

const styles = StyleSheet.create({
  Content: {
    flex: 1,
    margin: "0",
    maxWidth: "100%",
    boxSizing: "border-box",
    minHeight: 100,
    backgroundColor: "#fff2a6",
    paddingBottom: 100,
  },
  Title: {
    fontWeight: "bold",
    fontSize: 60,
    fontFamily: "Alegreya",
    textAlign: "center",
    width: "100%",
    marginBottom: 50,
    marginTop:30,
    "@media(max-width: 795px)": {
      fontSize: "54px",
      lineHeight: "65px",
      marginTop: 40,
      marginBottom: 32,
    },
  },
  BoldText: {
    fontWeight: "bold",
  },
  /*Text: {
    padding: 30,
    fontFamily: "Work Sans",
    marginBottom: 40,
  },*/
  CenteredContainer: {
    display: "flex",
    justifyContent: "center",
  },
 /* SubmitBtn: {
    border: "1px solid #1d75fd",
    padding: 10,
    cursor: "pointer",
    backgroundColor: "transparent",
    fontFamily: "Work Sans",
    fontSize: 13,
    color: "#1d75fd",
    textTransform: "uppercase",
    fontWeight: 600,
    marginBottom: 50,
    ":hover": {
      backgroundColor: "#1d75fd",
      color: "#fff2a6",
    },
  },
  InputText: {
    height: 45,
    padding: 10,
    width: "100%",
    border: "1px solid black",
    backgroundColor: "transparent",
    fontSize: 20,
    fontFamily: "Work Sans",
    color: "black",
    marginBottom: 20,
    "::placeholder": {
      color: "black",
    },
  },*/
  TextArea: {
    height: 180,
  },
  Text: {
    //padding: 50,
    fontFamily: "Work Sans",
    fontSize: 18,
    lineHeight: "1.5",
    width:"100%",
  },
});

const ContactScreen = () => {
  return (
    <div className={css(styles.Content)}>
      <Banner3 />
      <Container>
        <div 
        className={css(styles.Text)}
        >
          <div className={css(styles.Title)}>Estemos cerca</div>
          <FormContact/>
          También podés escribirnos a{" "}
          <span className={css(styles.BoldText)}>
          info@fiestaenlaescuela.com.ar
          </span>
        </div>
      </Container>
    </div>
  );
};

export default ContactScreen;
