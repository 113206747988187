import "./css/style.css";

const Table = () => {
  return (
    <table className="table">
      <tr>
        <td></td>
        <td>LA MAYORÍA CONTESTÓ SÍ</td>
        <td>LA MAYORÍA CONTESTÓ NO</td>
        <td>LA MAYORÍA NO SÉ O MÁS O MENOS</td>
        <td>COMENTARIOS</td>
      </tr>

      <tbody>
        <tr>
          <td data-label=" ">¿tenés ojos claros?</td>
          <td data-label="LA MAYORÍA CONTESTÓ SÍ"></td>
          <td data-label="LA MAYORÍA CONTESTÓ NO">x</td>
          <td data-label="LA MAYORÍA NO SÉ O MÁS O MENOS"></td>
          <td data-label="COMENTARIOS">
            2 o 3 chicos tardan en entender el juego
          </td>
        </tr>

        <tr>
          <td data-label=" ">
            vamos a opinar sobre la guerra, la paz y las peleas. ¿vos sos
            peleador o peleadora?
          </td>
          <td data-label="LA MAYORÍA CONTESTÓ SÍ"></td>
          <td data-label="LA MAYORÍA CONTESTÓ NO"></td>
          <td data-label="LA MAYORÍA NO SÉ O MÁS O MENOS">x</td>
          <td data-label="COMENTARIOS">se arma un lío tremendo y discuten</td>
        </tr>

        <tr>
          <td data-label=" ">
            Si un chico te pega siempre ¿tenés que ir a pegarle para que te deje
            en paz?
          </td>
          <td data-label="LA MAYORÍA CONTESTÓ SÍ"></td>
          <td data-label="LA MAYORÍA CONTESTÓ NO">x</td>
          <td data-label="LA MAYORÍA NO SÉ O MÁS O MENOS">algunos</td>
          <td data-label="COMENTARIOS">
            hay distintas opiniones en los hogares
          </td>
        </tr>

        <tr>
          <td data-label=" ">
            ¿San Martín luchaba contra los españoles porque nos hacían vivir
            mal?
          </td>
          <td data-label="LA MAYORÍA CONTESTÓ SÍ">x</td>
          <td data-label="LA MAYORÍA CONTESTÓ NO"></td>
          <td data-label="LA MAYORÍA NO SÉ O MÁS O MENOS">
            No sé por qué los españoles eran malos. (Joaquín)
          </td>
          <td data-label="COMENTARIOS">
            Nos sacaban el oro y la plata y todo era para el rey
          </td>
        </tr>

        <tr>
          <td data-label=" ">
            ¿Toda la gente decía que San Martín tenía razón?
          </td>
          <td data-label="LA MAYORÍA CONTESTÓ SÍ">x</td>
          <td data-label="LA MAYORÍA CONTESTÓ NO"></td>
          <td data-label="LA MAYORÍA NO SÉ O MÁS O MENOS">
            no sé, siempre hay algún tonto que no entiende nada y no ayuda
            (Naty)
          </td>
          <td data-label="COMENTARIOS"></td>
        </tr>

        <tr>
          <td data-label=" ">
            ¿Vos sabés quienes son ahora los malos que hacen que haya pobreza o
            sufrimiento?
          </td>
          <td data-label="LA MAYORÍA CONTESTÓ SÍ"></td>
          <td data-label="LA MAYORÍA CONTESTÓ NO"></td>
          <td data-label="LA MAYORÍA NO SÉ O MÁS O MENOS">
            nosotros por no defendernos, el presidente, los corruptos, los
            ricos...
          </td>
          <td data-label="COMENTARIOS"></td>
        </tr>

        <tr>
          <td data-label=" ">
            ¿Si fueras San Martín armarías un ejército ahora y saldrías a luchar
            contra los malos?
          </td>
          <td data-label="LA MAYORÍA CONTESTÓ SÍ"></td>
          <td data-label="LA MAYORÍA CONTESTÓ NO"></td>
          <td data-label="LA MAYORÍA NO SÉ O MÁS O MENOS">x</td>
          <td data-label="COMENTARIOS">
            él arreglaría por las buenas, si los malos son de afuera sí.
          </td>
        </tr>

        <tr>
          <td data-label=" ">
            ¿vos venderías armas para llenarte de dinero aunque los pacifistas
            se enojen?
          </td>
          <td data-label="LA MAYORÍA CONTESTÓ SÍ"></td>
          <td data-label="LA MAYORÍA CONTESTÓ NO">x</td>
          <td data-label="LA MAYORÍA NO SÉ O MÁS O MENOS"></td>
          <td data-label="COMENTARIOS">
            charla sobre los que venden armas y drogas.
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Table;
