import React,{useEffect, useState} from "react";
import { Header, Banner, Container, HighlightedText } from "../ui";
import { StyleSheet, css } from "aphrodite";
import BannerColumnas from "./BannerColumnas";
import Video from "./Video";
import Img from "../ui/components/Img";


const styles = StyleSheet.create({
  Content: {
    flex: 1,
    margin: "0",
    maxWidth: "100%",
    boxSizing: "border-box",
    minHeight: 100,
    backgroundColor: "#fff2a6",
    paddingBottom: 100,
  },
  Box: {
    width: "80%",
    margin: "2rem 10% 0 10%",
    height: "100vh",
    backgroundColor: "plum",
  },
  Title: {
    width: "100%",
    fontFamily: "Alegreya",
    textAlign: "center",
    fontSize: "74px",
    marginTop: 90,
    marginBottom: 40,
    fontWeight: "bold",
    "@media(max-width: 795px)": {
      fontSize: "54px",
      lineHeight: "65px",
      marginTop: 40,
      marginBottom: 32,
    },
  },
  TitleFiesta:{
    width: "100%",
    fontFamily: "Alegreya",
    textAlign: "center",
    fontSize: "74px",
    marginTop: 60,
    marginBottom: 40,
    fontWeight: "bold",
    "@media(max-width: 795px)": {
      fontSize: "54px",
      lineHeight: "65px",
      marginTop: 40,
      marginBottom: 32,
    },
    
  },
  Autores:{
    marginBottom: 60,
  },
  Text: {
    textAlign: "left",
    fontFamily: "Work Sans",
    fontSize: 18,
    lineHeight: "28px",
  },
  TextFiesta: {
    fontFamily: "Work Sans",
    fontWeight: 500,
    fontSize: 18,
    lineHeight: "28px",
    textAlign: "center",
    margin: 0,
  },
  TextContainer: {
    marginTop: 20,
  },
  HighlightedContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 50,
    paddingBottom: 50,
  },
  HighlightedContent: {
    textAlign: "center",
    width: 700,
  },
  ColumnsContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 20,
    flex: 1,
    "@media(max-width: 795px)": {
      flexDirection: "column",
    },
  },
  Column: {
    paddingLeft: 20,
    paddingRight: 20,
    flex: 1,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    "@media(max-width: 795px)": {
      paddingBottom: 40,
    },
  },
  CircularImg: {
    // height: 250,
    width: 200,
    height: 200,
    "@media(max-width: 795px)": {
      width: "70%",
      height: "70%",
    },
  },
  CircleTextTitle: {
    marginTop: 20,
    fontSize: 25,
    fontFamily: "Alegreya",
    fontWeight: "bold",
    "@media(max-width: 795px)": {
      fontSize: 35,
    },
  },
  LineSeparator: {
    width: "140px",
    paddingTop: 25,
    paddingBottom: 25,
    "@media(max-width: 795px)": {
      width: "80%",
    },
  },
  TextColumn: {
    fontSize: "1rem",
    fontFamily: "Work Sans",
    textWeight: 400,
    textAlign: "center",
    lineHeight: "24px",
  },
  TextItalic:{
    fontFamily:"Work Sans",
    fontStyle: "italic",
  },
});

const HomeScreen = () => {

  

  
  return (
    
    <div className={css(styles.Content)}>
      <Banner />
      <Container>
        <div className={css(styles.TitleFiesta)}> ¿Qué es <i>Fiesta?</i> </div>
        <div className={css(styles.TextContainer, styles.TextFiesta)}>
          El proyecto surge con la idea de acercar a los y las colegas de la enseñanza lo necesario para hacer
          un acto escolar diferente a partir de la música. Para cada fecha hemos compuesto una canción:
          chacarera, rap, zamba o chamamé, entre otras. También sumamos ideas para su aplicación: teatros
          de sombras, títeres, coros y más.
        </div>
        <div className={css(styles.Title)}> ¿Qué es el acto escolar? </div>
        <BannerColumnas />
        <div className={css(styles.TextContainer, styles.Text)}>
        Entre tanta tormenta de sensaciones, los maestros disfrutamos algunas veces, y sufrimos demasiado
        con las exigencias. Por eso hemos ideado una herramienta que nos permita celebrar con mayor
        libertad, creatividad y placer. 
        </div>
        <div className={css(styles.HighlightedContainer)}>
          <div className={css(styles.HighlightedContent)}>
            <HighlightedText>
              Este material pretende ser un salvavidas solidario
              <br /> para despabilar ideas, recursos y sentimientos <br />{" "}
              olvidados o nuevos.
            </HighlightedText>
          </div>
        </div>

        <div className={css(styles.TextContainer, styles.Text)}>
          Se necesita una dosis de coraje para lanzarse en estas propuestas que
          van más a la acción y tratan de disminuir el palabrerío y el
          aburrimiento de lo pomposo. La idea es encarar lo regional y lo
          interamericano con una dinámica de taller, en la cual el alumno se
          involucre como persona, en el hacer, y no informándose sólo
          teóricamente, y con un tipo de planificación en la que se integren
          áreas y se creen eventos compartidos, para profundizar y estimular
          conductas participativas.
        </div>
        <div className={css(styles.TextContainer, styles.Text)}>
          Estas actividades fueron imaginadas en la década del 90. Apenas
          asomaba el digno deseo de escribir “niñxs” en lugar de “niños”, de
          venerar a la Pachamama y otras revoluciones sin feriado. Entre tanto
          los ámbitos educativos atravesaron años de fuerte abandono y también
          momentos de estímulo y libertad en los que aprendimos muy buenas
          maneras de celebrar.
        </div>
        <div className={css(styles.TextContainer, styles.Text)}>
          Vale la pena ofrecer la oportunidad de encontrarse con los otros en
          este hacer sencillo, con alegría y emoción.
        </div>
        <Video src={"https://www.youtube.com/embed/AbhMr80FLJs"}/>
        <div className={css(styles.Title,styles.Autores)}> Los autores </div>
        <div className={css(styles.ColumnsContainer)}>
          <div className={css(styles.Column)}>
            <Img
              className={css(styles.CircularImg)}
              src='/images/foto_pablo.png'
              alt='pablo'
            />

            <span className={css(styles.CircleTextTitle)}>Pablo Spiller</span>
            <Img
              className={css(styles.LineSeparator)}
              src='/images/horizontal_separator.png'
              alt='sep'
            />
            <span className={css(styles.TextColumn)}>
              Compositor, arreglador y guitarrista. Actualmente presenta un
              proyecto de composiciones propias junto al percusionista Alejandro
              Shmidt (disco <i>Foto en el aire</i>). Como integrante de Los Musiqueros
              (disco <i>Ronda</i>) realizó giras por América y Europa. Ha participado
              en Teresa Usandivaras Trío (disco <i>Espabílate</i>). Ha desarrollado
              proyectos propios (disco <i>El cielo desde el catre</i>). Ha participado
              como instrumentista en el disco Y <i>puedo querer</i> de Pepa Vivanco,
              también con Eli Monteagudo Trío y Septeto Siguaraya. Arreglador,
              productor musical e intérprete de los libros-disco <i>Nidos que
              arrullan</i> (editorial Ojoreja) y <i>Luna con duendes</i> (editorial
              Aerolitos). Dictó numerosos cursos además de clases de guitarra
              para niños, adolescentes y adultos. Capacitador en el Programa:
              Formación de formadores. Cultura Viva Comunitaria de la
              Subsecretaria de Hábitat e Inclusión.
            </span>
          </div>
          <div className={css(styles.Column)}>
            <Img
              className={css(styles.CircularImg)}
              src='/images/foto_pepa.png'
              alt='pepa'
            />
            <span className={css(styles.CircleTextTitle)}>Pepa Vivanco</span>
            <Img
              className={css(styles.LineSeparator)}
              src='/images/horizontal_separator.png'
              alt='sep'
            />
            <span className={css(styles.TextColumn)}>
              Maestra y capacitadora docente en jardines, escuelas primarias y
              secundarias, Universidades de Buenos Aires y Rosario, en la
              Escuela de Música Popular de Avellaneda, organismos del gobierno,
              ONG, organizaciones gremiales, populares y privadas, en provincias
              de la Argentina y en Latinoamérica. Colaboró en libros, revistas y
              ediciones pedagógicas. Realizó dos discos con canciones propias: <i>Y
              puedo querer</i> y <i>Ni hay peligro</i>, y tres discos como integrante del
              grupo Fadeiros. Publicó varios libros: <i>Exploremos el sonido</i>, Ed.
              Ricordi. <i>La música está conmigo</i>, Ed. Guadalupe. <i>Zapadas y
              ocurrencias</i> (con Eric Giles), Ed. Ricordi y <i>En música
              independencia</i> (con Violeta de Gainza), Ed. Lumen. En estos años
              continúa con su tarea docente realizando cursos y recitales,
              además de tomar clases en países de América, en espacios
              culturales zapatistas, mayas, mapuches, entre otros.
            </span>
          </div>

          <div className={css(styles.Column)}>
            <Img
              className={css(styles.CircularImg)}
              src='/images/foto_martin.png'
              alt='martin'
            />
            <span className={css(styles.CircleTextTitle)}>Martín Fumero</span>
            <Img
              className={css(styles.LineSeparator)}
              src='/images/horizontal_separator.png'
              alt='sep'
            />
            <span className={css(styles.TextColumn)}>
              En la actualidad es docente de batería, percusión, guitarra y
              composición. Coordina grupos de iniciación musical para niños/as
              entre 5 y 12 años. Realiza grabaciones, como arreglador e
              instrumentista. Dio cursos para docentes en Perú (FLADEM). Desde
              el 2018 da la materia <i>Voz y Ritmo</i> en la carrera de psicomotricidad
              de la Universidad de Tres de Febrero. Dio talleres de percusión en
              Ciudad Oculta en el 2019. Realizó giras y recitales en escuelas y
              centros culturales. Desde hace varios años acompaña con percusión
              en clases de danza africana. Estudió música con diferentes
              maestros, entre ellos: Gabriel Spiller, Horacio López, Rodrigo
              Gómez, Andrés Inchausti, Violeta Gainza, armonía y composición con
              Hernán Hayet. Tomó seminarios de música tradicional africana,
              estudió Osteopatía fluido energética y bioenergética. Realizó
              clases de teatro con Claudio Quinteros.
            </span>
          </div>
        </div>
      </Container> 
    </div>
    
  );
};

export default HomeScreen;
