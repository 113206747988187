import React from "react";
import { Link, useLocation } from "react-router-dom";
import { saveAs } from 'file-saver';


import {
  Header,
  Container,
  BannerPaper,
  BorderedButton,
  CollapsableSection,
  ImageBox,
  BandcampPlayer,
} from "../ui";
import { StyleSheet, css } from "aphrodite";
import { useState } from "react";
import { useEffect } from "react";
import Img from "../ui/components/Img";
import AudioPlayer from "../ui/components/AudioPlayer";

const styles = StyleSheet.create({
  Content: {
    flex: 1,
    margin: "0",
    maxWidth: "100%",
    boxSizing: "border-box",
    minHeight: 100,
    backgroundColor: "#fff2a6",
    paddingBottom: 100,
    // height: 450,
    width: "100%",
    position: "relative",
  },
  Column: {
    flex: 1,
    paddingTop: 44,
    width: 430,
    "@media(max-width: 795px)": {
      paddingTop: 0,
      paddingLeft: 14,
      paddingRight: 14,
      width: "auto",
      maxWidth: 500,
      margin: "auto",
    },
  },
  ColumnText: {
    fontSize: "1rem",
    textAlign: "center",
    fontFamily: "Work Sans",
    lineHeight: "24px",
  },
  ColumnContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems:"center",
    "@media(max-width: 795px)": {
      flexDirection: "column",
    },
  },
  MarginText: {
    marginRight: 40,
  },
  BannerImg: {
    //width: "100%",
    //marginTop: 20,
  },
  Title: {
    fontFamily: "Alegreya",
    fontSize: 74,
    fontWeight: "bold",
    "@media(max-width:795px)": {
      fontSize: "54px",
      lineHeight: "65px",
    },
  },
  SubTitle: {
    fontFamily: "Alegreya",
    fontSize: 36,
    whiteSpace:"nowrap",
    "@media(max-width:950px)": {
      whiteSpace:"normal",
    },
    "@media(max-width:795px)": {
      fontSize: "28px",
    },
  },
  Description:{
    fontFamily:"Work Sans",
    fontSize:"18px",
    lineHeight: "28px",
    fontWeight:500,
    marginTop:20,
  },
  FloatingBannerSpan: {
    width: "100%",
    textAlign: "center",
    fontFamily: "Work Sans, sans-serif;",
    fontWeight: 600,
    fontSize: "1rem",
    fontStyle: "italic",
    display: "block",
    whiteSpace: "nowrap",
    "@media(max-width: 500px)": {
      whiteSpace: "normal",
    },
  },
  TitlesContainer: {
    textAlign: "center",
    marginTop: 60,
  },
  PlayerContainer: {
    marginTop: 40,
    marginBottom: 20,
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "500px",
  },
  ButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    marginTop: 72,
  },
  Button: {
    marginRight: 20,
    //marginBottom: 20,
    minHeight: 28,
    padding: "0px 10px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    "@media(max-width: 551px)": {
      marginBottom: 10,
      marginTop: 10,
    },
  },
  SectionsContainer: {
    marginTop: 50,
  },
  HighlightedItem: {
    paddingRight: 2,
    marginRight: 1,
    backgroundColor: "#FFF",
  },
  TextContent: {
    maxWidth: 900,
    width: "100%",
    fontSize: 18,
    fontFamily: "Work Sans",
    lineHeight: "28px",
  },
  TextContentContainer: {
    display: "flex",
    justifyContent: "center",
  },
  CenteredContent: {
    display: "flex",
    justifyContent: "center",
  },
  BlueText: {
    fontFamily: "Alegreya",
    lineHeight: "34px",
    fontSize: 27,
    fontWeight:400,
    color: "#0f6cfc",
    marginTop: 50,
    marginBottom: 50,
  },
  MarginTopContent: {
    marginTop: 40,
    marginBottom: 40,
  },
  PartituraText: {
    color: "#0f6cfc",
    fontFamily: "Work Sans",
    //fontWeight: 600,
    fontSize: 21,
  },
  LineaPartitura: {
    marginTop: 40,
    marginBottom: 20,
    fontFamily: "Alegreya",
    color: "#000",
    fontWeight: 500,
    fontSize: 27,
    "@media(max-width: 795px)": {
      lineHeight: "35px",
    },
  },

  NotaFlotante: {
       //position: "relative",
       top: -25,
       color: "#0f6cfc",
       fontFamily: "Work Sans",
       fontWeight: 600,
       fontSize: 20,
       /*LC */
       left:0,
       position: "absolute",
       width: "100%",
       textAlign:"center",
  },
  SilabaSubrayada: {
    textDecoration: "underline",
  },
  ImgAllWidth: {
    width: 700,
    marginBottom: 72,
    marginTop: 50,
  },
  ImgDefault: {
    marginBottom: 30,
    marginTop: 30,
  },
  BoldTitle: {
    fontFamily: "Alegreya",
    fontSize: 25,
    fontWeight: "bold",
    marginTop: 60,
    marginBottom: 20,
  },
  BoldTitleNoMarginTop: {
    fontFamily: "Alegreya",
    fontSize: 25,
    fontWeight: "bold",
    marginBottom: 20,
  },
  SemiBoldText: {
    fontWeight: 600,
  },
  TwoColumnsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    "@media(max-width: 939px)": {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  TwoColumnsColumnContainer: {
    marginTop: 62,
    paddingBottom: 20,
  },
  ImageColumnObra: {
    marginTop: 10,
    maxWidth: 427,
  },
  PictureBoxContainerText: {
    display:"flex",
    justifyContent:"space-evenly",
  },
  Desktop: {
    display: "block",
    "@media(max-width: 795px)": {
      display: "none",
    },
  },
  Responsive: {
    display: "none",
    "@media(max-width: 795px)": {
      display: "block",
    },
  },
  marginAuto: {
    display: "block",
    margin: "40px auto",
  },
  DobleBox:{
    display:"flex",

  },
  BannerTextJunio:{
    paddingBottom: 44,
    paddingTop: 20,
    fontSize: "1rem",
    textAlign: "center",
    fontFamily: "Work Sans",
    lineHeight: "24px",
  },
  width100:{
    width:"100%",
  },
  MarginTopButtom:{
    margin:"44px 0",
  },
  rela:{
    position:"relative",
  },
  nowrap:{
    whiteSpace:"nowrap",
    "@media(max-width: 400px)": {
      lineHeight: "70px",
    },
  }
});

const mapWidthNotes = {
  F: 9.53,
  Bb: 28.08,
  C7: 27.41,
  G: 12.53,
  Dm: 33.86,
  A7: 25.05,
};

const Note = ({ text, note }: { text: string, note: string }) => {
  /*const marginRight = mapWidthNotes[note]
    ? -mapWidthNotes[note]
    : -1 * note.length * 16;
  const stylesNote = StyleSheet.create({
    Note: {
       left: -1 * (text.length * 12 - (text.length * 12 + marginRight) / 2),
      marginRight: marginRight, 
    },
  });*/
  if(text ==""){
    return(
    <div style={{display:"inline-block", position:"relative"}}>
        <span className={css(styles.SilabaSubrayada)}>{text}</span>
        <span className={css(styles.NotaFlotante,styles.rela)}>{note}</span>
      </div>
    )  
  }else{
    return (
      <div style={{display:"inline-block", position:"relative"}}>
        <span className={css(styles.SilabaSubrayada)}>{text}</span>
        <span className={css(styles.NotaFlotante)}>{note}</span>
      </div>
    );
  }
  
};

const TwentyJuneFestScreen = () => {
  const [sectionSelected, setSectionSelected] = useState(null);
  const [windowSize, setWindowSize] = useState({
    windowWidth: window.innerWidth,
  });
  const [columnSelected, setColumnSelected] = useState(0);

  const managedState = windowSize?.windowWidth <= 795;

  const handleResize = (e) => {
    setWindowSize({ windowWidth: window.innerWidth });
  };

  const windowOpen= ()=>{
    window.open("files/PartituraLa-primavera-Vivaldi-y-Chamame.pdf");
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleToggle = (section) => {
    console.log(section);
    if (sectionSelected === section) {
      setSectionSelected(null);
      return;
    }
    setSectionSelected(section);
  };
  return (
    <div className={css(styles.Content)}>
      <BannerPaper>
        {/* <span className={css(styles.FloatingBannerSpan)}>
          Nuestra patria antes y ahora
        </span> */}
        <Container>
          <div className={css(styles.ColumnContainer)}>
            <div
              className={css(
                styles.Column,
                styles.ColumnText,
                //styles.MarginText
              )}
            >
              
                
              
              <Img
                className={css(styles.BannerImg)}
                src="/images/historias-grandiosas-chico.png"
              />
              <span className={css(styles.FloatingBannerSpan)}> 
            Historias grandiosas: La bandera naciendo en el litoral
          </span> 
            </div>
            
            <span className={css(styles.BannerTextJunio)}>
              <Img
                src="/images/linea-historias-grandiosas.png"
                className={css(styles.width100)}
              />    
              A mí me pasaron cosas grandiosas. Cuando tenía dos años me caí adentro de un pozo y me mojé todo. Cuando
              tenía cuatro fui a ver qué había adentro de una olla y me tiré toda el agua caliente arriba del cuerpo y me llevaron
              al hospital. A los cinco años me corté con un cuchillo. Una vez me pelié con un grandote y me rompió un ojo a
              trompadas. Otra fui a buscar hielo y al cruzar la calle un auto me pisó. Otra vez que le estaba sacando higos a un
              italiano que vivía al fondo de mi casa, se rompió la rama y me di un golpe. Yo tengo recuerdos bellos de mi niñez.
            </span>
          </div>
        </Container>
      </BannerPaper>
      <Container>
        <div className={css(styles.TitlesContainer)}>
          <div className={css(styles.Title)}>20 de junio.</div>
          <div className={css(styles.Title)}>Chamamé de la Bandera</div>
          <div className={css(styles.SubTitle)}>
            –Con flautas dulces, trinos de pájaros y Primavera de Vivaldi–
          </div>
          <div className={css(styles.Description)}>
          Algunos pájaros inspiraron a Vivaldi y otros acompañaron a Manuel Belgrano en
          las barrancas del Paraná, en la creación de la bandera. Celebraremos esta historia
          extraordinaria tocando estos temas
          </div>
          <div className={css(styles.PlayerContainer)}>
            <BandcampPlayer
              trackSrc="track=2386035875"
              size="small"
              height={"42px"}
              width={"100%"}
            />
          </div>
          <div className={css(styles.ButtonsContainer)}>
            <BorderedButton
              text={"descargar partituras"}
              onClick={() => {
                windowOpen()
              }}
              styles={styles.Button}
            />
            <BorderedButton
              text={"descargar audio"}
              onClick={() => {
                window.open("files/vivaldi-chamame-de-la-bandera.mp3");
              }}
              styles={styles.Button}
            />
          </div>
        </div>
      </Container>
      <div className={css(styles.SectionsContainer)}>
        <CollapsableSection
          fixed={managedState}
          // opened={sectionSelected === "a"}
          /*toggle={() => {
            handleToggle("a");
          }}*/
          letter="A."
          title="Me toca el acto en una semana"
          pictureUrl="/images/letter_banner/a-me-toca-el-acto.png"
          pictureHoverUrl="/images/letter_banner/a-me-toca-el-acto-act.png"
        >
          <Container>
            <div className={css(styles.TextContentContainer)}>
              <div className={css(styles.TextContent)}>
              Para preparar una actuación con poco tiempo trataremos de contar con: 
                <br />
                • Algunas flautas dulces (sopranos, las comunes)
                <br />
                • Una armónica en Do y/o una melódica <br />
                • Silbatos de cotillón o artesanales
                <br />
                <br />
                El grupo seguirá la grabación acompañando las distintas partes:
                <br />
                <br />
                <span className={css(styles.HighlightedItem)}>1.</span>{" "}
                <span className={css(styles.SemiBoldText)}>Primavera de Vivaldi</span>{" "}<br />
                Algunos chicos tocarán todo el tiempo en las flautas, “sin tapar ningún agujerito” (aproximadamente
                un re) un sonido muy suave y cortito (un pulso constante como el tic-tac del reloj). <br />
                <br />
                <span className={css(styles.HighlightedItem)}>2.</span>{" "}
                <span className={css(styles.SemiBoldText)}>Improvisación de pájaros</span><br />
                Otros chicos imitarán, de a uno o de a dos, trinos de pájaros con los silbatos, silbidos y otras
                maneras que surjan con las manos y la boca. Los que tienen flautas, usarán solo el pico y explorarán
                tapando los orificios.
                <br />
                <br />
                <span className={css(styles.HighlightedItem)}>3.</span>{" "}
                <span className={css(styles.SemiBoldText)}>Chamamé parte lenta</span>{" "}<br/>
                Si no hay tiempo de aprender toda la canción, cantarán sólo el estribillo gritando fuerte el sapucay:
                “porque ando en patas, y ando sin plata, sólo me queda cantar. Iiiiiiiii!” <br />
                <br />
                <span className={css(styles.HighlightedItem)}>4.</span>{" "}
                <span className={css(styles.SemiBoldText)}>Improvisación con armónica y melódica sobre la sección instrumental</span><br />
                Un solista soplará para adentro y para afuera la armónica (en do o C). Aunque sea la primera vez
                en la vida que lo haga, cualquier nota quedará bien con el acompañamiento grabado. Si hay una
                melódica, otro solista tocará lo que quiera sólo con las teclas blancas. <br />
                <br />
                <span className={css(styles.HighlightedItem)}>5.</span>{" "}
                <span className={css(styles.SemiBoldText)}>
                  Chamamé parte rápida
                </span><br />
                Otra vez cantarán el estribillo (o toda la canción) terminando con el sapucay más fuerte que puedan.
                De paso les contamos que el buen sapucay es un grito agudo aspirado; lo podemos probar. <br />
                <br />
                <br />
              </div>
            </div>
          </Container>
        </CollapsableSection>

        <CollapsableSection
          fixed={managedState}
          
          //opened={sectionSelected === "b"}
          /*toggle={() => {
            handleToggle("b");
          }}*/
          letter="B."
          title="Voy a probar sobre la grabación"
          pictureUrl="/images/letter_banner/b-voy-a-probar.png"
          pictureHoverUrl="/images/letter_banner/b-voy-a-probar-act.png"
        >
          <Container>
            <div className={css(styles.TextContentContainer)}>
              <div className={css(styles.TextContent)}>
              Luego de algunos ensayos los chicos harán una realización musical en vivo, tocando y cantando sobre
              esta base grabada. 
                <br />
                <div
                  className={css(
                    styles.CenteredContent,
                    styles.MarginTopContent
                  )}
                >
                  <BorderedButton
                    text={"descargar base para karaoke"}
                    onClick={() => {
                      window.open("files/vivaldi-chamame-de-la-bandera-karaoke.mp3");
                    }}
                    styles={styles.Button}
                  />
                </div>
                <span>
                  Recomendamos que elijan las flautas dulces los chicos que quieran hacerlo, los demás tocarán
                  silbatos y cantarán. Todas las personas hacen el maravilloso esfuerzo de aprender un instrumento
                  cuando no se las obliga a hacerlo, pero se les da en cambio la posibilidad de probar, atraídas además
                  por la audición de ejecuciones atractivas.
                </span><br /><br />
                <span className={css(styles.HighlightedItem)}>1.</span>
                <span className={css(styles.SemiBoldText)}>
                Primavera de Vivaldi
                </span>{" "}<br />
                Si alguno se anima puede tocar la segunda voz en el momento que aparece la primera:<br/>
                sol - sol - sol – sol – siiii – sila - sol - sol - sol - sol - siiii- sila - sol - sol- sol - sol- la.<br/>
                Y, en la segunda melodía del tema, el re agudo cortito
                <br />
                <br />
                <span className={css(styles.HighlightedItem)}>2.</span>
                <span className={css(styles.SemiBoldText)}>Improvisación de pájaros</span><br /> 
                Sobre el fondo grabado de flauta dulce todos los chicos imitarán trinos de pájaros con silbatos,
                chiflidos o silbidos. La maestra señalará a algunos solistas para que muestren de a uno un solo de
                pájaro.
                <br />
                <br />
                <span className={css(styles.HighlightedItem)}>3.</span>
                <span className={css(styles.SemiBoldText)}>Chamamé parte lenta</span><br /> 
                Pueden cantar todos juntos la canción entera
                <br />
                <br />
                <div className={css(styles.BlueText)}>
                La bandera está flameando<br /> 
                por detrás del maizal<br /> 
                escúcheme Don Manuel Belgrano, y yo<br /> 
                sin poderla saludar.<br />
                <br />  
                Un sólo par de alpargatas<br /> 
                vive en esta casa mía<br /> 
                y en esta fiesta seguro<br /> 
                que le toca usarlas a la Remigia.<br /> 
                <br /> 
                (estribillo)<br /> 
                Porque ando en patas<br /> 
                Y ando sin plata<br /> 
                sólo me queda cantar (sapucay)<br />
                </div>
                <span className={css(styles.HighlightedItem)}>4.</span>
                <span className={css(styles.SemiBoldText)}>
                Improvisación con armónica y melódica sobre la sección instrumental
                </span>{" "}<br />
                Sobre el acompañamiento grabado un solista improvisará con la armónica soplando para adentro y
                para afuera libremente. Lo mismo después con las teclas blancas de la melódica y otras armónicas si
                las hay.
                <br />
                <br />
                <span className={css(styles.HighlightedItem)}>5.</span>
                <span className={css(styles.SemiBoldText)}>Chamamé parte rápida</span><br />
                Todos cantarán nuevamente.
                <br />
                <div className={css(styles.BlueText)}>
                Que aquí andamos en patas,<br />
                Explica el gobernador<br />
                No porque nos falte calzado<br />
                Sino porque hace calor<br />
                <br />  
                Adiós don Manuel Belgrano<br />
                Puede que sea otra vez<br />
                Si me tocara la alpargata<br />
                La bandera izaré<br />
                <br /> 
                (estribillo)<br /> 
                Porque ando en patas<br /> 
                Y ando sin plata<br /> 
                sólo me queda cantar (sapucay)<br />
                </div>
                <br />
                <br />
              </div>
            </div>
          </Container>
        </CollapsableSection>

        <CollapsableSection
          
          fixed={managedState}
          // opened={sectionSelected === "c"}
          /* toggle={() => {
            handleToggle("c");
          }}*/
          letter="C."
          title="Aprendo la canción y me animo sin pista"
          pictureUrl="/images/letter_banner/c-aprendo-la-cancion.png"
          pictureHoverUrl="/images/letter_banner/c-aprendo-la-cancion-act.png"
        >
          <Container>
            <div className={css(styles.TextContentContainer)}>
              <div className={css(styles.TextContent)}>
                Los docentes que cantan con alegría y tocan percusión, guitarra o piano con comodidad pueden
                ensayar esta obra prescindiendo del celular o computadora. El maestro tocará la primera voz de La
                Primavera, algunos chicos la segunda voz y otros harán los sonidos de pájaros.
                <br />
                <div
                  className={css(
                    //styles.CenteredContent,
                    styles.MarginTopContent
                  )}
                >
                  <div className={css(styles.ButtonsContainer)}>
                  <BorderedButton
                    text={"descargar letra con cifrado"}
                    onClick={() => {
                      window.open("files/chamame-de-la-bandera-cifrado.pdf");
                    }}
                    styles={styles.Button}
                  />
                  <BorderedButton
                    text={"descargar partitura vivaldi"}
                    onClick={() => {
                      window.open("files/La-primavera-vivaldi-partitura.pdf");
                    }}
                    styles={styles.Button}
                  />
                  </div>
                  
                </div>
                <div className={css(styles.PartituraText)}>
                  <span className={css(styles.BlueText)}> Chamamé de la bandera</span><br />
                  <div className={css(styles.LineaPartitura)}>
                    La <Note note="G" text="ban" />dera está <span className={css(styles.nowrap)}>fla<Note note="D7" text="mean" />do</span><br/><br/>
                    por de<Note note="D7" text="trás" /> del ma<Note text="izal" note="G" /><br/><br/>
                    es<Note note="A7" text="cú" />che Don Manuel Belgrano, y yo<br/><br/>
                    sin po<Note note="D7" text="der" />la salu<Note note="G" text="dar" /><br/><br/>
                  </div>
                  <div className={css(styles.LineaPartitura)}>
                    Un <Note note="G" text="só"/>lo par de alpar<Note note="D7" text="ga"/>tas<br/><br/>
                    vive en <Note note="D7" text="es"/>ta casa <Note note="G" text="mí" />a<br/><br/>
                    y en <Note note="A7" text="es" />ta fiesta seguro<br/><br/>
                    que le toca u<Note note="D7" text="sar"/>las a la <span className={css(styles.nowrap)}>Rem<Note note="G" text="igi"/>a.</span><br/><br/>
                  </div>
                  <div className={css(styles.BlueText)}>(estribillo)</div>
                  <div className={css(styles.LineaPartitura)}>
                    Por<Note note="" text="que"/> <Note note="G" text=""/><Note note="" text="an"/>do en <span className={css(styles.nowrap)}><Note note="D7" text="pa"/>tas</span><br/><br/> 
                     <Note note="D7" text=""/>Y ando sin <Note note="G" text="pla"/>ta<br/><br/>
                    sólo me <Note note="D7" text="que"/>da cantar <Note note="G" text=""/><span className={css(styles.BlueText)}>(sapucay)</span><br/><br/> 
                    Que <Note note="G" text="aquí"/> andamos en <span className={css(styles.nowrap)}><Note note="D7" text="pa"/>tas,</span><br/><br/>
                    Expli<Note note="D7" text="ca"/> el goberna<Note note="G" text="dor"/><br/><br/>
                    No <Note note="A7" text="por"/>que nos falte calzado<br/><br/>
                    Sino <Note note="D7" text="por"/>que hace ca<Note note="G" text="lor"/><br/><br/>
                    A<Note note="G" text="diós"/> don Manuel <span className={css(styles.nowrap)}>Bel<Note note="D7" text="gra"/>no</span><br/><br/>
                    Puede <Note note="D7" text="que"/> sea otra <Note note="G" text="ve"/>z<br/><br/>
                    Si <Note note="A7" text="me"/> tocara la alpargata<br/><br/>
                    La ban<Note note="D7" text="de"/>ra izaré <Note note="G" text=""/><br/><br/>
                  </div>
                  <div className={css(styles.BlueText)}>(estribillo)</div>
                  <div className={css(styles.LineaPartitura)}>
                    Por<Note note="" text="que"/> <Note note="G" text=""/><Note note="" text="an"/>do en <Note note="D7" text="pa"/>tas<br/><br/> 
                     <Note note="D7" text=""/>Y ando sin <Note note="G" text="pla"/>ta<br/><br/>
                    sólo me <Note note="D7" text="que"/>da cantar <Note note="G" text=""/><span className={css(styles.BlueText)}>(sapucay)</span><br/><br/>    
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </CollapsableSection>
        <CollapsableSection
          
          fixed={managedState}
          // opened={sectionSelected === "d"}
          /* toggle={() => {
            handleToggle("d");
          }}*/
          letter="D."
          title="Me encanta hacer algo distinto"
          pictureUrl="/images/letter_banner/d-me-encanta-hacer.png"
          pictureHoverUrl="/images/letter_banner/d-me-encanta-hacer-act.png"
        >
          <Container>
            <div className={css(styles.TextContentContainer)}>
              <div className={css(styles.TextContent)}>
                <div className={css(styles.BoldTitleNoMarginTop)}>Cartas a chicos de otros lugares</div>
                Envío de cartitas a un grado de otra escuela, de otro lugar, de nuestro país. Por correo o por internet
                “hola, soy Lucas. Parece que ustedes viven muy lejos, pero levantan la misma bandera que nosotros.
                Acá te mando la foto de un pájaro que hay por aquí, cuando llueve, que se llama Vieja loca, y es
                como una cigüeña negra. ¿Ahí qué pájaro tenés? Si no podés mandar la foto, dibujámelo. Te mando
                un saludo.
                <div className={css(styles.BoldTitle)}>Coro de pájaros</div>
                Nuestra bandera es nuestro símbolo patrio que nos identifica como país y que nos puede hacer sentir
                todo lo que en general nos identifica, todo lo que hemos compartido en este lugar. Por ejemplo, el
                canto de nuestros pájaros.<br/>
                Para ejercitar el contacto con nuestras pertenencias, y como simple y maravilloso ejercicio de
                sensibilización artística, recordaremos a las hermanas Cossettini (Provincia de Santa Fe) precursoras
                en la realización de coros de pájaros, por la década de 1940.
                Podemos llevar una partitura gráfica y practicarla para presentar en un acto. Conviene aprenderla de
                memoria, con roles asignados de solistas y subgrupos de diferentes pájaros mientras la maestra
                estará dirigiendo las entradas y salidas.

                <div className={css(styles.BoldTitle)}>Algunos ejemplos de partitura de coro de pájaros:</div>
                
                <div className={css(styles.ImgDefault)}>
                  Coro 1 <br/><br/>
                  <ImageBox
                        textContainerStyles={styles.PictureBoxContainerText}
                        //styles={styles.ImgDefault}
                        pictureUrl="/images/bandada-de-gorriones.png"
                        text="Bandada de gorriones que se acerca y se aleja"
                      />
                      <ImageBox
                        textContainerStyles={styles.PictureBoxContainerText}
                        //styles={styles.ImgDefault}
                        pictureUrl="/images/dos-solos.png"
                        text="Solo de silbido muy hermoso
                        como un canario o jilguero.-
                        Otro solo, silbado o hecho
                        con las manos.-
                        Los dos solos juntos."
                      />
                </div>
                <div className={css(styles.ImgDefault)}>
                  Coro 2<br/><br/>
                  <ImageBox
                        textContainerStyles={styles.PictureBoxContainerText}
                        //styles={styles.ImgDefault}
                        pictureUrl="/images/chasquido-suave.png"
                        text="Chasquido suave, como el del zorzal cuando no canta."
                      />
                      <ImageBox
                        textContainerStyles={styles.PictureBoxContainerText}
                        //styles={styles.ImgDefault}
                        pictureUrl="/images/como-un-lechuzon.png"
                        text="Como un lechuzón."
                      />
                      <ImageBox
                        textContainerStyles={styles.PictureBoxContainerText}
                        //styles={styles.ImgDefault}
                        pictureUrl="/images/como-el-crespin.png"
                        text="Como el crespín o kakuy, muy suave y triste."
                      />
                      
                </div>
                <div className={css(styles.ImgDefault)}>
                  Coro 3<br/><br/>
                  <ImageBox
                        textContainerStyles={styles.PictureBoxContainerText}
                        //styles={styles.ImgDefault}
                        pictureUrl="/images/graznidos.png"
                        text="Graznidos y chistidos fuertes como para asustar."
                      />
                  <ImageBox
                        textContainerStyles={styles.PictureBoxContainerText}
                        //styles={styles.ImgDefault}
                        pictureUrl="/images/canto-y-eco.png"
                        text="Un chico ubicado en un extremo del coro hace el canto y otro le hace el eco desde el otro extremo."
                      />
                </div>
                <div className={css(styles.ImgDefault)}>
                  Coro 4<br/><br/>
                  <ImageBox
                        textContainerStyles={styles.PictureBoxContainerText}
                        //styles={styles.ImgDefault}
                        pictureUrl="/images/todos-los-pajaros.png"
                        text="Todos los pájaros juntos."
                      />
                  <ImageBox
                        textContainerStyles={styles.PictureBoxContainerText}
                        //styles={styles.ImgDefault}
                        pictureUrl="/images/tres-pajaros-agudos.png"
                        text="Tres pájaros agudos que se van yendo."
                      />
                </div>
                <div className={css(styles.BoldTitle)}>Salir al encuentro de los pájaros</div>
                Podemos organizar una salida al campo, al río, o a una plaza muy grande, y antes de dejar que los
                chicos jueguen a lo que quieran, pedirle a cada uno que investigue a un pájaro y que entregue un
                papelito con:
                <br /><br />
                • El pájaro dibujado (si no está seguro de cuál es, que haga algo un poco imaginario).<br />
                • El sonido dibujado o anotado como pueda (para poder reproducirlo luego en el aula).<br />
                • El nombre real o un nombre inventado (hasta averiguar el nombre verdadero).<br /><br />
                Conviene coordinar el trabajo con una actitud abierta, ya que según el lugar, el horario y cómo ande
                el clima ese día, pueden quedar incorporados a nuestras partituras grillos, chicharras, o algún coro
                de sapos apareándose después de la lluvia.<br /><br />
                <ImageBox
                  styles={styles.ImgDefault}
                  pictureUrl='/images/dibujar-pajaros.png'
                />
                
              </div>
            </div>
          </Container>
        </CollapsableSection>
        <CollapsableSection
          fixed={managedState}
          
          // opened={sectionSelected === "d"}
          /* toggle={() => {
            handleToggle("d");
          }}*/
          letter="E."
          title="Fiesta por pantalla"
          pictureUrl="/images/letter_banner/e-acto-por-pantalla.png"
          pictureHoverUrl="/images/letter_banner/e-acto-por-pantalla-act.png"
        >
          <Container>
            <div className={css(styles.TextContentContainer)}>
              <div className={css(styles.TextContent)}>
                <div className={css(styles.BoldTitleNoMarginTop)}>
                Para celebrar en clase, por internet, sin preparación previa
                </div>
                <span className={css(styles.HighlightedItem)}>1.</span>{" "}
                <span className={css(styles.SemiBoldText)}>Le contaremos a nuestro grado:</span>{" "}<br />
                “Hoy, chicas, chicos, vamos a festejar el día de la bandera. Manuel Belgrano inventó una bandera para
                que nos demos cuenta de que tenemos nuestras plantas, nuestros amigos queridos, nuestros juegos
                y nuestra tierra para defender y que nadie nos la quite. Esta bandera es para tratar de estar felices
                aquí. Él la hizo al lado del río y allí había muchos pájaros.<br />
                Así que cuando empiece la música, tengan listo el cuaderno para dibujar y escribir el nombre de un
                pájaro que les gusta ver en su casa o en su barrio. Cuando lo van teniendo listo avisan con la mano y
                lo vamos viendo”.<br />
                Y mientras tanto escuchamos la grabación de la Primavera y el Chamamé. ¿Dos veces, tres veces…?<br />
                Podemos comentarles que esta canción la canta una nena de diez años y su maestra.<br />
                ¿Quiénes quieren saludar con un canto de pájaro como despedida de este festejo chamamecero?<br />
                <br />
                <span className={css(styles.HighlightedItem)}>2.</span>{" "}
                <span className={css(styles.SemiBoldText)}>Otra celebración: juego de la bandera</span>{" "}<br />
                Realizaremos este encuentro por pantalla, para encarar el profundo y controvertido tema de la
                alegría que nos da tener banderas. Por qué y con quién nos embanderamos.<br />
                “¡Empieza el festejo del 20 de junio! ¡A jugar!” exclamamos.<br />
                “¿Quién conoce esta bandera?” y mostramos la foto de nuestra bandera argentina.<br />
                “¿Y esta otra?” y aparece la wiphala, y les explicamos a los que no saben, qué es.<br />
                “¿Y ésta?” y miran la bandera blanca que usa todo el planeta en señal de “alto el fuego” y “paz”<br />
                Y charlamos mientras vamos viendo algunas más.
                <ImageBox
                  styles={styles.ImgDefault}
                  pictureUrl="/images/banderas.png"
                />
                <div
                  className={css(
                    styles.CenteredContent,
                    styles.MarginTopContent
                  )}
                >
                  <BorderedButton
                    text={"descargar fotos de banderas"}
                    onClick={() => {
                      saveAs("files/banderasImg/bandera-argentina.jpg","bandera-argentina");
                      saveAs("files/banderasImg/bandera-de-la-paz.jpg","bandera-de-la-paz");
                      saveAs("files/banderasImg/bandera-pirata.jpg","bandera-pirata");
                      saveAs("files/banderasImg/diversidad.jpg","bandera-diversidad");
                      saveAs("files/banderasImg/futbol.jpg","bandera-futbol");
                      saveAs("files/banderasImg/whipala.jpg","bandera-whipala");
                      saveAs("files/banderasImg/Banderas.jpg","bandera-Banderas");
                      
                    }}
                    styles={styles.Button}
                  />
                </div>
                “Atención. Cuando escuchen ahora la primera música, levante la mano él que adivinó cuál bandera le
                corresponde.”<br/><br/>
                Y así vamos poniendo distintos ejemplos musicales:
                <div className={css(styles.MarginTopButtom)}>
                  <AudioPlayer
                  clave={"arg"}
                  path={"files/bandera-argentina.mp3"}
                  description={"(fragmento de himno nacional y canción de la bandera)"}
                  title={"Audio bandera Argentina"}
                  />
                  <AudioPlayer
                  clave="fut"
                  path={"files/banderas-de-futbol.mp3"}
                  description={"(canción de cancha de fútbol)"}
                  title={"Audio banderas de equipos"}
                  />
                  <AudioPlayer
                  clave="wi"
                  path={"files/wipalha.mp3"}
                  description={"(canción en quechua)"}
                  title={"Audio bandera wiphala"}
                  />
                  <AudioPlayer
                  clave="paz"
                  path={"files/bandera-de-la-paz.mp3"}
                  description={"(voces hablando, gritando, riendo en idiomas del mundo)"}
                  title={"Audio bandera de la paz "}
                  />
                </div> 
                De acuerdo a la información que tengan los chicos y el maestro o la maestra, surgirán distintas
                charlas e interrogantes: ¿hay ahora piratas pero no usan banderas? ¿qué es la biopiratería? ¿se puede
                tener muchas banderas?<br /><br />
                Terminado este festejo de jugar para reflexionar, nos despediremos escuchando la grabación de la
                Primavera y el Chamamé. Porque en la Patagonia nos dijeron que la bandera es el viento y en el
                Litoral el trinar de los pájaros. 
                <br />
                <div className={css(styles.BoldTitle)}>
                Preparando durante algunas clases un acto para enviar
                </div>
                <span className={css(styles.HighlightedItem)}>1.</span>{" "}
                <span className={css(styles.SemiBoldText)}>Le contaremos a nuestro grado algunas aventuras grandiosas y relatos emocionantes de la vida de
                  Manuel Belgrano.
                </span>{" "}<br /><br />
                Después relataremos alguna historia real de nuestra familia. Yo les contaría lo que le pasó ¡de verdad!
                a mi hermana:<br />
                “Resulta que los habían dejado sin trabajo por la dictadura. Entonces juntó coraje y se fue de
                maestra rural a enseñar arriba de la montaña. Primero vivía en una carpa con sus dos hijitos chicos
                porque los adolescentes se habían quedado abajo, en la ciudad, con el marido.<br />
                Después le pusieron una casita prefabricada. Era invierno y vino un tornado; un viento feroz que
                aullaba, y le arrancó las ventanas y chapas del techo. Un vecino los refugió en su rancho y
                aparecieron los amigos a ayudarlos. Al final los campesinos de allí le enseñaron a ponerle adobe a la
                casa y el viento no se la voló nunca más.”<br /><br />
                Pediremos a nuestros chicos y chicas que pregunten en sus casas quién les puede decir alguna
                aventura o historia grandiosa de un bisabuelo, la prima, o su papá cuando era chico. Y nos contarán
                ese relato la clase siguiente.<br /><br />
                Como celebración de las cosas difíciles, divertidas y hermosas que pasan en nuestra historia, la
                maestra o el maestro mandarán un regalo: dos textos, elegidos entre todos, de los relatos de las
                familias, y algunos de la vida de Manuel Belgrano. Para que los lean en la pantalla con el fondo
                musical de pájaros de la Primavera de Vivaldi y el Chamamé de la bandera.<br /><br />
                
                <span className={css(styles.HighlightedItem)}>2.</span>{" "}
                <span className={css(styles.SemiBoldText)}>Un karaoke digital.</span>{" "}<br />
                
                Si pretendemos que los chicos canten este hermoso chamamé con texto de Laura Devetach,
                tendremos que hacer algunos juegos de técnica vocal en el zoom, para que lo pasen bien y se
                desinhiban mientras lo aprenden.<br /><br />
                • Juego nº 1:<br />
                “Cuando digo ¡ya…! todos cara de risa”. “¡Ya!”<br />
                “Ahora cuando digo ¡ya! cara de enojadísimos”.<br />
                “Ahora cara de aburridos ¡Ya!”<br />
                “Cara de locos y locas ¡Ya!”<br /><br />
                • Juego nº 2:<br />
                “Piensen cuál de las cuatro caras les gustó hacer. ¿A ver cuál eligieron? Preparados listos ¡Ya!”<br /><br />
                • Juego nº 3:<br />
                Aparece en la pantalla de la maestra o el maestro el texto de la primera estrofa. Los chicos “cara de
                aburridos o serios”, con el micrófono apagado, canten conmigo esta primera parte.
                Aparece la segunda estrofa y cantan los “cara de risa”
                Tercera estrofa y los “cara de locas y locos”
                Cuarta estrofa y los “cara de enojo”
                En el estribillo cantamos todos.<br /><br />
                • Juego nº 4:<br />
                En la siguiente clase habremos mandado la canción entera para que la prueben durante la semana.
                Entonces en este siguiente juego estilo karaoke, haremos escuchar la grabación del chamamé
                mientras cada uno que quiera o pueda, filmará con su celular lo que canta y nos lo envía. Algunas
                chicas y chicos están acostumbrados a grabar con el celular, inclusive con auriculares, para ir
                escuchando la grabación de la canción mientras registran su canto. Podrán realizar esto como tarea.
                De esta manera, podremos editar sobre la base de karaoke (letra B de este capítulo) algunas estrofas
                con solistas y otras con voces mezcladas. Y las familias recibirán esta canción cantada por sus hijos.
                
              </div>
            </div>
          </Container>
        </CollapsableSection>
      </div>
    </div>
  );
};

export default TwentyJuneFestScreen;
