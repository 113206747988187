import React, { Component } from "react";

const getUrl = ({
  trackSrc,
  size = "large",
  bgcol = "ffffff",
  linkcol = "0687f5",
  merch = null,
  tracklist = true,
  artwork = "big",
}: {
  trackSrc: string;
  size?: string;
  bgcol?: string;
  linkcol?: string;
  merch?: string | null;
  tracklist?: boolean;
  artwork?: string;
}) => {
  const merchString = merch ? `/package=${merch}` : "";
  const tracklistString = tracklist ? "" : "/tracklist=false";
  const artworkString = artwork ? `/artwork=${artwork}` : "";

  return `https://bandcamp.com/EmbeddedPlayer/${trackSrc}/size=${size}/bgcol=${bgcol}/linkcol=${linkcol}${merchString}${tracklistString}${artworkString}/`;
};

const getStyles = ({ height = "auto", width = "auto" }) => {
  return {
    border: 0,
    width,
    height,
  };
};

const BandcampPlayer = ({
  trackSrc,
  size,
  bgcol,
  linkcol,
  merch,
  tracklist,
  artwork,
  width,
  height,
  title,
}: {
  trackSrc: string;
  size?: string;
  bgcol?: string;
  linkcol?: string;
  merch?: string;
  tracklist?: boolean;
  artwork?: string;
  width?: string;
  height?: string;
  title?: string;
}) => {
  const style = getStyles({ height, width });
  const src = getUrl({
    trackSrc,
    size,
    bgcol,
    linkcol,
    merch,
    tracklist,
    artwork,
  });
  return (
    <iframe
      title={title}
      style={style}
      className='embed-responsive-item'
      src={src}
      seamless
    />
  );
};

export default BandcampPlayer;
