import React from "react";

import {
    Container,
    BannerPaper,
    BorderedButton,
    CollapsableSection,
    ImageBox,
    BandcampPlayer,
} from "../ui";
import { StyleSheet, css } from "aphrodite";
import { useState } from "react";
import { useEffect } from "react";
import Img from "../ui/components/Img";
import Video from "modules/home/Video";
import Table from "modules/ui/components/Table";

const styles = StyleSheet.create({
    Content: {
        flex: 1,
        margin: "0",
        maxWidth: "100%",
        boxSizing: "border-box",
        minHeight: 100,
        backgroundColor: "#fff2a6",
        paddingBottom: 100,
        // height: 450,
        width: "100%",
        position: "relative",
    },
    Column: {
        flex: 1,
        //paddingTop: 44,
        width: 430,
        "@media(max-width: 795px)": {
            paddingTop: 0,
            paddingLeft: 14,
            paddingRight: 14,
            width: "auto",
            maxWidth: 500,
            margin: "auto",
        },
    },
    ColumnText: {
        fontSize: "1rem",
        textAlign: "center",
        fontFamily: "Work Sans",
        lineHeight: "24px",
    },
    ColumnContainer: {
        display: "flex",
        //flexDirection: "column",
        alignItems: "center",
        "@media(max-width: 795px)": {
            flexDirection: "column",
        },
    },
    MarginText: {
        marginRight: 40,
    },
    BannerImg: {
        maxWidth: 220,
        height: 320,
        //marginTop: 20,
    },
    Title: {
        fontFamily: "Alegreya",
        fontSize: 74,
        fontWeight: "bold",
        "@media(max-width:795px)": {
            fontSize: "48px",
            lineHeight: "65px",
        },
    },
    SubTitle: {
        fontFamily: "Alegreya",
        fontSize: 36,
        whiteSpace: "nowrap",
        "@media(max-width:950px)": {
            whiteSpace: "normal",
        },
        "@media(max-width:795px)": {
            fontSize: "28px",
        },
    },
    Description: {
        fontFamily: "Work Sans",
        fontSize: "18px",
        lineHeight: "28px",
        fontWeight: 500,
        marginTop: 20,
    },
    FloatingBannerSpan: {
        width: "100%",
        marginTop: 20,
        //textAlign: "center",
        fontFamily: "Work Sans, sans-serif;",
        fontWeight: 600,
        fontSize: "1rem",
        fontStyle: "italic",
        display: "block",
        whiteSpace: "nowrap",
        "@media(max-width: 500px)": {
            whiteSpace: "normal",
        },
    },
    TitlesContainer: {
        textAlign: "center",
        marginTop: 60,
    },
    PlayerContainer: {
        marginTop: 40,
        marginBottom: 20,
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "500px",
    },
    ButtonsContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        flexWrap: "wrap",
        marginTop: 72,
    },
    Button: {
        marginRight: 20,
        //marginBottom: 20,
        minHeight: 28,
        padding: "0px 10px",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        "@media(max-width: 440px)": {
            marginBottom: 10,
            marginTop: 10,
        },
    },
    SectionsContainer: {
        marginTop: 50,
    },
    HighlightedItem: {
        paddingRight: 2,
        marginRight: 1,
        backgroundColor: "#FFF",
    },
    TextContent: {
        maxWidth: 900,
        width: "100%",
        fontSize: 18,
        fontFamily: "Work Sans",
        lineHeight: "28px",
    },
    TextContentContainer: {
        display: "flex",
        justifyContent: "center",
    },
    CenteredContent: {
        display: "flex",
        justifyContent: "center",
    },
    BlueText: {
        fontFamily: "Alegreya",
        lineHeight: "34px",
        fontSize: 27,
        fontWeight: 400,
        color: "#0f6cfc",
        marginTop: 50,
        marginBottom: 50,
    },
    MarginTopContent: {
        marginTop: 40,
        marginBottom: 40,
    },
    PartituraText: {
        color: "#0f6cfc",
        fontFamily: "Work Sans",
        //fontWeight: 600,
        fontSize: 21,
    },
    LineaPartitura: {
        marginTop: 40,
        marginBottom: 20,
        fontFamily: "Alegreya",
        color: "#000",
        fontWeight: 500,
        fontSize: 27,
        "@media(max-width: 795px)": {
            lineHeight: "35px",
        },
    },

    NotaFlotante: {
        //position: "relative",
        top: -25,
        color: "#0f6cfc",
        fontFamily: "Work Sans",
        fontWeight: 600,
        fontSize: 20,
        /*LC */
        left: 0,
        position: "absolute",
        width: "100%",
        textAlign: "center",
    },
    SilabaSubrayada: {
        textDecoration: "underline",
    },
    ImgAllWidth: {
        width: 700,
        marginBottom: 72,
        marginTop: 50,
    },
    ImgDefault: {
        marginBottom: 30,
        marginTop: 30,
    },
    BoldTitle: {
        fontFamily: "Alegreya",
        fontSize: 25,
        fontWeight: "bold",
        marginTop: 60,
        marginBottom: 20,
    },
    BoldTitleNoMarginTop: {
        fontFamily: "Alegreya",
        fontSize: 25,
        fontWeight: "bold",
        marginBottom: 20,
    },
    SemiBoldText: {
        fontWeight: 600,
    },
    TwoColumnsContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        "@media(max-width: 939px)": {
            flexDirection: "column",
            alignItems: "center",
        },
    },
    TwoColumnsColumnContainer: {
        marginTop: 62,
        paddingBottom: 20,
    },
    ImageColumnObra: {
        marginTop: 10,
        maxWidth: 427,
    },
    PictureBoxContainerText: {
        display: "flex",
        justifyContent: "space-evenly",
    },
    Desktop: {
        display: "block",
        "@media(max-width: 795px)": {
            display: "none",
        },
    },
    Responsive: {
        display: "none",
        "@media(max-width: 795px)": {
            display: "block",
        },
    },
    marginAuto: {
        display: "block",
        margin: "40px auto",
    },
    DobleBox: {
        display: "flex",
    },
    BannerTextJulio: {
        //paddingBottom: 44,
        paddingTop: 50,
        fontSize: "1rem",
        //textAlign: "center",
        fontFamily: "Work Sans",
        lineHeight: "24px",
    },
    width100: {
        width: "100%",
    },
    MarginTopButtom: {
        margin: "44px 0",
    },
    rela: {
        position: "relative",
    },
    nowrap: {
        whiteSpace: "nowrap",
        "@media(max-width: 400px)": {
            lineHeight: "70px",
        },
    },
    rotate180: {
        transform: "rotate(180deg)",
    },
    mixer: {
        textDecoration: "underline",
        color: "#0f6cfc",
        fontWeight: 600,
    },
    textCenter: {
        display: "flex",
        justifyContent: "center",
    },
    CellTable: {
        border: "white solid 4px",
    },
    DivTable: {
        "@media(max-width: 722px)": {
            overflowX: "scroll",
            overflowY: "hidden",
        },
    },
});

const mapWidthNotes = {
    F: 9.53,
    Bb: 28.08,
    C7: 27.41,
    G: 12.53,
    Dm: 33.86,
    A7: 25.05,
};

const Note = ({ text, note }: { text: string; note: string }) => {
    /*const marginRight = mapWidthNotes[note]
    ? -mapWidthNotes[note]
    : -1 * note.length * 16;
  const stylesNote = StyleSheet.create({
    Note: {
       left: -1 * (text.length * 12 - (text.length * 12 + marginRight) / 2),
      marginRight: marginRight, 
    },
  });*/
    if (text == "") {
        return (
            <div style={{ display: "inline-block", position: "relative" }}>
                <span className={css(styles.SilabaSubrayada)}>{text}</span>
                <span className={css(styles.NotaFlotante, styles.rela)}>
                    {note}
                </span>
            </div>
        );
    } else {
        return (
            <div style={{ display: "inline-block", position: "relative" }}>
                <span className={css(styles.SilabaSubrayada)}>{text}</span>
                <span className={css(styles.NotaFlotante)}>{note}</span>
            </div>
        );
    }
};

const SeventeenAugustFestScreen = () => {
    const [sectionSelected, setSectionSelected] = useState(null);
    const [windowSize, setWindowSize] = useState({
        windowWidth: window.innerWidth,
    });
    const [columnSelected, setColumnSelected] = useState(0);

    const managedState = windowSize?.windowWidth <= 795;

    const handleResize = (e) => {
        setWindowSize({ windowWidth: window.innerWidth });
    };

    const windowOpen = () => {
        window.open("files/PartituraLa-primavera-Vivaldi-y-Chamame.pdf");
    };
    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleToggle = (section) => {
        console.log(section);
        if (sectionSelected === section) {
            setSectionSelected(null);
            return;
        }
        setSectionSelected(section);
    };
    return (
        <div className={css(styles.Content)}>
            <BannerPaper>
                {/* <span className={css(styles.FloatingBannerSpan)}>
          Nuestra patria antes y ahora
        </span> */}
                <Container>
                    <div className={css(styles.ColumnContainer)}>
                        <span className={css(styles.BannerTextJulio)}>
                            Señorita: me pelié a la hora del recreo porque un
                            niño decía que yo tenía que estar en la banda de él,
                            y entre todos me pegaron y yo me tuve que defender;
                            si no me defiendo capáz que me revientan la cabeza;
                            como mi padre me dice que no me deje pegar con
                            naides, yo me defendí. Entonces me dijeron te espero
                            a la salida y yo le dije ¿a vos nunca te rompieron
                            la cabeza a pinias? y entonces me dijo guacho,
                            cayate la boca y yo le dije a mi madre no me la
                            toqués tamo, porque si bos no querés que te rrelajen
                            a tu madre, no me rrelajes la mía. No me la nombres
                            porque la mía es muy sagrada. Y el niño me dijo si
                            querés pinias peliá y yo me pelié y fue cuando usté
                            señorita Directora me llamó y me dijo párese aí.
                            <Img
                                src="/images/linea-historias-grandiosas.png"
                                className={css(
                                    styles.width100,
                                    styles.rotate180
                                )}
                            />
                            <span className={css(styles.FloatingBannerSpan)}>
                                San Martín: Las guerras y el pacifismo
                            </span>
                        </span>

                        <Img
                            className={css(styles.BannerImg)}
                            src="/images/17agostoNiño.png"
                        />
                        {/* <div
              className={css(
                styles.Column,
                styles.ColumnText,
                //styles.MarginText
              )}
            >
              
              
                
            </div> */}
                    </div>
                </Container>
            </BannerPaper>
            <Container>
                <div className={css(styles.TitlesContainer)}>
                    <div className={css(styles.Title)}>17 de agosto</div>
                    <div className={css(styles.Title)}>
                        Marcha de San Lorenzo
                    </div>
                    <div className={css(styles.SubTitle)}>
                        –Con tambores y expresión corporal‒
                    </div>
                    <div className={css(styles.PlayerContainer)}>
                        <BandcampPlayer
                            trackSrc="track=1053611673"
                            size="small"
                            height={"42px"}
                            width={"100%"}
                        />
                    </div>

                    <div className={css(styles.ButtonsContainer)}>
                        <BorderedButton
                            text={"DESCARGAR SAN LORENZO"}
                            onClick={() => {
                                window.open(
                                    "files/Fiesta_en_la_Escuela_San_Lorenzo.mp3"
                                );
                            }}
                            styles={styles.Button}
                        />
                    </div>
                    {/* <div className={css(styles.ButtonsContainer)}>
            <BorderedButton
              text={"descargar canción"}
              onClick={() => {
                window.open("files/rap-Independiente.mp3");
              }}
              styles={styles.Button}
            />
          </div> */}
                </div>
            </Container>
            <div className={css(styles.SectionsContainer)}>
                <CollapsableSection
                    fixed={managedState}
                    // opened={sectionSelected === "a"}
                    /*toggle={() => {
            handleToggle("a");
          }}*/
                    letter="A."
                    title="Me toca el acto en una semana"
                    pictureUrl="/images/letter_banner/a-me-toca-el-acto.png"
                    pictureHoverUrl="/images/letter_banner/a-me-toca-el-acto-act.png"
                >
                    <Container>
                        <div className={css(styles.TextContentContainer)}>
                            <div className={css(styles.TextContent)}>
                                <div
                                    className={css(styles.BoldTitleNoMarginTop)}
                                >
                                    Prepararando una actuación con poco tiempo
                                </div>
                                <p>
                                    Los chicos y las chicas realizarán juegos
                                    corporales que no los obliguen a memorizar
                                    tantos textos.
                                    <br />
                                    <br />
                                    ¿Por qué les encanta jugar a la guerra? En
                                    las fiestas escolares se recuerdan y valoran
                                    las victorias bélicas mientras los docentes
                                    reprimimos la violencia y exigimos
                                    tolerancia: ¡qué mensajes contradictorios!
                                    Es característico de la infancia elaborar
                                    las situaciones, resolver, metiendo el
                                    cuerpo, jugando. Por eso a esta actividad y
                                    la posterior reflexión, las planteamos desde
                                    la expresión corporal. ¿Y por qué los
                                    tambores? Somos hijos de una guerra en la
                                    que fueron callados los instrumentos y
                                    pensamientos de los nativos de estas tierras
                                    y de las culturas africanas. El cajón
                                    peruano, el candombe y el blues nacieron
                                    para poder seguir diciendo.
                                    <br />
                                    <br />
                                    Así tocaremos esta marcha de San Lorenzo,
                                    con la alegría de resucitar de tanta muerte,
                                    lo africano oculto del autor de esta música
                                    y del Sargento Cabral, como el mestizaje del
                                    propio San Martín y el que está también
                                    metido en nuestra sangre.
                                    <br />
                                    <br />
                                    Todos, vestidos con ropa cómoda y quizás de
                                    un color parecido, jugarán de distintas
                                    maneras con las indicaciones de la maestra o
                                    el maestro, mientras se escucha la
                                    grabación.
                                </p>
                                <br />
                                <span className={css(styles.HighlightedItem)}>
                                    1.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    Comienza la percusión
                                </span>
                                <br />
                                “¡Escondidos!”
                                <br />
                                Mientras se escucha la introducción, todos los
                                chicos se van metiendo debajo de las mesas que
                                habrá en el escenario.
                                <br />
                                <br />
                                <span className={css(styles.HighlightedItem)}>
                                    2.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    {" "}
                                    Avanzamos
                                </span>
                                <br />
                                “¡Arrastrándose! ¡Vamos por el suelo y por
                                arriba de las mesas!”
                                <br />
                                La maestra, que estará en un costado del
                                escenario, les dirá que salgan arrastrándose por
                                el suelo, trepando por arriba y por debajo de
                                las mesas.
                                <br />
                                <br />
                                <p>
                                    <i>
                                        (Febo asoma ya sus rayos
                                        <br />
                                        iluminan el histórico convento <br />
                                        tras los muros sordos ruidos
                                        <br />
                                        oir se dejan de corceles y de aceros
                                        <br />
                                        son las huestes que prepara San Martín
                                        <br />
                                        para luchar en San Lorenzo
                                        <br />
                                        y el clarín estridente sonó
                                        <br />
                                        y la voz del gran jefe <br />a la carga
                                        ordenó).
                                    </i>
                                    <br />
                                    <br />
                                </p>
                                <span className={css(styles.SemiBoldText)}>
                                    3.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    {" "}
                                    Percusión
                                </span>
                                <br />
                                “En fila”
                                <br />
                                Durante estos compases les pedirá a dos chicos
                                que se pongan adelante y los demás se formarán
                                detrás.
                                <br />
                                <br />
                                <span className={css(styles.SemiBoldText)}>
                                    4.{" "}
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    Improvisación con armónica y melódica sobre
                                    la sección instrumental
                                </span>
                                <br />
                                “¡Vamos, golpeando el piso!”
                                <br />
                                Mientras toma al de adelante de la mano, los
                                chicos marcharán en fila, dando la vuelta al
                                escenario.
                                <br />
                                <br />
                                <i>
                                    (Avanza el enemigo
                                    <br />
                                    al paso redoblado
                                    <br />
                                    al viento desplegado
                                    <br />
                                    su rojo pabellón).
                                </i>
                                <br />
                                <br />
                                <span className={css(styles.SemiBoldText)}>
                                    5.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    {" "}
                                    Giro y marcha
                                </span>
                                <br />
                                “¡Para el otro lado!” <br />
                                Girarán y marcharán para el otro lado.
                                <br />
                                <br />
                                <i>
                                    (Y nuestros granaderos
                                    <br />
                                    aliados de la gloria
                                    <br />
                                    inscriben en la historia
                                    <br />
                                    su página mejor).
                                </i>
                                <br />
                                <br />
                                <span className={css(styles.SemiBoldText)}>
                                    6.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    {" "}
                                    Tambores de guerra y gritos
                                </span>
                                <br />
                                “¡Vamos! ¡Acérquense! ¡detrás de las mesas!”{" "}
                                <br />
                                <br />
                                <ImageBox
                                    textContainerStyles={
                                        styles.PictureBoxContainerText
                                    }
                                    //styles={styles.ImgDefault}
                                    pictureUrl="/images/17AgostoDibujo-2.png"
                                />
                                <br />
                                <br />
                                En medio de los sonidos de la guerra, daremos
                                las consignas:
                                <br />
                                “Ayelén y Juancito salten desde arriba de la
                                mesa, corran, medialunas… lo que quieran, hasta
                                la otra pared”.
                                <br />
                                “Ahora Vicente y Julián, salten, patadas al
                                aire, corriendo, capoeira”. <br />
                                Y así va pasando todo el grado. <br />
                                “¡Ahora salen todos juntos! Sin tocar a nadie,
                                esquiven a los compañeros, quién sabe hacer
                                Karate… eso, les están disparando”.
                                <br />
                                <br />
                                <span className={css(styles.SemiBoldText)}>
                                    7.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    {" "}
                                    Grito desgarrador y percusión
                                </span>
                                <br />
                                Los que quieran quedan tirados en el suelo, como
                                lastimados. Los demás tratan de levantarlos.
                                <br />
                                La maestra y algún otro maestro que viene a
                                ayudar, cuidan de levantar la cabeza, mientras
                                los chicos los toman desde los brazos y los pies
                                para acostarlos después arriba de las mesas.{" "}
                                <br />
                                <br />
                                <i>
                                    (Cabral soldado heroico
                                    <br />
                                    cubriéndose de gloria
                                    <br />
                                    cual precio a la victoria
                                    <br />
                                    su vida rinde
                                    <br />
                                    haciéndose inmortal
                                    <br />
                                    Y allí salvó su arrojo
                                    <br />
                                    la libertad naciente
                                    <br />
                                    de medio continente
                                    <br />
                                    ¡honor, honor al gran Cabral!).
                                </i>
                                <br />
                                <br />
                                <span className={css(styles.SemiBoldText)}>
                                    8.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    {" "}
                                    Percusión
                                </span>
                                <br />
                                Terminan de acomodar a los heridos.
                                <br />
                                <br />
                                <span className={css(styles.SemiBoldText)}>
                                    9.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    {" "}
                                    Conclusión
                                </span>
                                <br />
                                La maestra les pedirá a los chicos que traigan
                                de atrás del escenario (con el desorden natural
                                de cuando hacen las cosas solos) un enorme
                                cartel con una conclusión de sus opiniones, que
                                extenderán hasta que finalice la música.
                                <br />
                                <br />
                                <i>
                                    (y allí salvó su arrojo
                                    <br />
                                    la libertad naciente
                                    <br />
                                    de medio continente
                                    <br />
                                    ¡honor, honor al gran Cabral!).
                                </i>
                                <br />
                                <br />
                                <span className={css(styles.SemiBoldText)}>
                                    10.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    {" "}
                                    Percusión africana
                                </span>
                                <br />
                                Ejemplos de posibles carteles: <br />
                                <br />
                                <div className={css(styles.textCenter)}>
                                    “NOS GUSTA LUCHAR POR LO NUESTRO. QUEREMOS
                                    SER VALIENTES. LOS CHICOS DE ESTE GRADO
                                    DECIDIMOS QUE EN ESTA ÉPOCA NO IRÍAMOS A LA
                                    GUERRA PARA LOGRARLO. SOMOS PACIFISTAS.”
                                    <br />
                                    <br />
                                    “NOSOTROS ADMIRAMOS A SAN MARTÍN Y PENSAMOS
                                    QUE SI ES NECESARIO IRÍAMOS A LA GUERRA PARA
                                    DEFENDER NUESTRA LIBERTAD.”
                                    <br />
                                    <br />
                                    “NOS GUSTA LUCHAR POR LO NUESTRO. LOS CHICOS
                                    DE ESTE GRADO NO NOS PUSIMOS DE ACUERDO PARA
                                    DECIDIR SI SON NECESARIAS LAS ARMAS Y LA
                                    GUERRA PARA LOGRARLO.”
                                    <br />
                                    <br />
                                </div>
                                Todos saludan al público y se van retirando.
                                <br />
                                <br />
                                <br />
                            </div>
                        </div>
                    </Container>
                </CollapsableSection>

                <CollapsableSection
                    fixed={managedState}
                    //opened={sectionSelected === "b"}
                    /*toggle={() => {
            handleToggle("b");
          }}*/
                    letter="B."
                    title="Voy a probar sobre la grabación"
                    pictureUrl="/images/letter_banner/b-voy-a-probar.png"
                    pictureHoverUrl="/images/letter_banner/b-voy-a-probar-act.png"
                >
                    <Container>
                        <div className={css(styles.TextContentContainer)}>
                            <div className={css(styles.TextContent)}>
                                <div
                                    className={css(styles.BoldTitleNoMarginTop)}
                                >
                                    Tocando sobre la grabación, en karaoke
                                </div>
                                Contando con algunos ensayos, los chicos harán
                                una realización musical en vivo, cantando la
                                marcha de San Lorenzo sobre la base grabada.
                                <br />
                                {/*  <div
                  className={css(
                    styles.CenteredContent,
                    styles.MarginTopContent
                  )}
                >
                  <BorderedButton
                    text={"descargar base para karaoke"}
                    onClick={() => {
                      window.open("files/rap-independiente-karaoke.mp3");
                    }}
                    styles={styles.Button}
                  />
                </div> */}
                                <div className={css(styles.PlayerContainer)}>
                                    <BandcampPlayer
                                        trackSrc="track=2334398392"
                                        size="small"
                                        height={"42px"}
                                        width={"100%"}
                                    />
                                    <div
                                        className={css(styles.ButtonsContainer)}
                                    >
                                        <BorderedButton
                                            text={"DESCARGAR KARAOKE"}
                                            onClick={() => {
                                                window.open(
                                                    "files/Fiesta_en_la_Escuela_San_Lorenzo_Karaoke.mp3"
                                                );
                                            }}
                                            styles={styles.Button}
                                        />
                                    </div>
                                </div>
                                <br />
                                <br />
                                En la sección de la guerra, cuando se escucha
                                “vamooooos”, pueden gritar: “guariló”, palabra
                                rioplatense, como otras exclamaciones para dar
                                ánimo: “con todo”…“eso”…
                                <br />
                                <br />
                                En la última sección sobre la percusión
                                africana, pueden leer noticias del momento
                                relacionadas con hechos bélicos o situaciones de
                                violencia. Cuando los compañeros extiendan el
                                cartel con sus opiniones, se preparan para
                                despedirse diciendo por ejemplo: “muchas gracias
                                por escuchar nuestros pensamientos, hasta la
                                próxima, ¡Viva San Martín!”
                                <br />
                                <br />
                                La realización puede ser exclusivamente musical,
                                finalizando con la exposición del cartel, o
                                puede integrar además los juegos corporales. En
                                este caso, los chicos de un grado ubicado en el
                                costado del escenario cantarán y harán los
                                sonidos y los de otro grado realizarán los
                                juegos sobre las mesas.
                                <br />
                                <br />
                                <div className={css(styles.BlueText)}>
                                    Febo asoma ya sus rayos
                                    <br />
                                    iluminan el histórico convento
                                    <br />
                                    tras los muros sordos ruidos
                                    <br />
                                    oir se dejan de corceles y de aceros
                                    <br />
                                    son las huestes que prepara San Martín
                                    <br />
                                    para luchar en San Lorenzo
                                    <br />
                                    y el clarín estridente sonó
                                    <br />
                                    y la voz del gran jefe <br />
                                    a la carga ordenó.
                                    <br />
                                    <br />
                                    Avanza el enemigo
                                    <br />
                                    al paso redoblado
                                    <br />
                                    al viento desplegado
                                    <br />
                                    su rojo pabellón.
                                    <br />
                                    y nuestros granaderos
                                    <br />
                                    aliados de la gloria
                                    <br />
                                    inscriben en la historia
                                    <br />
                                    su página mejor.
                                    <br />
                                    <br />
                                    Cabral soldado heroico
                                    <br />
                                    cubriéndose de gloria
                                    <br />
                                    cual precio a la victoria
                                    <br />
                                    su vida rinde
                                    <br />
                                    haciéndose inmortal
                                    <br />
                                    Y allí salvó su arrojo
                                    <br />
                                    la libertad naciente
                                    <br />
                                    de medio continente
                                    <br />
                                    ¡honor, honor al gran Cabral!
                                    <br />
                                    <br />
                                    y allí salvó su arrojo
                                    <br />
                                    la libertad naciente
                                    <br />
                                    de medio continente
                                    <br />
                                    ¡honor, honor al gran Cabral! <br />
                                    <br />
                                    <br />
                                </div>
                            </div>
                        </div>
                    </Container>
                </CollapsableSection>

                <CollapsableSection
                    fixed={managedState}
                    // opened={sectionSelected === "c"}
                    /* toggle={() => {
            handleToggle("c");
          }}*/
                    letter="C."
                    title="Aprendo la canción y me animo sin pista"
                    pictureUrl="/images/letter_banner/c-aprendo-la-cancion.png"
                    pictureHoverUrl="/images/letter_banner/c-aprendo-la-cancion-act.png"
                >
                    <Container>
                        <div className={css(styles.TextContentContainer)}>
                            <div className={css(styles.TextContent)}>
                                <div
                                    className={css(styles.BoldTitleNoMarginTop)}
                                >
                                    Realización musical en vivo
                                </div>
                                Los docentes que cantan y tocan con alegría
                                pueden elegir entre dos versiones:
                                <br />
                                <br />
                                <span className={css(styles.HighlightedItem)}>
                                    1.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    Si les gusta la percusión
                                </span>
                                arman una banda con baldes de plástico y
                                tambores o con las mesas de la sala. Eligiendo
                                una base rítmica que conozcan (reggae, blues con
                                swing, etc.) diferencian sonidos agudos, medios
                                y graves y organizan las distintas secciones de
                                climas de guerra o tranquilidad. Y llaman a
                                algunos compañeros docentes o a algunas madres o
                                padres para que canten la marcha.
                                <br />
                                <br />
                                <span className={css(styles.HighlightedItem)}>
                                    2.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    {" "}
                                    Si les gusta el canto,{" "}
                                </span>
                                enseñan la marcha de San Lorenzo y llaman a
                                algún padre o madre “baterista” para que los
                                acompañe.
                                <br />
                                <br />
                                <br />
                            </div>
                        </div>
                    </Container>
                </CollapsableSection>
                <CollapsableSection
                    fixed={managedState}
                    // opened={sectionSelected === "d"}
                    /* toggle={() => {
            handleToggle("d");
          }}*/
                    letter="D."
                    title="Me encanta hacer algo distinto"
                    pictureUrl="/images/letter_banner/d-me-encanta-hacer.png"
                    pictureHoverUrl="/images/letter_banner/d-me-encanta-hacer-act.png"
                >
                    <Container>
                        <div className={css(styles.TextContentContainer)}>
                            <div className={css(styles.TextContent)}>
                                <div
                                    className={css(styles.BoldTitleNoMarginTop)}
                                >
                                    Otras ideas de aplicación
                                </div>
                                Para que los chicos emitan realmente sus
                                opiniones en los carteles, en graffitis escritos
                                sobre papeles grandes o en la selección de
                                noticias se podrán realizar algunas actividades
                                de investigación y debate.
                                <br />
                                <br />
                                <span className={css(styles.HighlightedItem)}>
                                    1.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    Debate
                                </span>
                                <br />
                                En las charlas en el grado suelen hablar los
                                alumnos más seguros; los temerosos no dan su
                                opinión y en general todos terminan dando la
                                razón a los líderes del grupo, que son líderes
                                por su humor y soltura o porque tienen buenas
                                notas, pero no reflejan necesariamente el
                                pensamiento de todos los integrantes.
                                <br />
                                <br />
                                Proponemos un juego para que todos se expresen y
                                que además divierte mucho a los chicos porque
                                les permite “opinar con el cuerpo” en lugar de
                                quedarse quietos como suelen estar en el aula.
                                <br />
                                <br />
                                1º - Se sientan en dos filas, uno detrás de
                                otro.
                                <br />
                                2º - La maestra pregunta algo: ¿tenés pelo
                                enrulado? y cada uno piensa la respuesta sin
                                hablar.
                                <br />
                                3º - La maestra grita “¡ya!” y el que quiere
                                contestar “sí”, salta al costado, el que “no”
                                salta al medio, <br />
                                y el que “más o menos” o “no sé” se queda en la
                                fila. Si alguno comentó su opinión antes del
                                “¡ya!” <br />
                                queda invalidada su respuesta.
                                <br />
                                4º - La maestra va anotando aproximadamente las
                                respuestas
                                <br />
                                <br />
                                <ImageBox
                                    textContainerStyles={
                                        styles.PictureBoxContainerText
                                    }
                                    //styles={styles.ImgDefault}
                                    pictureUrl="/images/17AgostoDibujo-3.png"
                                />
                                <br />
                                <br />
                                Lista de preguntas que puede tener la maestra
                                para anotar aproximadamente las respuestas.
                                <br />
                                <br />
                                <div className={css(styles.DivTable)}>
                                    <Table />
                                </div>
                                <br />
                                <br />
                                A partir de las respuestas surgen naturalmente
                                algunos comentarios y una síntesis de la
                                maestra.
                                <br />
                                <br />
                                <ImageBox
                                    textContainerStyles={
                                        styles.PictureBoxContainerText
                                    }
                                    //styles={styles.ImgDefault}
                                    pictureUrl="/images/17AgostoDibujo-4.png"
                                />
                                <br />
                                <br />
                                <p>
                                    Según la modalidad del docente y del grupo,
                                    surgirán distintas preguntas y respuestas.
                                    Lo importante no es ponerse de acuerdo
                                    obligadamente sino aprender a opinar,
                                    informarse y decir lo que se cree
                                    sinceramente y sin miedo. De esta manera el
                                    docente enseñará a sus alumnos a ser
                                    valientes en lugar de enseñarles a someterse
                                    repitiendo de memoria: “San Martín luchó
                                    valientemente...”
                                </p>
                                <span className={css(styles.HighlightedItem)}>
                                    2.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    Investigación
                                </span>
                                <br />
                                Tomando la coherencia del modelo de San Martín
                                para trabajar el difícil tema de la guerra y el
                                pacifismo, recordaremos algunas técnicas de
                                reflexión que pueden concluir en el armado de
                                los carteles y noticieros de la actuación:{" "}
                                <br />
                                • Armar un programa de radio con entrevistas a
                                ex-guerrilleros, combatientes y militares por un
                                lado y a representantes de posturas pacifistas:
                                ex-hippies y gente de organismos de derechos
                                humanos, por el otro.
                                <br />
                                • Dibujar y redactar un diario tomando noticias
                                reales de conflictos bélicos del momento,
                                analizando las luchas de poder que hay detrás de
                                cada guerra.
                                <br />
                                • Grabar sonidos de armas de guerra (con la voz
                                y objetos), dibujar las armas o dibujar a los
                                vendedores de armamentos mientras la maestra
                                agrega algún cartel informativo: “Después de la
                                1ª Guerra Mundial, la Liga de la Naciones
                                produjo un informe culpando de la contienda al
                                voraz tráfico de armas. El negocio mundial de
                                armas mueve anualmente unos 900 millones de
                                dólares” (Newsweek).
                                <br />
                                <br />
                                <span className={css(styles.HighlightedItem)}>
                                    3.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    {" "}
                                    Invitaciones a músicos
                                </span>
                                <br />
                                Para realizar la experiencia de expresión
                                corporal, la marcha de San Lorenzo puede ser
                                ejecutada con ayuda de invitados:
                                <br />
                                • Alumnos o ex alumnos músicos
                                <br />
                                • Padres músicos
                                <br />
                                • Una banda del pueblo o de la Municipalidad
                                <br />
                                <br />
                                Estos músicos pueden acompañar la marcha leyendo
                                la partitura o tocando de oído. Les pediremos
                                otros temas de su repertorio para compartir la
                                fiesta.
                                <br />
                                <br />
                                <span className={css(styles.HighlightedItem)}>
                                    4.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    {" "}
                                    Para nenes chiquitos
                                </span>
                                <br />
                                La realización de la marcha de San Lorenzo con
                                los juegos corporales es totalmente natural y
                                divertida para los más pequeños. Cambiaremos
                                sólo los carteles escritos, por un gran cartel,
                                un buen fondo negro y bien recortados por la
                                maestra o alguna madre, los dibujos que hayan
                                ido haciendo los chicos espontáneamente mientras
                                la maestra les contaba historias de San Martín,
                                sin reprimir la maravillosa forma de reflexión
                                de esta edad, que es hacer preguntas y
                                comentarios incluyendo las historias personales,
                                “que un tío mío también se animó a cruzar una
                                montaña”... “creo que no tenían aviones”… etc.
                                <br />
                                <br />
                            </div>
                        </div>
                    </Container>
                </CollapsableSection>
                <CollapsableSection
                    fixed={managedState}
                    // opened={sectionSelected === "d"}
                    /* toggle={() => {
            handleToggle("d");
          }}*/
                    letter="E."
                    title="Fiesta por pantalla"
                    pictureUrl="/images/letter_banner/e-acto-por-pantalla.png"
                    pictureHoverUrl="/images/letter_banner/e-acto-por-pantalla-act.png"
                >
                    <Container>
                        <div className={css(styles.TextContentContainer)}>
                            <div className={css(styles.TextContent)}>
                                <div
                                    className={css(styles.BoldTitleNoMarginTop)}
                                >
                                    Para realizar una celebración sin
                                    preparación previa
                                </div>
                                Empezaremos la clase anunciando: “Chicas,
                                chicos, hoy vamos a recordar al Gral. San
                                Martín”
                                <br />
                                <br />
                                <span className={css(styles.HighlightedItem)}>
                                    1.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    {" "}
                                    Con los grados de primero y segundo ciclo{" "}
                                </span>
                                haremos la actividad de expresión corporal
                                descripta en A. Sólo pediremos que busquen dos
                                sillas o una mesa de la que puedan saltar, y que
                                ubiquen la pantalla para que se lo vea a cada
                                uno y a cada una jugando en su casa. <br />
                                Hace unas décadas se hubiera pedido solo a los
                                varones que jugaran a luchar y desplegar su
                                fuerza y a las nenas que jugaran a curar o a las
                                muñecas. Hoy sabemos que al lado de los jefes
                                indios y criollos pelearon mujeres. <br />
                                Escucharán la grabación e iremos dando las
                                consignas: “escondidos, arrastrándose, salten, a
                                luchar, están heridos, etc
                                <br />
                                Mientras la música va finalizando, opinarán
                                libremente sobre la guerra y la paz.
                                <br />
                                Podemos enviar después la grabación de esta
                                actividad a las familias.
                                <br />
                                <br />
                                <span className={css(styles.HighlightedItem)}>
                                    2.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    {" "}
                                    Con los grados de tercer ciclo{" "}
                                </span>
                                invitaremos a un representante de organismos de
                                derechos humanos y le haremos una entrevista.
                                <br />
                                Los recibiremos o despediremos con música para
                                agradecer: alguna chica que toca lindo un
                                instrumento, algún chico al que le gusta cantar,
                                o una buena grabación que tengamos de todo el
                                grupo de alguna clase de comienzo de año.
                                <br />
                                <div className={css(styles.BoldTitle)}>
                                    Preparación de una celebración armando
                                    tambores
                                </div>
                                Mandaremos a nuestro grado una lista bien
                                generosa de objetos interesantes para poder
                                hacer percusión en las casas.
                                <br />
                                También enviaremos unos videos para que se
                                entusiasmen escuchando qué linda música se puede
                                tocar aunque no tengamos instrumentos comprados.
                                Para que comprueben que, tengan la edad que
                                tengan, las manos pueden desplegar una natural
                                conexión con ritmos ancestrales.
                                <br />
                                <br />
                                <Video
                                    src={
                                        "https://www.youtube.com/embed/7dGe5VLEVYk"
                                    }
                                />
                                <br />
                                <br />
                                <Video
                                    src={
                                        "https://www.youtube.com/embed/i692mWylnPg"
                                    }
                                />
                                <br />
                                <br />
                                En algunos hogares hay diversos “cachivaches” a
                                disposición. En algunos barrios hay fábricas,
                                restaurantes o negocios donde se puede pedir
                                algo que sea útil.
                                <br />
                                <br />
                                “Chicos y chicas ¿podrán conseguir alguna de
                                estas cosas para armar un tambor?”
                                <br />
                                • Bidón de agua
                                <br />
                                • Tacho de pintura grande o mediano
                                <br />
                                • Balde de plástico sano o roto
                                <br />
                                • Caja de cartón redonda y grande (de dulce)
                                <br />
                                • Latas grandes o medianas
                                <br />
                                • Dos trozos de palo de escoba o ramas de árbol
                                lijados
                                <br />
                                • Un trozo de tela, un poco de algodón o
                                gomapluma, y cinta de embalar o de electricista
                                para hacer una maza (baqueta con un extremo
                                redondo y blando)
                                <br />
                                <br />
                                Con estos elementos, realizaremos una clase
                                haciendo diálogos con cada chico: el maestro
                                pregunta con un ritmo y le contestan copiándolo
                                o inventando una respuesta.
                                <br />
                                Sorpresas: a veces alguien de 6 años brilla
                                tocando, y alguien de 12 años tiene vergüenza e
                                inseguridad.
                                <br />
                                Los que puedan van grabando con un celular. Si
                                el maestro sostuvo siempre un mismo pulso (con
                                un click, o de oído), editará después las
                                grabaciones rítmicas para cantar la marcha de
                                San Lorenzo sobre esa base.
                                <br />
                                <div className={css(styles.BoldTitle)}>
                                    “Guariló”, el fuego de los tambores
                                </div>
                                La percusión africana es un arma de felicidad de
                                tal potencia que quizás atemorizó al europeo.
                                <br />
                                Buscaremos entrenarnos por pantalla, inclusive
                                sin tambores, para acercarnos a sentir el ritual
                                grupal. Será una forma trascendente de festejar
                                la valentía de San Martín, o los logros de paz y
                                de la vida. Aprendiendo de estas culturas a
                                realizar ceremonias sin escenario.
                                <br />
                                <br />
                                <span className={css(styles.HighlightedItem)}>
                                    1.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    Fácil{" "}
                                </span>
                                <br />
                                <Video
                                    src={
                                        "https://www.youtube.com/embed/j7jZga4a6oI"
                                    }
                                />
                                <br />
                                <br />
                                <span className={css(styles.HighlightedItem)}>
                                    2.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    {" "}
                                    Más difícil
                                </span>
                                <br />
                                <Video
                                    src={
                                        "https://www.youtube.com/embed/IiO6Vyw1ZIo"
                                    }
                                />
                                <br />
                                <br />
                                <span className={css(styles.HighlightedItem)}>
                                    3.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    {" "}
                                    Para improvisar y sentir el ritual de
                                    integrarse a otros, probando tocar
                                    libremente con el video
                                </span>
                                <br />
                                <Video
                                    src={
                                        "https://www.youtube.com/embed/cOY3GfvLU4Q"
                                    }
                                />
                                <br />
                                <br />Y cuando un grado aprende a festejar con
                                el poderoso entendimiento rítmico grupal, basta
                                la fecha de San Martín, o el cumpleaños de unos
                                compañeros o la curación de la maestra que
                                estaba con covid, para que empecemos un ritmo.{" "}
                                <br />
                                Silenciando los micrófonos de ellos mientras
                                tocan, y escuchando solo al maestro, tocamos
                                juntos. A veces des-silenciamos a una chica o un
                                chico, que es escuchado por el grupo. Por la
                                pantalla, verán que estamos participando todos,
                                pero se escucha solo el propio toque y del
                                maestro.
                                <br />
                                <br />
                            </div>
                        </div>
                    </Container>
                </CollapsableSection>
            </div>
        </div>
    );
};

export default SeventeenAugustFestScreen;
