import React from "react";
import { StyleSheet, css } from "aphrodite";
import { useState } from "react";
import Img from "../components/Img";

const styles = StyleSheet.create({
  root: {
    flex: "1",
    flexDirection: "column",
    display: "flex",
  },
  Header: {
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    paddingBottom: 75,
  },
  HeaderoCollapsed: {
    backgroundImage: "url('./images/letter_banner/papel-azul-a.png')",
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    background: "cover",
    padding: "0 2%",
    paddingBottom: 75 ,
    "@media(max-width: 795px)": {
      backgroundImage: "url('./images/papel-azul-a-responsive.png')",
    },
  },
  HeaderDisplayed: {
    backgroundImage: "url('./images/letter_banner/papel-blanco-a.png')",
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    background: "cover",
    padding: "0 2%",
    paddingBottom: 75 ,
  },
  Separator: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    marginRight:10,
    "@media(max-width: 795px)": {
      marginLeft: 20,
      marginRight: 20,
    },
  },
  Letter: {
    fontFamily: "Alegreya",
    fontSize: 177,
    //width: "33%",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    "@media(max-width: 795px)": {
      fontSize: 100,
      width: "inherit",
      //paddingRight: 10,
     // marginLeft: 30,
      color: "white",
    },
  },
  Textopened: {
    color: "white",
  },
  TextDisplayed: {
    color: "#1d75fd",
  },
  TitleContainer: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    paddingLeft: 20,
    //paddingRight: 100,
    "@media(max-width: 795px)": {
      paddingLeft: 20,
      paddingRight: 20,
      textAlign: "center",
    },
  },
  Title: {
    fontFamily: "Alegreya",
    fontSize: 56,
    lineHeight: "56px",
    "@media(max-width: 795px)": {
      fontSize: 36,
      lineHeight: "46px",
    },
  },
  Picture: {
    //width: "66%",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    "@media(max-width: 795px)": {
      width: "inherit",
    },
  },
  PictureImg: {
    maxWidth: 320,
    width: "100%",
    "@media(max-width: 795px)": {
      //height: 160,
      //paddingLeft: 10,
      width: "100%",
      maxWidth: 220,
    },
  },
  SeparatorImg: {
    height: "70%",
  },
  ContentContainer: {
    paddingTop: 72,
    paddingBottom: 20,
  },
  Content: {
    transition: "all 0.5s ease-in",
    overflow: "hidden",
  },
  Collapsed: {
    // visibility: "hidden",
    height: 0,
    minHeight: 0,
  },
  Displayed: {
    // visibility: "visible",
    heght: "100%",
    minHeight: 400,
  },
  HeaderColumn5050: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  Desktop: {
    display: "flex",
    "@media(max-width: 795px)": {
      display: "none",
    },
  },
  Responsive: {
    display: "none",
    "@media(max-width: 795px)": {
      display: "flex",
    },
  },
  ContentFloated: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    padding: "0px 15px",
  },
  InColumn: {
    flex: 1,
    flexDirection: "column",
    display: "flex",
  },
});

const CollapsableSection = ({
  children,
  letter,
  pictureUrl,
  pictureHoverUrl,
  title,
  fixed = false,
}: {
  children: any;
  letter: string;
  title: string;
  pictureUrl: string;
  pictureHoverUrl: string;
  fixed?: boolean;
}) => {
  const [hover, setHover] = useState(false);

  const [opened, setOpened] = useState(false);

  const toggle =( open: boolean) =>{
    setOpened(!open)
  }
  


  const handleMouseEnter = () => {
    if (!fixed) {
      setHover(true);
    }
  };

  const handleMouseLeave = () => {
    if (!fixed) {
      setHover(false);
    }
  };

  const scrollfunc = ()=>{

    if(opened ){
      return;
    }
    let elemen = document.getElementById(letter)
    
   
     if(elemen !== null){
       setTimeout(()=>{
         let elemen2 = document.getElementById(letter)
         if(elemen2){
          let elDistanceToTop = window.pageYOffset + elemen2?.getBoundingClientRect().top - 74
        console.log(elemen,elDistanceToTop)
         window.scrollTo({
           left:0,
           top:elDistanceToTop,
           behavior: 'smooth',
         })
         }
        
       },200)
        
        } 
  }
  return (
    <div className={css(styles.root)} id={letter}>
      <div
        className={css(
          styles.Header,
          (opened || hover) && !fixed
            ? styles.HeaderDisplayed
            : styles.HeaderoCollapsed
        )}
        onClick={() => {
          
          if (!fixed) {
            toggle(opened);
          }
          scrollfunc()
        }}
        
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className={css(styles.HeaderColumn5050, styles.Desktop)}>
          <div
            className={css(
              styles.Letter,
              (opened || hover) && !fixed
                ? styles.TextDisplayed
                : styles.Textopened
            )}
          >
            {letter}
          </div>
          <div className={css(styles.Separator)}>
            <Img
              className={css(styles.SeparatorImg)}
              src="/images/letter_banner/linea-a-1.png"
              alt={"fiesta"}
            />
          </div>
          <div className={css(styles.Picture)}>
            <Img
              className={css(styles.PictureImg)}
              src={opened || hover ? pictureHoverUrl : pictureUrl}
              alt={"fiesta"}
            />
          </div>
        </div>
        <div className={css(styles.Separator, styles.Desktop)}>
          <Img
            className={css(styles.SeparatorImg)}
            src="/images/letter_banner/linea-a-2.png"
            alt={"fiesta"}
          />
        </div>
        <div className={css(styles.HeaderColumn5050, styles.Desktop)}>
          <div className={css(styles.TitleContainer)}>
            <span
              className={css(
                styles.Title,
                (opened || hover) && !fixed
                  ? styles.TextDisplayed
                  : styles.Textopened
              )}
            >
              {title}
            </span>
          </div>
        </div>

        <div className={css(styles.InColumn, styles.Responsive)}>
          <div className={css(styles.ContentFloated)}>
            <div
              className={css(
                styles.Letter,
                (opened || hover) && !fixed
                  ? styles.TextDisplayed
                  : styles.Textopened
              )}
            >
              {letter}
            </div>
            <div className={css(styles.Separator)}>
              <Img
                className={css(styles.SeparatorImg)}
                src="/images/letter_banner/linea-a-1.png"
                alt={"fiesta"}
              />
            </div>
            <div className={css(styles.Picture)}>
              <Img
                className={css(styles.PictureImg)}
                src={opened || hover ? pictureHoverUrl : pictureUrl}
                alt={"fiesta"}
              />
            </div>
          </div>
          <div className={css(styles.TitleContainer)}>
            <span
              className={css(
                styles.Title,
                (opened || hover) && !fixed
                  ? styles.TextDisplayed
                  : styles.Textopened
              )}
            >
              {title}
            </span>
          </div>
        </div>
      </div>
      <div className={css(styles.ContentContainer)}>
        <div
          className={css(
            styles.Content,
            opened || fixed ? styles.Displayed : styles.Collapsed,
            console.log(opened + " "+fixed)
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
export default CollapsableSection;
