import React from "react";

import {
    Container,
    BannerPaper,
    BorderedButton,
    CollapsableSection,
    ImageBox,
    ImageBoxCenter,
    BandcampPlayer,
} from "../ui";
import { StyleSheet, css } from "aphrodite";
import { useState } from "react";
import { useEffect } from "react";
import Img from "../ui/components/Img";
import Video from "modules/home/Video";

const styles = StyleSheet.create({
    Content: {
        flex: 1,
        margin: "0",
        maxWidth: "100%",
        boxSizing: "border-box",
        minHeight: 100,
        backgroundColor: "#fff2a6",
        paddingBottom: 100,
        // height: 450,
        width: "100%",
        position: "relative",
    },
    Column: {
        flex: 1,
        //paddingTop: 44,
        width: 430,
        "@media(max-width: 795px)": {
            paddingTop: 0,
            paddingLeft: 14,
            paddingRight: 14,
            width: "auto",
            maxWidth: 500,
            margin: "auto",
        },
    },
    ColumnText: {
        fontSize: "1rem",
        textAlign: "center",
        fontFamily: "Work Sans",
        lineHeight: "24px",
    },
    ColumnContainer: {
        display: "flex",
        //flexDirection: "column",
        alignItems: "center",
        "@media(max-width: 795px)": {
            flexDirection: "column",
        },
    },
    MarginText: {
        marginRight: 40,
    },
    MarginLinea: {
        marginBottom: "10px",
    },
    ChTresMarginText: {
        // marginRight: 40,
        marginLeft: "-120px",
    },
    ChCuatroMarginText: {
        // marginRight: 40,
        marginLeft: "80px",
    },
    TextBoxHead: {
        flex: "1 1 0%",
        width: "430px",
        fontSize: "14px",
        textAlign: "center",
        fontFamily: "Work Sans",
        lineHeight: "20px",
        //marginRight: "40px",
        /*fontSize: "13px",
        fontFamily: "Work Sans",
        lineHeight: "15px",*/
    },
    BannerImg: {
        width: "100%",
    },
    BannerImgCharacter: {
        width: "80%",
    },
    BordeBlanco: {
        border: "4px solid white",
        marginTop: 10,
        marginBottom: 10,
    },

    Title: {
        fontFamily: "Alegreya",
        fontSize: 74,
        fontWeight: "bold",
        "@media(max-width:795px)": {
            fontSize: "48px",
            lineHeight: "65px",
        },
    },
    SubTitle: {
        fontFamily: "Alegreya",
        fontSize: 36,
        whiteSpace: "nowrap",
        "@media(max-width:950px)": {
            whiteSpace: "normal",
        },
        "@media(max-width:795px)": {
            fontSize: "28px",
        },
    },
    Description: {
        fontFamily: "Work Sans",
        fontSize: "18px",
        lineHeight: "28px",
        fontWeight: 500,
        marginTop: 20,
    },
    FloatingBannerSpan: {
        width: "100%",
        marginTop: 20,
        marginBottom: 10,
        //textAlign: "center",
        fontFamily: "Work Sans, sans-serif;",
        fontWeight: 600,
        fontSize: "1rem",
        fontStyle: "italic",
        display: "block",
        whiteSpace: "nowrap",
        "@media(max-width: 500px)": {
            whiteSpace: "normal",
        },
    },
    TitlesContainer: {
        textAlign: "center",
        marginTop: 60,
    },
    PlayerContainer: {
        marginTop: 40,
        marginBottom: 20,
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "500px",
    },
    ButtonsContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        flexWrap: "wrap",
        marginTop: 72,
    },
    Button: {
        marginRight: 20,
        //marginBottom: 20,
        minHeight: 28,
        padding: "0px 10px",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        "@media(max-width: 440px)": {
            marginBottom: 10,
            marginTop: 10,
        },
    },
    SectionsContainer: {
        marginTop: 50,
    },
    ChDos: {
        marginLeft: -30,
    },
    ChTres: {
        marginLeft: 120,
    },
    HighlightedItem: {
        paddingRight: 2,
        marginRight: 1,
        backgroundColor: "#FFF",
    },
    TextContent: {
        maxWidth: 900,
        width: "100%",
        fontSize: 18,
        fontFamily: "Work Sans",
        lineHeight: "28px",
    },
    TextContentContainer: {
        display: "flex",
        justifyContent: "center",
    },
    CenteredContent: {
        display: "flex",
        justifyContent: "center",
    },
    BlueText: {
        fontFamily: "Alegreya",
        lineHeight: "34px",
        fontSize: 27,
        fontWeight: 400,
        color: "#0f6cfc",
        marginTop: 50,
        marginBottom: 50,
    },
    MarginTopContent: {
        marginTop: 40,
        marginBottom: 40,
    },
    PartituraText: {
        color: "#0f6cfc",
        fontFamily: "Work Sans",
        //fontWeight: 600,
        fontSize: 21,
    },
    LineaPartitura: {
        marginTop: 40,
        marginBottom: 20,
        fontFamily: "Alegreya",
        color: "#000",
        fontWeight: 500,
        fontSize: 27,
        "@media(max-width: 795px)": {
            lineHeight: "35px",
        },
    },
    NotaFlotante: {
        //position: "relative",
        top: -25,
        color: "#0f6cfc",
        fontFamily: "Work Sans",
        fontWeight: 600,
        fontSize: 20,
        /*LC */
        left: 0,
        position: "absolute",
        width: "100%",
        textAlign: "center",
    },
    SilabaSubrayada: {
        textDecoration: "underline",
    },
    ImgAllWidth: {
        width: 700,
        marginBottom: 72,
        marginTop: 50,
    },
    ImgDefault: {
        width: "30%",
        height: "30%",
    },
    ImgTabla: {
        height: 150,
        width: 360,
    },
    ImgDefaultF: {
        width: "100%",
        height: "100%",
    },
    BoldTitle: {
        fontFamily: "Alegreya",
        fontSize: 25,
        fontWeight: "bold",
        marginTop: 60,
        marginBottom: 20,
    },
    TextoTabla: {
        backgroundColor: "white",
        width: 420,
        padding: "10px",
    },
    HeaderTabla: {
        padding: "10px",
        fontWeight: 600,
    },
    BoldTitleNoMarginTop: {
        fontFamily: "Alegreya",
        fontSize: 25,
        fontWeight: "bold",
        marginBottom: 20,
    },
    SemiBoldText: {
        fontWeight: 600,
    },
    TwoColumnsContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        "@media(max-width: 939px)": {
            flexDirection: "column",
            alignItems: "center",
        },
    },
    TwoColumnsColumnContainer: {
        paddingBottom: 40,
    },
    ImageColumnObra: {
        marginTop: 10,
        maxWidth: 420,
    },
    PictureBoxContainerText: {
        display: "flex",
        justifyContent: "space-evenly",
    },
    PictureBoxContainerText2: {
        minHeight: 110,
    },
    Desktop: {
        display: "block",
        "@media(max-width: 795px)": {
            display: "none",
        },
    },
    Responsive: {
        display: "none",
        "@media(max-width: 795px)": {
            display: "block",
        },
    },
    marginAuto: {
        display: "block",
        margin: "40px auto",
    },
    DobleBox: {
        display: "flex",
    },
    BannerTextJulio: {
        paddingLeft: 50,
        fontSize: "1rem",
        //textAlign: "center",
        fontFamily: "Work Sans",
        lineHeight: "24px",
    },
    width100: {
        width: "100%",
    },
    MarginTopButtom: {
        margin: "44px 0",
    },
    rela: {
        position: "relative",
    },
    nowrap: {
        whiteSpace: "nowrap",
        "@media(max-width: 400px)": {
            lineHeight: "70px",
        },
    },
    rotate180: {
        transform: "rotate(180deg)",
    },
    mixer: {
        textDecoration: "underline",
        color: "#0f6cfc",
        fontWeight: 600,
    },
    textCenter: {
        display: "flex",
        justifyContent: "center",
    },
    CellTable: {
        border: "white solid 4px",
    },
    DivTable: {
        "@media(max-width: 722px)": {
            overflowX: "scroll",
            overflowY: "hidden",
        },
    },
});

const mapWidthNotes = {
    F: 9.53,
    Bb: 28.08,
    C7: 27.41,
    G: 12.53,
    Dm: 33.86,
    A7: 25.05,
};

const Note = ({ text, note }: { text: string; note: string }) => {
    /*const marginRight = mapWidthNotes[note]
    ? -mapWidthNotes[note]
    : -1 * note.length * 16;
  const stylesNote = StyleSheet.create({
    Note: {
       left: -1 * (text.length * 12 - (text.length * 12 + marginRight) / 2),
      marginRight: marginRight, 
    },
  });*/
    if (text == "") {
        return (
            <div style={{ display: "inline-block", position: "relative" }}>
                <span className={css(styles.SilabaSubrayada)}>{text}</span>
                <span className={css(styles.NotaFlotante, styles.rela)}>
                    {note}
                </span>
            </div>
        );
    } else {
        return (
            <div style={{ display: "inline-block", position: "relative" }}>
                <span className={css(styles.SilabaSubrayada)}>{text}</span>
                <span className={css(styles.NotaFlotante)}>{note}</span>
            </div>
        );
    }
};

const SeventeenAugustFestScreen = () => {
    const [sectionSelected, setSectionSelected] = useState(null);
    const [windowSize, setWindowSize] = useState({
        windowWidth: window.innerWidth,
    });
    const [columnSelected, setColumnSelected] = useState(0);

    const managedState = windowSize?.windowWidth <= 795;

    const handleResize = (e) => {
        setWindowSize({ windowWidth: window.innerWidth });
    };

    const windowOpen = () => {
        window.open("files/PartituraLa-primavera-Vivaldi-y-Chamame.pdf");
    };
    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleToggle = (section) => {
        console.log(section);
        if (sectionSelected === section) {
            setSectionSelected(null);
            return;
        }
        setSectionSelected(section);
    };
    return (
        <div className={css(styles.Content)}>
            <BannerPaper>
                {/* <span className={css(styles.FloatingBannerSpan)}>
          Nuestra patr
          ia antes y ahora
        </span> */}

                <Container>
                    <div className={css(styles.BannerTextJulio)}>
                        <span className={css(styles.FloatingBannerSpan)}>
                            Europa invade América
                        </span>
                    </div>
                    <span className={css(styles.ColumnContainer)}>
                        <div
                            className={css(
                                styles.Column,
                                styles.TextBoxHead,
                                //styles.ColumnText,
                                styles.MarginText
                            )}
                        >
                            <Img
                                className={css(
                                    styles.BannerImgCharacter,
                                    styles.Desktop
                                )}
                                src="/images/12oc-3.png"
                            />

                            <Img
                                className={css(
                                    styles.BannerImg,
                                    styles.Desktop
                                )}
                                src="/images/banda_arriba-02.png"
                            />
                            {!managedState || columnSelected === 0 ? (
                                <span>
                                    {" "}
                                    “Colón iba en la calavera más grande.”
                                </span>
                            ) : undefined}

                            {managedState && columnSelected === 0 && (
                                <Img
                                    onClick={() => setColumnSelected(1)}
                                    className={css(
                                        styles.BannerImg,
                                        styles.Responsive
                                    )}
                                    src="/images/12oc-3.png"
                                />
                            )}
                        </div>

                        <div
                            className={css(
                                styles.Column,
                                styles.TextBoxHead,
                                //styles.ColumnText,
                                styles.MarginText
                            )}
                        >
                            <Img
                                className={css(
                                    styles.BannerImgCharacter,
                                    styles.Desktop,
                                    styles.ChDos
                                )}
                                src="/images/12oc-1.png"
                            />

                            <Img
                                className={css(
                                    styles.BannerImg,
                                    styles.Desktop,
                                    styles.MarginLinea
                                )}
                                src="/images/banda_arriba-03.png"
                            />
                            {!managedState || columnSelected === 0 ? (
                                <span>
                                    {" "}
                                    “Mi papá dice que si los indios quisieran
                                    venir de nuevo a vivir aquí no podrían sacar
                                    a nadie y todos les dirían: ¿y a ustedes
                                    quién los conoce?”
                                </span>
                            ) : undefined}

                            {managedState && columnSelected === 0 && (
                                <Img
                                    onClick={() => setColumnSelected(1)}
                                    className={css(
                                        styles.BannerImg,
                                        styles.Responsive
                                    )}
                                    src="/images/12oc-1.png"
                                />
                            )}
                        </div>
                        <div
                            className={css(
                                styles.Column,
                                styles.TextBoxHead,
                                //styles.ColumnText,
                                styles.ChTresMarginText
                            )}
                        >
                            {!managedState || columnSelected === 0 ? (
                                <span className={css()}>
                                    {" "}
                                    “Hablaban así: AS BIGORTI ETETSJI J 95,
                                    porque no podían hablar como nosotros.”
                                </span>
                            ) : undefined}

                            <Img
                                className={css(
                                    styles.BannerImg,
                                    styles.Desktop
                                )}
                                src="/images/banda_arriba-04.png"
                            />

                            <Img
                                className={css(
                                    styles.BannerImgCharacter,
                                    styles.Desktop,
                                    styles.ChTres
                                )}
                                src="/images/12oc-2.png"
                            />

                            {managedState && columnSelected === 0 && (
                                <Img
                                    onClick={() => setColumnSelected(1)}
                                    className={css(
                                        styles.BannerImg,
                                        styles.Responsive
                                    )}
                                    src="/images/12oc-2.png"
                                />
                            )}
                        </div>
                        <div
                            className={css(
                                styles.Column,
                                styles.TextBoxHead,
                                //styles.ColumnText,
                                //styles.MarginText
                                styles.ChCuatroMarginText
                            )}
                        >
                            <Img
                                className={css(
                                    styles.BannerImgCharacter,
                                    styles.Desktop
                                )}
                                src="/images/12oc-4.png"
                            />

                            <Img
                                className={css(
                                    styles.BannerImg,
                                    styles.Desktop,
                                    styles.MarginLinea
                                )}
                                src="/images/banda_arriba-05.png"
                            />
                            {!managedState || columnSelected === 0 ? (
                                <span>
                                    {" "}
                                    “Una vez que fue a la casa del rey y de la
                                    reina tocó timbre, salió la reina y lo hizo
                                    pasar. Colón aprovechó enseguida y le pidió
                                    plata.”
                                </span>
                            ) : undefined}

                            {managedState && columnSelected === 0 && (
                                <Img
                                    onClick={() => setColumnSelected(1)}
                                    className={css(
                                        styles.BannerImg,
                                        styles.Responsive
                                    )}
                                    src="/images/12oc-4.png"
                                />
                            )}
                        </div>
                    </span>

                    <div className={css(styles.ColumnContainer)}></div>
                </Container>
            </BannerPaper>
            <Container>
                <div className={css(styles.TitlesContainer)}>
                    <div className={css(styles.Title)}>12 de octubre</div>
                    <div className={css(styles.Title)}>Cuento El cautivo</div>
                    <div className={css(styles.SubTitle)}>
                        –Versión infantil de un cuento de Jorge L. Borges–
                    </div>
                    <div className={css(styles.SubTitle)}>
                        Paisajes sonoros y sikus de papel
                    </div>
                    <div className={css(styles.PlayerContainer)}>
                        <BandcampPlayer
                            trackSrc="track=2909454591"
                            size="small"
                            height={"42px"}
                            width={"100%"}
                        />
                    </div>
                    <div className={css(styles.PlayerContainer)}>
                        <BandcampPlayer
                            trackSrc="track=4132259080"
                            size="small"
                            height={"42px"}
                            width={"100%"}
                        />
                    </div>

                    <div className={css(styles.ButtonsContainer)}>
                        <BorderedButton
                            text={"DESCARGAR CUENTO NARRADO POR UN NIÑO"}
                            onClick={() => {
                                window.open(
                                    "files/Jaupen_cuento_narrado_por_niño.mp3"
                                );
                            }}
                            styles={styles.Button}
                        />

                        <BorderedButton
                            text={"DESCARGAR CUENTO SONORIZADO"}
                            onClick={() => {
                                window.open(
                                    "files/Jaupen_cuento_sonorizado.mp3"
                                );
                            }}
                            styles={styles.Button}
                        />
                    </div>
                </div>
            </Container>
            <div className={css(styles.SectionsContainer)}>
                <CollapsableSection
                    fixed={managedState}
                    // opened={sectionSelected === "a"}
                    /*toggle={() => {
            handleToggle("a");
          }}*/
                    letter="A."
                    title="Me toca el acto en una semana"
                    pictureUrl="/images/letter_banner/a-me-toca-el-acto.png"
                    pictureHoverUrl="/images/letter_banner/a-me-toca-el-acto-act.png"
                >
                    <Container>
                        <div className={css(styles.TextContentContainer)}>
                            <div className={css(styles.TextContent)}>
                                <div
                                    className={css(styles.BoldTitleNoMarginTop)}
                                >
                                    Prepararando una actuación con poco tiempo
                                </div>

                                <p>
                                    Recomendamos escuchar varias veces el cuento
                                    con los chicos, comentando y jugando
                                    libremente.
                                    <br />
                                    <br />
                                    El día del acto se escuchará la grabación
                                    por los parlantes, y la maestra o el maestro
                                    irán acompañando a los chicos en sus
                                    movimientos, ordenando las escenas que
                                    pueden resultar más o menos así:
                                    <br />
                                    <br />
                                    <ImageBox
                                        textContainerStyles={
                                            styles.PictureBoxContainerText
                                        }
                                        styles={styles.ImgDefault}
                                        pictureUrl="/images/12oc-5.png"
                                    />
                                    <br />
                                    Traer a un chico (Jaupén) para que se siente
                                    en una silla.
                                    <br />
                                    <br />
                                    <ImageBox
                                        textContainerStyles={
                                            styles.PictureBoxContainerText
                                        }
                                        styles={styles.ImgDefaultF}
                                        pictureUrl="/images/12oc-6.png"
                                    />
                                    <br />
                                    Cuando se escucha el sonido del malón,
                                    decirles: ¡vamos a caballo!
                                    <br />
                                    Después imaginan que saltan el río saltando
                                    las sogas.
                                    <br />
                                    <br />
                                    <ImageBox
                                        textContainerStyles={
                                            styles.PictureBoxContainerText
                                        }
                                        styles={styles.ImgDefaultF}
                                        pictureUrl="/images/12oc-7.png"
                                    />
                                    <br />
                                    Mientras escuchan el llamado gritan
                                    “Jaupéeeeeen” mientras se sientan en rueda y
                                    palmean ritmos libremente siguiendo el juego
                                    de ecos de la grabación.
                                    <br />
                                    <br />
                                    <ImageBox
                                        textContainerStyles={
                                            styles.PictureBoxContainerText
                                        }
                                        styles={styles.ImgDefaultF}
                                        pictureUrl="/images/12oc-8.png"
                                    />
                                    <br />
                                    Cuando se escuchan los sikus como fondo del
                                    relato, poner dos sillas más y decirles a
                                    dos chicos altos (papá y mamá) que se
                                    sienten. Después decirle a Jaupén que vaya a
                                    la otra silla, con sus padres.
                                    <br />
                                    <br />
                                    <ImageBox
                                        textContainerStyles={
                                            styles.PictureBoxContainerText
                                        }
                                        styles={styles.ImgDefaultF}
                                        pictureUrl="/images/12oc-9.png"
                                    />
                                    <br />
                                    Decirles a la mamá y al papá que le den un
                                    beso de despedida a Jaupén. Tomarlo de la
                                    mano llevando en fila a todos los chicos que
                                    se alejan gritando con alegría: ¡iuju!
                                    ¡vamos! ¡uaké, uaké, uaké! Mientras se va
                                    escuchando el final del relato.
                                    <br />
                                    <br />
                                    <br />
                                    <span className={css(styles.BoldTitle)}>
                                        Otro acto que no requiere preparación
                                        previa, es una reunión sin escenario
                                    </span>
                                    <br />
                                    <br />
                                    Después de cantar el himno y escuchar el
                                    discurso con toda la escuela, cada grado se
                                    irá a su sala con su maestra o maestro y los
                                    familiares que hayan ido.
                                    <br />
                                    Realizaremos un debate al estilo del que ya
                                    les contamos en la fiesta del 17 de agosto.
                                    <br />
                                    <br />
                                    <span className={css(styles.SemiBoldText)}>
                                        a. Mientras ingresamos al aula pondremos
                                        la grabación del cuento.
                                    </span>
                                    <br />
                                    Se encontrarán con cinco mesas en fila, una
                                    detrás de la otra, y con algunas sillas
                                    alrededor. Allí se acomodarán como puedan
                                    los adultos mezclados con chicos y chicas.
                                    <br />
                                    <br />
                                    <span className={css(styles.SemiBoldText)}>
                                        b. Empieza el juego.
                                    </span>{" "}
                                    La maestra hace la primera pregunta. La
                                    gente de la comunidad qom vive en medio del
                                    monte pero en casas como las nuestras ¿son
                                    indígenas o no?
                                    <br />
                                    Tienen que ponerse de acuerdo en cada mesa,
                                    en cada grupo, para contestar SI, parándose
                                    en la pared derecha. NO en la izquierda.
                                    DUDAS u opiniones contrapuestas, quedándose
                                    sentados.
                                    <br />
                                    ¡Tiempo! Exclamamos después tocando una
                                    pandereta. Cuando decimos ¡ya! se ubican
                                    donde decidieron.
                                    <br />
                                    Observamos y anotamos cuántas dudas, cuántos
                                    si y cuántos no.
                                    <br />
                                    Y así haremos la segunda pregunta
                                    <br />
                                    ¿Está bien decir “Europa invadió a América?
                                    <br />
                                    Y la tercera pregunta
                                    <br />
                                    ¿Hizo bien Jaupén en volverse con los
                                    indígenas?
                                    <br />
                                    <br />
                                    <span className={css(styles.SemiBoldText)}>
                                        c. Para finalizar leemos los resultados
                                        que anotamos.
                                    </span>
                                    <br />
                                    Y para agradecer la presencia de todos en
                                    esta experiencia de escucharnos, nos
                                    despedimos brindando una canción que tocamos
                                    con nuestro instrumento o cantamos un tema
                                    con los chicos.
                                    <br />
                                    <br />
                                </p>
                            </div>
                        </div>
                    </Container>
                </CollapsableSection>

                <CollapsableSection
                    fixed={managedState}
                    //opened={sectionSelected === "b"}
                    /*toggle={() => {
            handleToggle("b");
          }}*/
                    letter="B."
                    title="Voy a probar sobre la grabación"
                    pictureUrl="/images/letter_banner/b-voy-a-probar.png"
                    pictureHoverUrl="/images/letter_banner/b-voy-a-probar-act.png"
                >
                    <Container>
                        <div className={css(styles.TextContentContainer)}>
                            <div className={css(styles.TextContent)}>
                                <div
                                    className={css(styles.BoldTitleNoMarginTop)}
                                >
                                    Tocando sobre la grabación, en karaoke
                                </div>
                                <br />
                                La experiencia de tocar sobre una base en
                                karaoke a veces facilita y a veces complica las
                                realizaciones. En este caso recomendamos hacer
                                la versión del cuento en vivo y poner la
                                grabación con el acompañamiento de la canción
                                inventada por niños solamente al final del
                                relato.
                                <div
                                    className={css(
                                        styles.CenteredContent,
                                        styles.MarginTopContent
                                    )}
                                >
                                    <BorderedButton
                                        text={"DESCARGAR KARAOKE"}
                                        onClick={() => {
                                            window.open(
                                                "files/Jaupen_cancion_karaoke.mp3"
                                            );
                                        }}
                                        styles={styles.Button}
                                    />
                                </div>
                                <span className={css(styles.HighlightedItem)}>
                                    1.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    Materiales que conseguiremos para conocer
                                    sonoridades
                                </span>
                                <br />
                                <br />
                                <span className={css(styles.SemiBoldText)}>
                                    Grupo 1:{" "}
                                </span>
                                baldes con un poco de agua con jarritos o
                                vasitos para volcar y pajitas para hacer
                                burbujas.
                                <br />
                                <br />
                                <span className={css(styles.SemiBoldText)}>
                                    Grupo 2:{" "}
                                </span>
                                tarritos de yoghurt para golpear en el piso y
                                cajas de cartón, latas o tambores para golpear
                                con la mano o con palos.
                                <br />
                                <br />
                                <span className={css(styles.SemiBoldText)}>
                                    Grupo 3:{" "}
                                </span>
                                espejitos frotados con un trozo de telgopor,
                                silbatos de cotillón, y picos de flautas para
                                hacer pájaros, marcadores pegados sobre un
                                cartón, como raspador, perchas con lapicitos,
                                llaves y chapitas colgando. Bolsitas de nylon.
                                <br />
                                <br />
                                <span className={css(styles.SemiBoldText)}>
                                    Grupo 4:{" "}
                                </span>
                                sikus y trozos de caño plástico, y botellitas de
                                plástico de extracto de vainilla para soplar.
                                <br />
                                <br />
                                <span className={css(styles.HighlightedItem)}>
                                    2.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    Exploración musical previa
                                </span>
                                <br />
                                Probaremos un tipo de clase que realizan en
                                muchas escuelas, con menor intromisión del
                                adulto.
                                <br />
                                Somos países aprendiendo a resolver sin los
                                ordenamientos externos de grupos financieros.
                                Estamos inventando cómo vivir con la cultura que
                                trajeron los huincas (europeos) y la de los
                                pueblos que existían en el Abya Yala, y
                                realizaremos una pedagogía con estos sentidos.
                                <br />
                                Ubicaremos a todo el grado distribuido en cuatro
                                mesas con los diferentes materiales, con una
                                coordinadora o coordinador en cada grupo.
                                <br />
                                Sin ninguna explicación nos vamos a tomar un
                                tecito o mate afuera. Ellos sabrán que estamos
                                cerca, cuidando, sin interferir, mientras
                                prueban hacer sonar lo que tienen a disposición.
                                <br />
                                Cuando regresemos, comprobaremos que algunos
                                descubrieron sonidos insospechados, otros
                                estarán jugando a otra cosa con los vasitos
                                mientras algunos se estarán riendo por el
                                enchastre del agua. Trataremos de no retar a
                                nadie y nos asombraremos de todo lo que ocurrió
                                <br />
                                <br />
                                <span className={css(styles.HighlightedItem)}>
                                    3.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    El acto en el escenario
                                </span>
                                <br />
                                Empezaremos a relatar el cuento con un micrófono
                                y lo iremos acercando a un grupo de sonidos o a
                                otro para acompañar las distintas partes.
                                <br />
                                <br />
                                <span className={css(styles.BlueText)}>
                                    “La historia que cuentan es que aquí vivían
                                    pueblos ranqueles.
                                    <br />
                                    Cuando los criollos los echaron entraron al
                                    pueblo a buscar comida y un chico de ojos
                                    azules se fue con ellos en medio del malón.
                                    <br />
                                    Bajo la lluvia atravesaron el monte de noche
                                    y a la mañana cruzaron el río. Al chico lo
                                    llamaron Jaupén.
                                    <br />
                                    Habló y cantó como los ranqueles. Mirando
                                    las estrellas para aprender cosas, sabiendo
                                    curar con plantas.
                                    <br />
                                    Pasaron años.
                                    <br />
                                    La familia escuchó hablar de un muchacho de
                                    ojos azules que vivía en las carpas y lo
                                    trajeron al pueblo.
                                    <br />
                                    El muchacho llegó. Tenía 20 años. No
                                    reconoció a sus padres y ellos tampoco. De
                                    pronto corrió a la cocina, metió la mano en
                                    un agujero y sacó un cuchillito con el que
                                    jugaba cuando era chico. Así que era su
                                    casa.
                                    <br />
                                    Pero no se acostumbró. Y se fue. ¡Qué
                                    lástima para los padres!
                                    <br />
                                    Él eligió lo que lo hacía feliz: vivir con
                                    sus hermanos, pescar, galopar en el caballo,
                                    andar sin miedo a la noche, sentirse libre y
                                    cantar.”
                                </span>
                                <br />
                                <br />
                                Después de hacer los llamados “Jaupeeeeen”, les
                                diremos a los chicos que vengan adelante al
                                centro del escenario y les haremos repetir
                                esquemas rítmicos en forma de eco. Por ejemplo:
                                <br />
                                <span className={css(styles.SemiBoldText)}>
                                    pam pam taca pam (hará la maestra con pies y
                                    manos)
                                </span>
                                <br />
                                <span className={css(styles.SemiBoldText)}>
                                    pam pam taca pam (repetirán los chicos)
                                </span>
                                <br />
                                <span className={css(styles.SemiBoldText)}>
                                    tucu tucu traaaa (hará la maestra)
                                </span>
                                <br />
                                <span className={css(styles.SemiBoldText)}>
                                    {" "}
                                    tucu tucu traaaa (repetirán los chicos)
                                </span>
                                <br />
                                etc.
                                <br />
                                <br />
                                En el final del cuento, todos cantaremos la
                                canción, palmeando o pateando un ritmo en el
                                suelo acompañados por la base en karaoke.
                                <br />
                                <br />
                            </div>
                        </div>
                    </Container>
                </CollapsableSection>

                <CollapsableSection
                    fixed={managedState}
                    // opened={sectionSelected === "c"}
                    /* toggle={() => {
            handleToggle("c");
          }}*/
                    letter="C."
                    title="Aprendo la canción y me animo sin pista"
                    pictureUrl="/images/letter_banner/c-aprendo-la-cancion.png"
                    pictureHoverUrl="/images/letter_banner/c-aprendo-la-cancion-act.png"
                >
                    <Container>
                        <div className={css(styles.TextContentContainer)}>
                            <div className={css(styles.TextContent)}>
                                <span
                                    className={css(styles.BoldTitleNoMarginTop)}
                                >
                                    Realización musical en vivo
                                </span>
                                <div
                                    className={css(
                                        styles.CenteredContent,
                                        styles.MarginTopContent
                                    )}
                                >
                                    <BorderedButton
                                        text={"descargar partitura"}
                                        onClick={() => {
                                            window.open(
                                                "files/Jaupén - Partitura completa.pdf"
                                            );
                                        }}
                                        styles={styles.Button}
                                    />
                                </div>
                                <span className={css(styles.HighlightedItem)}>
                                    1.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    Para esta presentación son necesarios muy
                                    pocos ensayos.
                                </span>
                                <br />
                                Se sentarán en el escenario, en semicírculo, los
                                cuatro grupos de chicos (los del agua, los del
                                malón, los de pájaros y monte y los de sikus y
                                botellitas) con todos sus materiales, listos
                                para tocar.
                                <br />
                                La maestra relatará el cuento con un micrófono
                                en la mano, que irá acercando a cada grupo de
                                instrumentos según lo que va indicando la
                                historia: el sonido del agua antes de empezar,
                                las cajas cuando viene el malón, los sonidos de
                                lluvia y de pájaros cuando cruzan el río.
                                <br />
                                Realizarán los llamados “Jaupéeeeeen…..” y los
                                ecos rítmicos.
                                <br />
                                Al finalizar cantaremos la canción
                                acompañándonos con un bombo o alguna guitarra.
                                <br />
                                Esta propuesta es muy natural para chicos de
                                5,6,7,8,9 y 10 años.
                                <br />
                                <br />
                                <span className={css(styles.HighlightedItem)}>
                                    2.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    Con los más grandes.
                                </span>
                                <br />
                                Se puede realizar con un carácter distinto
                                pidiendo que la historia la lea uno de ellos y
                                que en los ecos rítmicos copien a algún chico en
                                vez de a la maestra.
                                <br />
                                Todo este arreglo musical está planteado para
                                acceder bella y fácilmente a la ejecución de
                                instrumentos: punteando la melodía en la
                                guitarra, acompañando todo con el acorde de mi
                                menor, o con los tres acordes indicados en la
                                partitura, tocando la melodía en un siku o
                                flauta y acompañando con percusión de vasos o
                                bombo.
                                <br />
                                También es posible que consigan el cuento
                                original de Jorge L. Borges y lo lean adaptando
                                los sonidos.
                                <br />
                                <br />
                                <span className={css(styles.HighlightedItem)}>
                                    3.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    Tocando la batería… de cocina.
                                </span>
                                <br />
                                No hay nada tan extraordinario como comprobar
                                que el mundo suena. Que todo lo que nos rodea
                                puede vibrar y convertirse en belleza.
                                <br />
                                Para que los chicos no se sientan infantilizados
                                creyendo que les hacemos hacer ruiditos como en
                                el jardín de infantes, les pasaremos esta
                                grabación al comenzar estas actividades:
                                <br />
                                <Video
                                    src={
                                        "https://www.youtube.com/embed/01Qvn1FIxog"
                                    }
                                />
                                <br />
                                <span className={css(styles.HighlightedItem)}>
                                    4.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    Para lograr hermosos paisajes sonoros{" "}
                                </span>
                                podremos profundizar la exploración sonora
                                jugando a las adivinanzas.
                                <br />
                                <br />

                                <Video
                                    src={
                                        'https://www.youtube.com/embed/NqaQ_N2G7Go?si=SEPNe2seyC8n1HHU'
                                    }
                                />
                                <Video
                                    src={
                                        'https://www.youtube.com/embed/t8xJaOwGkEc?si=sIoM40cvPqCLZA20'
                                    }
                                />
                                <Video
                                    src={
                                        'https://www.youtube.com/embed/s6hcI2m8YgQ?si=aOQNyGDLKf7t77t6'
                                    }
                                />
                                <Video
                                    src={
                                       'https://www.youtube.com/embed/pQvzH6uUpVY?si=iod699ZaGb4yihMS'
                                    }
                                />
                            </div>
                        </div>
                    </Container>
                </CollapsableSection>
                <CollapsableSection
                    fixed={managedState}
                    // opened={sectionSelected === "d"}
                    /* toggle={() => {
            handleToggle("d");
          }}*/
                    letter="D."
                    title="Me encanta hacer algo distinto"
                    pictureUrl="/images/letter_banner/d-me-encanta-hacer.png"
                    pictureHoverUrl="/images/letter_banner/d-me-encanta-hacer-act.png"
                >
                    <Container>
                        <div className={css(styles.TextContentContainer)}>
                            <div className={css(styles.TextContent)}>
                                <div
                                    className={css(styles.BoldTitleNoMarginTop)}
                                >
                                    Otras ideas de aplicación
                                </div>
                                <span className={css(styles.HighlightedItem)}>
                                    1.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    Disfraces
                                </span>
                                <br />
                                Los disfraces plantean situaciones
                                controvertidas. Es la única actividad que logra
                                una participación contundente de todos los
                                padres, quienes se quedan hasta sin dormir para
                                fabricarlos y logran llegar personalmente a la
                                fiesta para ver al nene o sacarle una foto. Por
                                otro lado, estimula situaciones de competencia a
                                veces centradas en lo económico (quién pudo
                                gastar más) y los chicos realizan actuaciones
                                aburridísimas para no romper los trajes.
                                <br />
                                Para evitar esto, damos una propuesta de
                                “cuadros vivientes” que no obliga a realizar mil
                                ensayos con los chicos repitiendo textos como
                                robots.
                                <br />
                                Los disfraces se pueden hacer en clase, o con el
                                maestro de plástica, como también en las casas
                                entregándoles a los padres fotocopias con estas
                                sugerencias:
                                <br />
                                <br />
                                <div
                                    className={css(styles.TwoColumnsContainer)}
                                >
                                    <div
                                        className={css(
                                            styles.TwoColumnsColumnContainer
                                        )}
                                    >
                                        <ImageBoxCenter
                                            textContainerStyles={
                                                styles.PictureBoxContainerText2
                                            }
                                            styles={styles.ImageColumnObra}
                                            stylesImg={styles.ImgTabla}
                                            titulo=" Jaupén"
                                            pictureUrl="/images/12oc-10.png"
                                            text="descalzo o con botas, boina o sombrero, camisa, algún pantalón, un pañuelo en el cuello, tiradores de papel."
                                        />
                                    </div>
                                    <div
                                        className={css(
                                            styles.TwoColumnsColumnContainer
                                        )}
                                    >
                                        <ImageBoxCenter
                                            textContainerStyles={
                                                styles.PictureBoxContainerText2
                                            }
                                            styles={styles.ImageColumnObra}
                                            stylesImg={styles.ImgTabla}
                                            titulo="Los hombres y mujeres indígenas"
                                            pictureUrl="/images/12oc-11.png"
                                            text="vincha de cartulina con guardas dibujadas con motivos indígenas, poncho de verdad o de papel madera con guardas dibujadas, sandalias o pies descalzos, polleras con colores de la wiphala, trenzas hechas con lana."
                                        />
                                    </div>
                                </div>
                                <div
                                    className={css(styles.TwoColumnsContainer)}
                                >
                                    <div
                                        className={css(
                                            styles.TwoColumnsColumnContainer
                                        )}
                                    >
                                        <ImageBoxCenter
                                            textContainerStyles={
                                                styles.PictureBoxContainerText2
                                            }
                                            styles={styles.ImageColumnObra}
                                            stylesImg={styles.ImgTabla}
                                            titulo="La madre"
                                            pictureUrl="/images/12oc-12.png"
                                            text="pañuelo, aros y pintura cosmética pollera larga zapatos altos de una mamá."
                                        />
                                    </div>
                                    <div
                                        className={css(
                                            styles.TwoColumnsColumnContainer
                                        )}
                                    >
                                        <ImageBoxCenter
                                            textContainerStyles={
                                                styles.PictureBoxContainerText2
                                            }
                                            styles={styles.ImageColumnObra}
                                            stylesImg={styles.ImgTabla}
                                            titulo="El padre"
                                            pictureUrl="/images/12oc-13.png"
                                            text="sombrero bigotes y barba con corcho quemado zapatos o botas."
                                        />
                                    </div>
                                </div>
                                <div
                                    className={css(styles.TwoColumnsContainer)}
                                >
                                    <div
                                        className={css(
                                            styles.TwoColumnsColumnContainer
                                        )}
                                    >
                                        <ImageBox
                                            textContainerStyles={
                                                styles.PictureBoxContainerText2
                                            }
                                            //styles={styles.ImgDefaultF}
                                            titulo="El sol, la luna y los árboles"
                                            pictureUrl="/images/12oc-14.png"
                                            text="cartón pintado de amarillo, verde y celeste o telgopor forrado en papel dorado, plateado y 
                                            verde, clavados con cuatro clavos en un palo de escoba.
                                            En algunas zonas es más fácil conseguir unas buenas ramas de árboles verdaderos.
                                            Los chicos que llevan los palos estarán vestidos de un sólo color (negro o azul)"
                                        />
                                    </div>
                                </div>
                                <div
                                    className={css(styles.TwoColumnsContainer)}
                                >
                                    <div
                                        className={css(
                                            styles.TwoColumnsColumnContainer
                                        )}
                                    >
                                        <ImageBox
                                            textContainerStyles={
                                                styles.PictureBoxContainerText2
                                            }
                                            //styles={styles.ImgDefaultF}
                                            titulo="Los caballos"
                                            pictureUrl="/images/12oc-15.png"
                                            text="ropa marrón y una remera vieja tapando la cabeza con dos agujeros para los ojos
                                            o una tela que cubra todo y la cabeza de caballo hecha con telgopor."
                                        />
                                    </div>
                                </div>
                                <div
                                    className={css(styles.TwoColumnsContainer)}
                                >
                                    <div
                                        className={css(
                                            styles.TwoColumnsColumnContainer
                                        )}
                                    >
                                        <ImageBox
                                            textContainerStyles={
                                                styles.PictureBoxContainerText2
                                            }
                                            //styles={styles.ImgDefaultF}
                                            titulo="El río"
                                            pictureUrl="/images/12oc-16.png"
                                            text="tiras de tela o papel crepe, celestes, blancas, azules, verdes, para que los chicos salten."
                                        />
                                    </div>
                                </div>
                                Los chicos de un grado podrán realizar la parte
                                musical en los costados del escenario y los de
                                otro grado, la parte plástica, en el centro del
                                escenario.
                                <br />
                                . Al comienzo pasarán caminando “el papá y la
                                mamá” llevando a “Jaupén”.
                                <br />
                                . Aparece “la luna” (si hay luces se pondrá
                                oscurito) y pasarán “los caballos” con “los
                                indios” que se llevarán al chico y saltarán “el
                                río”.
                                <br />
                                . Se ubicarán “los árboles”, “el sol” y
                                aparecerán “las mujeres y hombres indígenas” que
                                vestirán al chico de Jaupén con vincha y
                                ponchito. Juntos saltarán, correrán y darán
                                vueltas carnero entre los árboles.
                                <br />
                                . Se irán todos por el fondo y aparecerán otra
                                vez “el papá y la mamá” por un lado, por el otro
                                “Jaupén” que ahora será otro chico, más alto. Se
                                encontrarán y se mirarán, quietos.
                                <br />
                                . Volverán todos: “los árboles”, “el río”, “el
                                sol”, “la luna”, “los caballos” y “las mujeres y
                                hombres indígenas” con Jaupén que correrán y
                                saltarán. El “papá” y la “mamá” los espiarán
                                desde atrás de un árbol.
                                <br />
                                . Al finalizar todos saludarán en fila,
                                inclinándose hacia adelante.
                                <br />
                                <br />
                                <span className={css(styles.HighlightedItem)}>
                                    2.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    Otros cuentos
                                </span>
                                <br />
                                Este modelo de actividad basada en un relato,
                                sonorización con instrumentos y objetos e
                                inventando además disfraces, se podrá aplicar a
                                otras historias.
                                <br />
                                <br />
                                Recomendamos especialmente:
                                <br />
                                El diablo inglés de María Elena Walsh
                                (hermosísimo relato de las invasiones inglesas,
                                que puede prepararse para cualquier acto como
                                ejemplo de cómo un muchacho defiende lo que
                                quiere).
                                <br />
                                El garbanzo peligroso de Laura Devetach (un
                                cuento divertidísimo como ejemplo de que las
                                ideas de San Martín y Belgrano y otros,
                                aparecieron chiquitas y temidas como un
                                “garbanzo peligroso” y sin embargo ayudaron a
                                hacer crecer muchas ideas necesarias para
                                todos).
                                <br />
                                El cardón, leyenda de la cultura diaguita (allí
                                están los cardones, cerca de Amaicha del Valle.
                                En este relato la Pachamama asustó con estas
                                plantas a los conquistadores de tierra para que
                                no perturben la felicidad de esas comunidades).
                                <br />
                                <br />
                                <span className={css(styles.HighlightedItem)}>
                                    3.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    Construcción de sikus de papel
                                </span>
                                <br />
                                a. En la reuniones de padres y madres valoramos
                                el contacto con la familia de los chicos, pero
                                las reuniones no siempre ayudan para lograrlo.
                                Porque la mitad no viene, porque sólo habla la
                                maestra y tres o cuatro charlatanes y porque
                                algunos tímidos no abren la boca.
                                <br />
                                Invitaremos a la reunión habitual con una
                                motivación que sale un poco de la rutina: hacer
                                un instrumento para cada chico.
                                <br />
                                Las actividades manuales crean un espacio muy
                                natural de charla y colaboración en el que
                                aparecen distintas habilidades que se mezclan
                                con anécdotas y chistes. Y con este objetivo de
                                integración, los docentes adquirimos un hermoso
                                material didáctico sin quedarnos horas sin
                                dormir para confeccionarlo.
                                <br />
                                <br />
                                Aquí va la receta para sikus pentatónicos con
                                tubos angostos.
                                <br />
                                . Cortar cinco trozos de cartulina de 25 cm de
                                largo por 10 cm de ancho. Cubrir bien el trozo
                                con cola vinílica y hacer un rollo apretado
                                envolviendo dos lápices con las puntas hacia
                                afuera. Hacerlo con todos los dedos,
                                enchastrándose bastante. Sacar los lápices del
                                tubito antes de que se endurezca.
                                <br />
                                . Mientras se seca preparamos unos mates, viene
                                la directora y nos informa de alguna cuestión.
                                <br />
                                . Cortar con un cuchillo afilado o un cutter un
                                centímetro de una punta de los tubitos. Del otro
                                lado se corta cada uno con distintas medidas
                                para que suenen diferentes:
                                <br />
                                Mi: 12,2 cm
                                <br />
                                Sol: 10,1 cm
                                <br />
                                La: 9 cm
                                <br />
                                Si: 7,9 cm
                                <br />
                                Re:6,5 cm
                                <br />
                                Mi: 5,7 cm
                                <br />
                                Sol: 4,6 cm
                                <br />
                                <br />
                                . Pegarle a cada tubito en una punta un
                                cuadradito de cartulina doble (pegada),
                                dejándolo paradito hasta que se seque un rato al
                                sol o cerca de una estufa.
                                <br />
                                . Mientras, nos comemos unos bizcochitos y
                                hacemos firmar algo, o entregamos boletines y
                                contamos cómo van las cosas en el grado.
                                <br />
                                . Cortar con una tijera en cortes rectos lo que
                                sobra del cuadrado de cartulina doble. . Pegar
                                los tubitos uno al lado del otro.
                                <br />
                                . Se le puede agregar una faja de cartulina
                                pegada alrededor para sostenerlos bien y
                                escribirles el nombre de las notas. Una segunda
                                faja permite dibujar una decoración, o el nombre
                                del chico, o atar con lanas.
                                <br />
                                . El toque final es pasarle a las dos puntas de
                                cada tubito una buena capa de esmalte de uñas
                                para que no se desarmen con la humedad de la
                                boca. Y para que quede bien pegada la tapita de
                                cartulina doble.
                                <br />
                                <br />
                                <ImageBox
                                    textContainerStyles={
                                        styles.PictureBoxContainerText2
                                    }
                                    //styles={styles.ImgDefaultF}
                                    pictureUrl="/images/12oc-17.png"
                                />
                                <br />
                                Aquí les mostraremos un siku de siete tubitos
                                amarrados con una maderita y lana:
                                <br />
                                <br />
                                <Video
                                    src={
                                        "https://www.youtube.com/embed/6Ut6ncN_uMw"
                                    }
                                />
                                b. Se viene la sikuriada en la fiesta:
                                <br />
                                Caminando en fila por toda la escuela, como las
                                sikuriadas que bajan de la montaña para
                                festejar, iremos acompañando al docente que
                                cante con una guitarra o bombo la canción de
                                Jaupén, o cualquier tema en mi menor. Algunos
                                chicos tocarán notas libremente, otros harán
                                ruiditos de viento que les salgan y otros habrán
                                aprendido la melodía.
                                <br />
                                <br />
                            </div>
                        </div>
                    </Container>
                </CollapsableSection>
                <CollapsableSection
                    fixed={managedState}
                    // opened={sectionSelected === "d"}
                    /* toggle={() => {
            handleToggle("d");
          }}*/
                    letter="E."
                    title="Fiesta por pantalla"
                    pictureUrl="/images/letter_banner/e-acto-por-pantalla.png"
                    pictureHoverUrl="/images/letter_banner/e-acto-por-pantalla-act.png"
                >
                    <Container>
                        <div className={css(styles.TextContentContainer)}>
                            <div className={css(styles.TextContent)}>
                                <span className={css(styles.HighlightedItem)}>
                                    1.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    Enviar un cuento con comentarios
                                </span>
                                <br />
                                <br />
                                Un festejo sencillo y espontáneo es enviar a las
                                familias un video con el cuento grabado, sobre
                                un collage visual de dibujos y frases de las
                                chicas y chicos.
                                <br />
                                En una clase previa por pantalla, les haremos
                                escuchar el cuento, y a continuación los que
                                quieran dibujarán, otros escribirán alguna frase
                                de lo que más les atrajo, y plantarán su nombre
                                al pie del papel. Con el celular sacarán una
                                foto y enviarán a los docentes este material
                                para armar una edición sencilla.
                                <br />
                                Quienes no querramos editar, realizaremos una
                                videollamada en la que haremos escuchar a las
                                familias el cuento que hemos descargado,
                                mientras vamos mostrando en pantalla con
                                nuestras manos, los distintos dibujos.
                                <br />
                                <br />
                                <span className={css(styles.HighlightedItem)}>
                                    2.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    Sonorización de un cuento con objetos
                                    caseros
                                </span>
                                <br />
                                <br />
                                Para realizar una sonorización seguiremos los
                                siguientes pasos diciendo:
                                <br />
                                • Escuchemos este cuento
                                <br />
                                • Escuchen cómo grabó Pablo los sonidos:
                                <br />
                                <br />
                                <Video
                                    src={
                                        "https://www.youtube.com/embed/Nif_DYrN_vA"
                                    }
                                />
                                <Video
                                    src={
                                        "https://www.youtube.com/embed/GgAjqyB_JnI"
                                    }
                                />
                                <Video
                                    src={
                                        "https://www.youtube.com/embed/y2emx0FM0Nw"
                                    }
                                />
                                <br />
                                • Chicos, chicas, les pediremos que busquen en
                                su casa alguno de estos materiales para la
                                próxima clase.
                                <br />
                                <br />
                                • Hoy registraremos nuestra sonorización
                                grabando la videollamada. Los que no buscaron
                                ningún objeto van a ayudar en la parte de los
                                ecos rítmicos con palmas.
                                <br />
                                Empiezo a contar el cuento y estén listos para
                                levantar la mano y realizar el sonido que les
                                guste para cada parte.
                                <br />
                                <br />
                                <span className={css(styles.HighlightedItem)}>
                                    3.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    Sonorización tecno
                                </span>
                                <br />
                                <br />
                                En la escuela nos encontramos con algunas
                                personitas exploradoras electrónicas, que
                                inventan temas y bandas sonoras en sus
                                computadoras. Les daremos cabida pidiéndoles que
                                compongan la música que quieran para nuestro
                                relato.
                                <br />
                                Escuchen lo que armó Manuel Spiller con el texto
                                que le envié:
                                <br />
                                <br />
                                <BandcampPlayer
                                    trackSrc="track=676498613"
                                    size="small"
                                    height={"42px"}
                                    width={"100%"}
                                    bgcol="fff2a6"
                                />
                                <div className={css(styles.ButtonsContainer)}>
                                    <BorderedButton
                                        text={"DESCARGAR CUENTO"}
                                        onClick={() => {
                                            window.open(
                                                "files/Fiesta_en_la-Escuela_Cuento_sonorizacion_tecno.mp3"
                                            );
                                        }}
                                        styles={styles.Button}
                                    />
                                </div>
                                <br />
                                <br />
                                No necesitamos meter a todo el grupo a hacer
                                cosas tan difíciles. Así como tenemos orgullo de
                                tener un goleador en el equipo de fútbol, un
                                guitarrista, una genia de las matemáticas, y
                                otra del baile en patines, daremos lugar a las
                                distintas pasiones tempranas para que los demás
                                se conmuevan y vayan despertando las suyas. Esto
                                es inclusión con las diferencias.
                                <br />
                                <br />
                                <span className={css(styles.HighlightedItem)}>
                                    4.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    Filmación de un relato con juguetes y
                                    objetos caseros
                                </span>
                                <br />
                                <br />
                                Los chicos pueden filmar historias sobre
                                cualquier tema con un celular, utilizando sus
                                juguetes.
                                <br />
                                <br />
                                <Video
                                    src={
                                        "https://www.youtube.com/embed/jaTzCMHcBLw"
                                    }
                                />
                                <br />
                                Podemos encargar esta tarea, en base a algún
                                relato libre sobre la fecha de la fiesta, a
                                alguna leyenda indígena, o relatos familiares
                                sobre el desarraigo y la colonización.
                                <br />
                                Algunos se podrán reunir y armarlo en equipo.
                                Otros los harán en su casa, jugando entre
                                hermanos o primos, como de hecho fue la
                                grabación que enviamos como ejemplo.
                                <br />
                                <br />
                                <span className={css(styles.HighlightedItem)}>
                                    5.
                                </span>
                                <span className={css(styles.SemiBoldText)}>
                                    Entrevistas para conocer mundos otros
                                </span>
                                <br />
                                <br />
                                Un maestro de grado, Darío, invitó para el 12 de
                                octubre a personas para entrevistar en dos
                                encuentros por internet. Escuchen un recorte de
                                estas charlas e imaginen el asombro de los
                                chicos al ver que Poli, alguien que canta
                                coplas, puede llegar a ser ¡la directora! del
                                equipo de inspectoras de escuelas de Tucumán.
                                Que Maruca, alguien que recorrió Europa cantando
                                con la guitarra, elige vivir en comunidad y
                                aprendiendo una lengua de las culturas
                                preexistentes.
                                <br />
                                <br />
                                • Poli Bordón, educadora musical nacida en la
                                comunidad de los quilmes, Tucumán.
                                <br />
                                • Maruca Hernández, que canta en tzotzil y vive
                                en las montañas de Chiapas, México
                                <br />
                                <br />
                                <Video
                                    src={
                                        "https://www.youtube.com/embed/kkjR0ZyM2jM"
                                    }
                                />
                                <br />
                                <br />
                                -------------------------------------------------------------------------------------------------
                                <br />
                                <br />
                                Encararemos la celebración de esta fecha,
                                reconociendo nuestra ignorancia en relación a la
                                vida y pensamiento de las poblaciones
                                prexistentes en nuestras tierras.
                                <br />
                                Entonces este capítulo nos ubica a los
                                educadores en un rol interesante de la docencia,
                                cuando elegimos que nuestros chicos aprendan
                                algo que no sabemos.
                                <br />
                                Una maestra de música joven y llena de ilusiones
                                descolonizadoras se preparó practicando
                                canciones mapuches y se fue al sur a trabajar en
                                una comunidad. Casi llorando nos contó que iba a
                                tener que dejar. Sus pequeños estudiantes se
                                portaban bien pero no cantaban, Y además le
                                pedían que cante en inglés. El grupo de
                                capacitación la ayudó y le propuso que hiciera
                                trueque, que les cante algo en inglés y que les
                                pida a ellos que canten en mapuzundún. Y
                                funcionó. Obviamente ella educada en colegios
                                bilingües cantaba muy bien a la europea y muy
                                mal a la americana. Ellos la obligaron a aceptar
                                quién era sin culpas. Y así empezó el vínculo
                                real entre ellos.
                                <br />
                                Cuando tocó el kultrun que había comprado se
                                reían. Le dijeron “no es un kultrun”. Al tiempo
                                la familia trajo un kultrun y cuando ella quiso
                                probarlo dijeron: “no lo toque, es un kultrun,
                                el suyo es un tambor”.
                                <br />
                                Tenían el mismo aspecto y en occidente solemos
                                definir los objetos por su aspecto. Ella
                                aprendió que este instrumento se definía por su
                                sentido y su uso. Su tambor era hermoso para un
                                escenario y el kultrun requería un ritual.
                                <br />
                                <br />
                            </div>
                        </div>
                    </Container>
                </CollapsableSection>
            </div>
        </div>
    );
};

export default SeventeenAugustFestScreen;
