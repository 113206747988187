import React, { useState } from "react";
import { StyleSheet, css } from "aphrodite";
import Img from "./Img";

const styles = StyleSheet.create({
    audioPlayer: {
        minHeight: 50,
        display: "flex",
        alignItems: "center",
        marginBottom: 20,
        "@media(max-width: 450px)": {
            fontSize: 14,
        },
    },
    Hidden: {
        display: "none",
    },
    Player: {
        color: "#0f6cfc",
        fontFamily: "Work Sans",
        fontWeight: 500,
        cursor: "pointer",
        marginRight: 10,
        textDecoration: "underline",
        textDecorationColor: "#0f6cfc",
    },
    Margin: {
        marginLeft: 20,
    },
    MarginPlay: {
        marginRight: 5,
    },
    nowar: {
        whiteSpace: "nowrap",
    },
});

const AudioPlayer = ({ title, description, path, clave }) => {
    console.log(title, description, path, clave);
    const handleClick = () => {
        console.log("estas en el handleclick");
        const audio = document.getElementById(clave);

        if (audio.paused || audio.ended) {
            audio.play();
        } else {
            audio.pause();
        }
    };
    console.log(clave);
    return (
        <div className={css(styles.audioPlayer)}>
            {/*<span className={css(styles.Player)} onClick={handleClick}>
                <Img
                    className={css(styles.MarginPlay)}
                    src="/images/icono-play.png"
                    alt="icono de play"
                />
                <span> {title}</span>

                
                <audio className={css(styles.Hidden)} controls id={clave}>
                    <source src={path} type="audio/mp3" />
                </audio>
    </span>*/}
            <span
                className={css(styles.Player)}
                onClick={() => {
                    window.open(path);
                }}
            >
                <Img
                    className={css(styles.MarginPlay)}
                    src="/images/icono-play.png"
                    alt="icono de play"
                />
                <span> {title}</span>
            </span>
            <span>{description}</span>
            <a download href={path}>
                <Img
                    src="/images/icono-descarga.png"
                    alt="icono de descargar"
                    className={css(styles.Margin)}
                />
            </a>
        </div>
    );
};

export default AudioPlayer;
