import React, { useRef, useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook"; //control de teclas
import Recorder from "../recorder";

import { faPlay, faStop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "../base.css";

//3:00
const tracksURL = [
  "media/sonidos25DeMayo/Acustico - Bombo.mp3", //0
  "media/sonidos25DeMayo/Acustico - Guitarra.mp3", //1
  "media/sonidos25DeMayo/Electrico - Bajo.mp3", //2
  "media/sonidos25DeMayo/Electrico - Guitar.mp3", //3
  "media/sonidos25DeMayo/Percu - Cencerro.mp3", //4
  "media/sonidos25DeMayo/Percu - Semillas.mp3", //5
];
const trackName = [
  "Bombo",
  "Guitarra Criolla",
  "Bajo",
  "Guitarra Eléctrica",
  "Cencerro",
  "Semillas",
];

const ListaKeyTrack = [
  "q", //0
  "w", //1
  "a", //2
  "s", //3
  "z", //4
  "x", //5
];

const ListaKeyTrackImagenes = {
  q: {
    imgAct: <img className="imagesBeat" src="/images/letras/q-activo.png" />,
    imgInact: <img className="imagesBeat" src="/images/letras/q.png" />,
  }, //0
  w: {
    imgAct: <img className="imagesBeat" src="/images/letras/w-activo.png" />,
    imgInact: <img className="imagesBeat" src="/images/letras/w.png" />,
  }, //1
  a: {
    imgAct: <img className="imagesBeat" src="/images/letras/a-activo.png" />,
    imgInact: <img className="imagesBeat" src="/images/letras/a.png" />,
  }, //2
  s: {
    imgAct: <img className="imagesBeat" src="/images/letras/s-activo.png" />,
    imgInact: <img className="imagesBeat" src="/images/letras/s.png" />,
  }, //3
  z: {
    imgAct: <img className="imagesBeat" src="/images/letras/z-activo.png" />,
    imgInact: <img className="imagesBeat" src="/images/letras/z.png" />,
  }, //4
  x: {
    imgAct: <img className="imagesBeat" src="/images/letras/x-activo.png" />,
    imgInact: <img className="imagesBeat" src="/images/letras/x.png" />,
  }, //5
};

const Track = ({ track, letra, callbackKey }) => {
  const [playing, setPlaying] = useState(false);
  useHotkeys(
    letra,
    () => {
      setPlaying(!playing);
      callbackKey();
    },
    [playing, setPlaying, callbackKey]
  );

  const onclickButon = () => {
    setPlaying(!playing);
    callbackKey();
  };
  return (
    <tr>
      <td className="destacado item-tabla">{track}</td>
      {!playing ? (
        <>
          <td className=" item-tabla">
            {" "}
            {/* <span className=" destacado button-off centrado-boton">
              {letra.toUpperCase()}
            </span> */}
            <div onClick={() => onclickButon()}>
              {ListaKeyTrackImagenes[letra].imgInact}
            </div>
          </td>

          <td className="item-tabla">
            {" "}
            <div onClick={() => onclickButon()}>
              {" "}
              <img className="imagesBeat" src="/images/letras/play.png" />{" "}
            </div>
          </td>

          <td>
            <div className="div-wave ">
              <span className="span-wave-off"></span>
            </div>
          </td>
        </>
      ) : (
        <>
          <td className=" item-tabla">
            {" "}
            {/* <span className=" destacado button-on centrado-boton">
              {letra.toUpperCase()}
            </span> */}
            <div onClick={() => onclickButon()}>
              {ListaKeyTrackImagenes[letra].imgAct}
            </div>
          </td>
          <td className="item-tabla">
            {" "}
            <div onClick={() => onclickButon()}>
              {" "}
              <img className="imagesBeat" src="/images/letras/stop.png" />{" "}
            </div>
          </td>

          <td>
            <div className="div-wave div-wave-off">
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
              <span className="span-wave"></span>
            </div>
          </td>
        </>
      )}
    </tr>
  );
};
const Mixer25DeMayo = () => {
  //setup
  const context = useRef();
  const bufferLoader = useRef();
  const pannerNode = useRef();

  const [load, setLoad] = useState(false); // cuando este cargado se muestra el data.
  const [isPlaying, setIsPlaing] = useState(false); // cuando este cargado se muestra el data.

  //sonidos
  const sourceLista = useRef([]);
  const bufferLista = useRef([]);
  const gainNodeList = useRef([]);
  const gainValueList = useRef(
    tracksURL.map(() => {
      return false;
    })
  ); //es para la lista de estados de los sonidos

  const [testState, setTestState] = useState(
    tracksURL.map(() => {
      return false;
    })
  );

  //valida si esta corriendo o no el sonido
  const playing = useRef(false);

  //valida si esta grabando
  const recording = useRef(false);

  //recorder obj
  const recorder = useRef();
  //url de mix grabado
  const [recordedMixUrl, getRecordedMixUrl] = useState();

  const [test, setTest] = useState(0);

  const init = () => {
    window.AudioContext = window.AudioContext || window.webkitAudioContext;

    context.current = new AudioContext();

    // Create a panner node.
    pannerNode.current = context.current.createPanner();
    pannerNode.current.panningModel = "equalpower";

    bufferLoader.current = new BufferLoader(
      context.current,
      tracksURL,
      finishedLoading
    );

    bufferLoader.current.load();
  };

  const setSonidosToSource = () => {
    console.log("en la funcion de source");
    bufferLista.current.forEach((sonido, index) => {
      let source = context.current.createBufferSource();
      var gainNode = context.current.createGain();
      source.buffer = sonido; //buffer.
      source.loop = true; // repetible.

      gainNode.gain.value = gainValueList.current[index] ? 1 : 0;

      gainNode.connect(pannerNode.current);

      // now instead of connecting to aCtx.destination, connect to the gainNode
      source.connect(gainNode);

      //guardo
      sourceLista.current[index] = source;
      gainNodeList.current[index] = gainNode;
    });

    pannerNode.current.connect(context.current.destination);
    return true; //todo cargado
  };

  const finishedLoading = (bufferList) => {
    bufferLista.current = bufferList;

    if (setSonidosToSource()) {
      console.log("fin--------");
      setLoad(true);
    }
  };

  const stopAllTracks = () => {
    sourceLista.current.forEach((source) => {
      if (source) {
        source.disconnect();
        source.stop(0);
        source = null;
      }
    });
    gainNodeList.current.forEach((gainNode) => {
      if (gainNode) {
        gainNode.disconnect();
        gainNode = null;
      }
    });

    pannerNode.current.disconnect();
    playing.current = false;
    gainNodeList.current = [];
    sourceLista.current = [];
  };

  const playSource = () => {
    sourceLista.current.map((source) => {
      return source.start(0);
    });
  };

  const setPlayStop = () => {
    setIsPlaing(!isPlaying);
    if (playing.current) {
      stopAllTracks();
      playing.current = false;
    } else {
      setSonidosToSource();
      playSource();
      playing.current = true;
    }
  };

  const muteTrack = (key) => {
    if (!playing.current) {
      setPlayStop();
    }
    const selectdNode = gainNodeList.current[key];
    // let listita = [];
    //setTestState((testState) => (listita = testState));
    console.log(key, selectdNode);
    if (selectdNode) {
      //se valida por si no esta cargado el sonido antes de apretar cualquier tecla
      if (selectdNode.gain.value === 1) {
        selectdNode.gain.value = 0;
        //gainValueList.current[key] = false;
        gainValueList.current[key] = false;
        // listita[key] = false;
      } else {
        selectdNode.gain.value = 1;
        gainValueList.current[key] = true;
        //  listita[key] = true;
      }
      // setTestState(() => listita);
      // setTest(Math.random() * (100 - 1) + 1);
      console.log("gainValueList", gainNodeList);
    }
  };

  const isInit = useRef(false);
  useEffect(() => {
    if (!isInit.current) {
      console.log("init");
      isInit.current = true;
      init();
    }
  }, [init]);

  function BufferLoader(context, urlList, callback) {
    this.context = context;
    this.urlList = urlList;
    this.onload = callback;
    this.bufferList = new Array();
    this.loadCount = 0;
  }

  BufferLoader.prototype.loadBuffer = function (url, index) {
    // Load buffer asynchronously
    var request = new XMLHttpRequest();
    request.open("GET", url, true);
    request.responseType = "arraybuffer";

    var loader = this;

    request.onload = function () {
      loader.context.decodeAudioData(
        request.response,
        function (buffer) {
          if (!buffer) {
            alert("error con el archivo: " + url);
            return;
          }
          loader.bufferList[index] = buffer;
          if (++loader.loadCount == loader.urlList.length)
            loader.onload(loader.bufferList);
        },
        function (error) {
          console.error("decodeAudioData error", error);
        }
      );
    };

    request.onerror = function () {
      alert("BufferLoader: XHR error");
    };

    request.send();
  };

  BufferLoader.prototype.load = function () {
    for (var i = 0; i < this.urlList.length; ++i)
      this.loadBuffer(this.urlList[i], i);
  };

  return (
    <div className="cuerpo">
      <header className="estilo-header">
        <div className="div-logo-header">
          <img
            src="/images/logo-y-sello.png"
            className="logo-header"
            alt="logo"
          ></img>
        </div>
        <div className="div-conteiner-header-img2 ">
          <div className="_3hmsj">
            <img
              src="/images/suri.png"
              className="img2-header"
              alt="suri"
            ></img>
          </div>
        </div>
      </header>

      <main className="centrado">
        <h1 className="titulo font-alegreya">Juego de loops</h1>
        <span className="font-alegreya subtitulo">
          –Podés combinarlos como quieras y armar tu base–
        </span>

        <ul className="list-style font-sans">
          <li>
            Si jugás desde una computadora, podés activar los sonidos con el
            teclado, eligiendo la letra que le corresponde a cada uno
          </li>
          <li>
            Si jugás desde un celular o tablet, podés activar los sonidos
            tocando el botón Play de cada sonido
            <img className="buttonSubtitle" src="/images/letras/play.png" />
          </li>
        </ul>

        {load ? (
          <>
            <table className="centrado-tabla font-sans">
              <tbody>
                {tracksURL.map((track, index) => {
                  return (
                    <Track
                      track={trackName[index]}
                      letra={ListaKeyTrack[index]}
                      callbackKey={() => muteTrack(index)}
                      key={index}
                    />
                  );
                })}
              </tbody>
            </table>{" "}
          </>
        ) : (
          "CARGANDO..."
        )}
      </main>
    </div>
  );
};

export default Mixer25DeMayo;
/*
 {recordedMixUrl === "" ? (
          ""
        ) : (
          <a href={recordedMixUrl} download={"FiestaEscuela.wav"}>
            {"FiestaEscuela"}{" "}
          </a>
        )}
const clickRecord = () => {
    try {
      if (!recording.current) {
        recording.current = true;
        playing.current ? stopAllTracks() : console.log("");
        recorder.current = new Recorder(pannerNode.current);
        setSonidosToSource();
        playSource();
        playing.current = true;
        recorder.current.record();
      } else {
        stopRecording();
      }
    } catch (error) {
      console.log(error);
    }
  };

  function stopRecording() {
    if (recording.current) {
      recording.current = false;
      stopAllTracks();
      playing.current = false;

      recorder.current.exportWAV((blob) => {
        getRecordedMixUrl(window.URL.createObjectURL(blob));
      });
    }
  }

        */
