import React from "react";


import {
  Container,
  BannerPaper,
  BorderedButton,
  CollapsableSection,
  ImageBox,
  BandcampPlayer,
} from "../ui";
import { StyleSheet, css } from "aphrodite";
import { useState } from "react";
import { useEffect } from "react";
import Img from "../ui/components/Img";
import Video from "modules/home/Video";
import Table from "modules/ui/components/Table";
import AudioPlayer from "../ui/components/AudioPlayer";

const styles = StyleSheet.create({
  Content: {
    flex: 1,
    margin: "0",
    maxWidth: "100%",
    boxSizing: "border-box",
    minHeight: 100,
    backgroundColor: "#fff2a6",
    paddingBottom: 100,
    // height: 450,
    width: "100%",
    position: "relative",
  },
  Column: {
    flex: 1,
    //paddingTop: 44,
    width: 430,
    "@media(max-width: 795px)": {
      paddingTop: 0,
      paddingLeft: 14,
      paddingRight: 14,
      width: "auto",
      maxWidth: 500,
      margin: "auto",
    },
  },
  ColumnText: {
    fontSize: "1rem",
    textAlign: "center",
    fontFamily: "Work Sans",
    lineHeight: "24px",
  },
  ColumnContainer: {
    display: "flex",
    //flexDirection: "column",
    alignItems:"center",
    "@media(max-width: 795px)": {
      flexDirection: "column",
    },
  },
  MarginText: {
    marginRight: 40,
  },
  BannerImg: {
    maxWidth: 220,
    height:320,
    //marginTop: 20,
  },
  Title: {
    fontFamily: "Alegreya",
    fontSize: 74,
    fontWeight: "bold",
    "@media(max-width:795px)": {
      fontSize: "48px",
      lineHeight: "65px",
    },
  },
  SubTitle: {
    fontFamily: "Alegreya",
    fontSize: 36,
    whiteSpace:"nowrap",
    "@media(max-width:950px)": {
      whiteSpace:"normal",
    },
    "@media(max-width:795px)": {
      fontSize: "28px",
    },
  },
  Description:{
    fontFamily:"Work Sans",
    fontSize:"18px",
    lineHeight: "28px",
    fontWeight:500,
    marginTop:20,
  },
  FloatingBannerSpanTop: {
    width: "100%",
    marginBottom: 20,
    //textAlign: "center",
    fontFamily: "Work Sans, sans-serif;",
    fontWeight: 600,
    fontSize: "1rem",
    fontStyle: "italic",
    display: "block",
    whiteSpace: "nowrap",
    "@media(max-width: 500px)": {
      whiteSpace: "normal",
    },
  },
  FloatingBannerSpanBot: {
    width: "100%",
    marginTop: 20,
    textAlign: "center",
    fontFamily: "Work Sans, sans-serif;",
    fontSize: "1rem",
    fontStyle: "italic",
    display: "block",
    whiteSpace: "nowrap",
    "@media(max-width: 500px)": {
      whiteSpace: "normal",
    },
  },
  TitlesContainer: {
    textAlign: "center",
    marginTop: 60,
  },
  PlayerContainer: {
    marginTop: 40,
    marginBottom: 20,
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "500px",
  },
  ButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    marginTop: 72,
  },
  Button: {
    marginRight: 20,
    //marginBottom: 20,
    minHeight: 28,
    padding: "0px 10px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    "@media(max-width: 440px)": {
      marginBottom: 10,
      marginTop: 10,
    },
  },
  SectionsContainer: {
    marginTop: 50,
  },
  HighlightedItem: {
    paddingRight: 2,
    marginRight: 1,
    backgroundColor: "#FFF",
  },
  TextContent: {
    maxWidth: 900,
    width: "100%",
    fontSize: 18,
    fontFamily: "Work Sans",
    lineHeight: "28px",
  },TextBlue2: {
    fontFamily: "Alegreya",
    lineHeight: "34px",
    fontSize: 27,
    fontWeight:400,
    color: "#0f6cfc",
    marginTop: 50,
    marginBottom: 50,
  },
  TextContentContainer: {
    display: "flex",
    justifyContent: "center",
  },
  CenteredContent: {
    display: "flex",
    justifyContent: "center",
  },
  BlueText: {
    fontFamily: "Alegreya",
    lineHeight: "34px",
    fontSize: 27,
    fontWeight:400,
    color: "#0f6cfc",
    marginTop: 50,
    marginBottom: 50,
  },
  MarginTopContent: {
    marginTop: 40,
    marginBottom: 40,
  },
  PartituraText: {
    color: "#0f6cfc",
    fontFamily: "Work Sans",
    //fontWeight: 600,
    fontSize: 21,
  },
  LineaPartitura: {
    marginTop: 40,
    marginBottom: 20,
    fontFamily: "Alegreya",
    color: "#000",
    fontWeight: 500,
    fontSize: 27,
    "@media(max-width: 795px)": {
      lineHeight: "35px",
    },
  },

  NotaFlotante: {
       //position: "relative",
       top: -25,
       color: "#0f6cfc",
       fontFamily: "Work Sans",
       fontWeight: 600,
       fontSize: 20,
       /*LC */
       left:0,
       position: "absolute",
       width: "100%",
       textAlign:"center",
  },
  SilabaSubrayada: {
    textDecoration: "underline",
  },
  ImgAllWidth: {
    width: 700,
    marginBottom: 72,
    marginTop: 50,
  },
  ImgDefault: {
    marginBottom: 30,
    marginTop: 30,
  },
  BoldTitle: {
    fontFamily: "Alegreya",
    fontSize: 25,
    fontWeight: "bold",
    marginTop: 60,
    marginBottom: 20,
  },
  BoldTitleNoMarginTop: {
    fontFamily: "Alegreya",
    fontSize: 25,
    fontWeight: "bold",
    marginBottom: 20,
  },
  SemiBoldText: {
    fontWeight: 600,
  },
  TwoColumnsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    "@media(max-width: 939px)": {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  TwoColumnsColumnContainer: {
    marginTop: 62,
    paddingBottom: 20,
  },
  ImageColumnObra: {
    marginTop: 10,
    maxWidth: 427,
  },
  PictureBoxContainerText: {
    display:"flex",
    justifyContent:"space-evenly",
  },
  Desktop: {
    display: "block",
    "@media(max-width: 795px)": {
      display: "none",
    },
  },
  Responsive: {
    display: "none",
    "@media(max-width: 795px)": {
      display: "block",
    },
  },
  marginAuto: {
    display: "block",
    margin: "40px auto",
  },
  DobleBox:{
    display:"flex",

  },
  BannerTextMaestros:{
    //paddingBottom: 44,
    paddingTop: 50,
    fontSize: "1rem",
    fontFamily: "Work Sans",
    lineHeight: "24px",
  },
  width100:{
    width:"100%",
  },
  MarginTopButtom:{
    margin:"44px 0",
  },
  rela:{
    position:"relative",
  },
  nowrap:{
    whiteSpace:"nowrap",
    "@media(max-width: 400px)": {
      lineHeight: "70px",
    },
  },
  rotate180:{
    transform: "rotate(180deg)",
  },
  mixer:{
    textDecoration:"underline",
    color: "#0f6cfc",
    fontWeight: 600,
  },
  textCenter:{
      display:'flex',
      justifyContent: 'center'
  },
  CellTable:{
      border: 'white solid 4px',
      
  },
  DivTable:{
    "@media(max-width: 722px)": {
      overflowX:"scroll",
      overflowY: "hidden",
    },
    
  },Trasparente:{
    opacity:0
  },Visible:{
    opacity:1
  }
});

const mapWidthNotes = {
  F: 9.53,
  Bb: 28.08,
  C7: 27.41,
  G: 12.53,
  Dm: 33.86,
  A7: 25.05,
};

const Note = ({ text, note }: { text: string, note: string }) => {
  /*const marginRight = mapWidthNotes[note]
    ? -mapWidthNotes[note]
    : -1 * note.length * 16;
  const stylesNote = StyleSheet.create({
    Note: {
       left: -1 * (text.length * 12 - (text.length * 12 + marginRight) / 2),
      marginRight: marginRight, 
    },
  });*/
  if(text ==""){
    return(
    <div style={{display:"inline-block", position:"relative"}}>
        <span className={css(styles.SilabaSubrayada)}>{text}</span>
        <span className={css(styles.NotaFlotante,styles.rela)}>{note}</span>
      </div>
    )  
  }else{
    return (
      <div style={{display:"inline-block", position:"relative"}}>
        <span className={css(styles.SilabaSubrayada)}>{text}</span>
        <span className={css(styles.NotaFlotante)}>{note}</span>
      </div>
    );
  }
  
};

const TeacherDayFestScreen = () => {

  const [sectionSelected, setSectionSelected] = useState(null);
  const [windowSize, setWindowSize] = useState({
    windowWidth: window.innerWidth,
  });
  const [columnSelected, setColumnSelected] = useState(0);

  const managedState = windowSize?.windowWidth <= 795;

  const handleResize = (e) => {
    setWindowSize({ windowWidth: window.innerWidth });
  };

  const windowOpen= ()=>{
    window.open("files/PartituraLa-primavera-Vivaldi-y-Chamame.pdf");
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleToggle = (section) => {
    console.log(section);
    if (sectionSelected === section) {
      setSectionSelected(null);
      return;
    }
    setSectionSelected(section);
  };
  return (
    <div className={css(styles.Content)}>
      <BannerPaper>
        {/* <span className={css(styles.FloatingBannerSpan)}>
          Nuestra patria antes y ahora
        </span> */}
        <Container>
          <div className={css(styles.ColumnContainer)}>
            
            <span className={css(styles.BannerTextMaestros)}>
                  
            <span className={css(styles.FloatingBannerSpanTop)}> 
            <span>Dia del maestro y la maestra: </span>
            <br />
            <span> los lazos afectivos con la seño, con la profe y el profe.</span> </span>
            
              <Img
                src="/images/linea-historias-grandiosas.png"
                className={css(styles.width100,styles.rotate180)}
              />

              <span className={css(styles.FloatingBannerSpanBot)}> 
              El maestro... “es uno de los que se porta mejor en la clase”
              <br />
              “de noche él trabaja en una escuela de adúlteros”
              </span> 

            </span>            
              
              <Img
                className={css(styles.BannerImg)}
                src="/images/dia_del_maestro_1.png"
              />
            {/* <div
              className={css(
                styles.Column,
                styles.ColumnText,
                //styles.MarginText
              )}
            >
              
              
                
            </div> */}
            
          </div>
        </Container>
      </BannerPaper>
      <Container>
        <div className={css(styles.TitlesContainer)}>
          <div className={css(styles.Title)}>11 de septiembre</div>
          <div className={css(styles.Title)}>Cola de Amor</div>
          <div className={css(styles.SubTitle)}>
          –Cantando en coro. Las voces de cerca y de lejos–
          </div>
          <div className={css(styles.PlayerContainer)}>
            <BandcampPlayer
              trackSrc="track=2291828904"
              size="small"
              height={"42px"}
              width={"100%"}
            />
          </div>
          {/* <div className={css(styles.ButtonsContainer)}>
            <BorderedButton
              text={"descargar canción"}
              onClick={() => {
                window.open("files/rap-Independiente.mp3");
              }}
              styles={styles.Button}
            />
          </div> */}
          <div className={css(styles.ButtonsContainer)}>
            <BorderedButton
              text={"descargar partitura"}
              onClick={() => {
                window.open("files/cola_de_amor_en_c_mayor.pdf");
              }}
              styles={styles.Button}
            />
            <BorderedButton
              text={"descargar canción"}
              onClick={() => {
                window.open("files/fiesta_en_la_escuela_cola_de_amor_completo.mp3");
              }}
              styles={styles.Button}
            />
          </div>
        </div>
      </Container>
      <div className={css(styles.SectionsContainer)}>
        <CollapsableSection
          fixed={managedState}
          // opened={sectionSelected === "a"}
          /*toggle={() => {
            handleToggle("a");
          }}*/
          letter="A."
          title="Me toca el acto en una semana"
          pictureUrl="/images/letter_banner/a-me-toca-el-acto.png"
          pictureHoverUrl="/images/letter_banner/a-me-toca-el-acto-act.png"
        >
          <Container>
            <div className={css(styles.TextContentContainer)}>
              <div className={css(styles.TextContent)}>
              <div className={css(styles.BoldTitleNoMarginTop)}>Prepararando una actuación con poco tiempo</div>
              
              Para preparar una actuación con poco tiempo buscaremos la emoción del canto comunitario.
A veces en las fiestas, una maraña de frases trilladas deja escondida la profundidad de cada herida y 
cada gesto de cariño entre los estudiantes y los educadores.
                <br />
                <br />

                Entre las familias y algunos adultos de la escuela necesitamos organizar:
                <br />
                • un equipo que reparta textos de la canción.
                <br />
• otro equipo que pase esta grabación de la canción por los parlantes.
<br />
• otro que redacte textos para leer al final.
<br />
• y un grupo que quiera cantar y que se habrá preparado para lograr este efecto sencillo y mágico que
es venir entonando la canción suavemente desde lejos, y terminar cantando con todos adelante. 
<br />
<br />
Pediremos a todos los docentes que suban al escenario, se sienten en sillas, en el suelo, o se queden
de pie, porque les cantaremos para homenajearlos.
<br />
<br />
<span className={css(styles.HighlightedItem)}>1.</span>{" "}
                <span className={css(styles.SemiBoldText)}>
                Comenzaremos poniendo la grabación:      
                </span>    {" "} 
                   y se escuchará en los parlantes la introducción a la
canción. Algunos familiares se quedarán entre los chicos, cuidándolos, mientras otros repartirán en
ese momento copias del texto. 
<br />
<br />
<span className={css(styles.HighlightedItem)}>2.</span>{" "}
                <span className={css(styles.SemiBoldText)}>
                Empezaremos a cantar desde el público,
                </span>{" "}
                con quienes se vayan integrando espontáneamente, cuando
empieza el canto en la grabación:
<br />
<br />

<div className={css(styles.TextBlue2)}>
<p>

  No me queda bien estar fingiendo<br />
Aquí parado cualquier línea me deja bien<br />
A veces sin rumbo cola de amor<br />
Voy a buscarte espero aquí o me voy.<br />
<br />

Miran otros ojos alrededor<br />
No creo que alguien pueda robarme este amor<br />
Más de diez años más que los vientos más que un dolor<br />
No creo que alguien pueda robarme este amor<br />
<br />

(estribillo)<br />
Para poder seguir tengo que empezar todo de nuevo<br />
Un canto libre para la luna y para vos<br />
En una orilla todos los días descansará<br />
Y con un sueño en un lugar, y con un sueño en un lugar,<br />
y con un sueño en un lugar, te espero.</p>
</div>
<br />
<br />

<span className={css(styles.HighlightedItem)}>3.</span>{" "}
                <span className={css(styles.SemiBoldText)}>
                Escucharemos a quienes se acercan cantando desde atrás,
                </span>{" "}
                  mientras con un gesto de silencio desde
adelante, pedimos callar al público.
<br />
<br />
<span className={css(styles.HighlightedItem)}>4.</span>{" "}
                <span className={css(styles.SemiBoldText)}>
                Escucharemos este texto de la grabación:
                </span>{" "}
                  para seguir poder todo empezar de nuevo seguir todo
para poder seguir nuevo empezar para poder seguir tengo que empezar de nuevo todo de nuevo
tengo… empezar todo de… etc. Mientras van preparándose para subir solamente quienes van a
hablar al final.
<br />
<br />
<span className={css(styles.HighlightedItem)}>5.</span>{" "}
                <span className={css(styles.SemiBoldText)}>
                Volvemos a cantar todos juntos,
                </span>{" "}
                  integrando al grupo que llegó caminando desde atrás
                <br />
                <br />
                <p> <i className={css(styles.TextBlue2)}>
                Para poder seguir tengo que empezar todo de nuevo<br />
Un canto libre para la luna y para vos<br />
En una orilla todos los días descansará<br />
y con un sueño en un lugar, y con un sueño en un lugar,<br />
y con un sueño en un lugar, te espero.<br />
                  </i> </p>
<br />
<br />
<span className={css(styles.HighlightedItem)}>6.</span>{" "}
                <span className={css(styles.SemiBoldText)}>
                Cuando termina la grabación tres o cuatro familiares subirán al escenario y leerán deseos,
                </span>{" "}
                 promesas que querrían decirles a los docentes.<br />
Aquí les dejamos algunos gratificantes ejemplos para una emocionante fiesta escolar:<br />
• Queremos apoyarlos. Que estén contentos. Son muy importantes en la vida de nuestros hijos.<br />
• Vamos a leer todas las informaciones y notitas que manden.<br />
• Cuando no nos guste algo que hacen no vamos a enojarnos. Lo plantearemos educadamente (risas) en
el momento adecuado.<br />
• Somos personas con distintas especialidades y podemos ayudarlos cuando nos pidan, para resolver
asuntos de pintura, limpieza, adornos, cuestiones legales, de computación, jardinería, etc. Cuenten con
nosotros.
                <br />
                <br />

                <span className={css(styles.HighlightedItem)}>7.</span>{" "}
                <span className={css(styles.SemiBoldText)}>
                Y pónganse de pie porque los vamos a aplaudir.
                </span>{" "}
                <br />

              </div>
            </div>
          </Container>
        </CollapsableSection>

        <CollapsableSection
          fixed={managedState}
          
          //opened={sectionSelected === "b"}
          /*toggle={() => {
            handleToggle("b");
          }}*/
          letter="B."
          title="Voy a probar sobre la grabación"
          pictureUrl="/images/letter_banner/b-voy-a-probar.png"
          pictureHoverUrl="/images/letter_banner/b-voy-a-probar-act.png"
        >
          <Container>
            <div className={css(styles.TextContentContainer)}>
              <div className={css(styles.TextContent)}>
              <div className={css(styles.BoldTitleNoMarginTop)}>Tocando sobre la grabación, en karaoke</div>
              Contando con algunos ensayos, los chicos y las chicas harán una realización musical en vivo.
A veces estamos más seguros coordinando el canto sin ninguna grabación de base.
Y quienes se organizan bien con karaoke, dispondrán de este acompañamiento:<br />
                 <div
                  className={css(
                    styles.CenteredContent,
                    styles.MarginTopContent
                  )}
                >
                  <BorderedButton
                    text={"descargar karaoke"}
                    onClick={() => {
                      window.open("files/amor_karaoke.mp3");
                    }}
                    styles={styles.Button}
                  />
                </div> 


<ImageBox
                  textContainerStyles={styles.PictureBoxContainerText}
                  //styles={styles.ImgDefault}
                  pictureUrl="/images/dia_del_maestro_4.png"/> 

                
<br />
<br />
Se ubicarán en el escenario en tres grupos: A, B, y C. para cantar la canción tres veces
<br />
<br />
                <span className={css(styles.SemiBoldText)}>

                1º vez
                </span>{" "}
                <br />
                Canta la primera estrofa el grupo A, segunda estrofa el grupo B, y el estribillo, más suave, el grupo C
que está más atrás.
                <br />
                <br />
                <span className={css(styles.SemiBoldText)}>
                2º vez
                </span>{" "}
                <br />
                Un chico o chica de cada grupo, a quiénes les guste cantar como solistas, se ubicarán en el medio
uno al lado del otro mirando a la maestra, y repetirán la versión anterior cantando una parte cada
uno.

                <br />
                <br />
                <span className={css(styles.SemiBoldText)}>
                3º vez
                </span>{" "}
                <br />
                Se acercarán todos al borde del escenario mientras la maestra gira para mirar al público incitándolo
a incluirse, palmeando ritmo de candombe, mientras esta vez todos los chicos juntos cantan la
canción entera.
                <br />
                <br />


                Si el público no está muy apretado, pueden retirarse caminando entre la gente, ya sin la grabación,
mientras cantan varias veces “y con un sueño en un lugar, y con un sueño en un lugar, y con un
sueño en un lugar, te espero” yéndose hacia la salida.

          
                
              </div>
            </div>
          </Container>
        </CollapsableSection>

        <CollapsableSection
          
          fixed={managedState}
          // opened={sectionSelected === "c"}
          /* toggle={() => {
            handleToggle("c");
          }}*/
          letter="C."
          title="Aprendo la canción y me animo sin pista"
          pictureUrl="/images/letter_banner/c-aprendo-la-cancion.png"
          pictureHoverUrl="/images/letter_banner/c-aprendo-la-cancion-act.png"
        >
          <Container>
            <div className={css(styles.TextContentContainer)}>
              <div className={css(styles.TextContent)}>

              <div className={css(styles.BoldTitleNoMarginTop)}>Realización musical en vivo</div>
                
             
                <span className={css(styles.HighlightedItem)}>1.</span>{" "}
                <span className={css(styles.SemiBoldText)}>
                Los docentes que cantan con alegría armarán un canto grupal.
                </span>{" "}
                 De acuerdo a la edad de sus
coristas y a sus propios gustos, elegirán qué nivel de complejidad explorarán, o sea qué melodías de
acompañamiento (ostinatos melódicos) y segundas voces enseñarán, ya que todas suenan muy bien. <br />
Aquí pueden seleccionar y enviar a su grado las voces para que los chicos pueden ir practicando. 
               

                <div className={css(styles.MarginTopButtom)}>
                  <AudioPlayer
                  clave={"ost1"}
                  path={"files/ostinato_1.mp3"}
                  description={""}
                  title={"Ostinato 1 No creo que alguien"}
                  />
                  <AudioPlayer
                  clave="ost2"
                  path={"files/ostinato_2.mp3"}
                  description={""}
                  title={"Ostinato 2 Descansarán"}
                  />
                   <AudioPlayer
                  clave={"ost3"}
                  path={"files/ostinato_3.mp3"}
                  description={""}
                  title={"Ostinato 3 Uh Uh"}
                  />
                   <AudioPlayer
                  clave={"amo2"}
                  path={"files/amor_2.mp3"}
                  description={""}
                  title={"2ª voz Cola de amor"}
                  />
                   <AudioPlayer
                  clave={"amo3"}
                  path={"files/amor_3.mp3"}
                  description={""}
                  title={" 3ª voz Cola de amor"}
                  />
                </div>
                

                Pueden descargar la partitura quienes se organizan mejor leyendo, recordando que nuestra hermosa
escritura europea nos complica, casi nos asusta cuando tenemos que leer síncopas y varias voces.<br />
Y el miedo no es buen amigo para disfrutar de las actividades.
<br />
<br />

                <span className={css(styles.HighlightedItem)}>2.</span>{" "}
                <span className={css(styles.SemiBoldText)}> Para maestras y maestros de 1°, 2° y 3° grado, </span>{" "}
                recomendamos adaptaciones más sencillas y divertidas.<br />
Percusión corporal:<br />
• Pies, con pulso del caminar<br />
• El mismo pulso con pecho y mano<br />
• Frotar rápido las manos<br />
                <br />
                
                <span className={css(styles.SemiBoldText)}>A quien le guste cantar, que cante! </span>{" "}<br />
                Pedimos a un papá o mamá a quien le guste cantar, que entone la primera estrofa y acompañamos
con el frotar. La segunda estrofa con pecho y mano. El estribillo, bien fuerte, con pies, como
bailando.
<br />
<br />
<span className={css(styles.SemiBoldText)}>Coro hablado </span>{" "}<br />
Cada chico y cada chica tiene un papel con unas palabras y vamos señalándolos para escucharlos
gritando individualmente: todo/ de nuevo/ un canto/ para la luna/ libre/ para vos/ de nuevo/ y se van
repitiendo las palabras en los papeles, que van cambiando el orden y los sentidos.<br />
Terminarán cantando todos:<br />
El estribillo, o solamente muchas veces “y con un sueño en un lugar te espero”.<br />
Les recomendamos realizar este canto grupal a voces de oído.
<br />
<br />

<span className={css(styles.HighlightedItem)}>3.</span>{" "}
<span className={css(styles.SemiBoldText)}>  Si sos de 7°, ahora sí podés coordinar un coro  </span>{" "}<br />
Como celebración del rol de educador es muy interesante que los grupos de más edad de la escuela
experimenten coordinar a los más pequeños.<br />
Así los de séptimo grado que quieran aprenderse un ostinato cantado, una segunda voz, o la
percusión corporal, se sentarán delante del subgrupo que dirigirán para que logren cantar en el lugar
preciso y con la melodía exacta.<br />
El docente que coordina todo comprobará que se pueden hacer arreglos corales complejos contando
con “subdirectores”.<br /><br />
Los de séptimo grado que no se interesan en coordinar cantando, realizarán otras tareas, como
conseguir gorras para los de segunda voz, gorro de lana para los que cantan un ostinato, sombrero
para los de otro ostinato, vincha para quienes cantan la primera voz, etc.<br />
O en vez de gorro pueden inventar collares de papel de color distinto para cada director o directora y
su subgrupo de chicos.
<br />
<br />
<ImageBox
                  textContainerStyles={styles.PictureBoxContainerText}
                  //styles={styles.ImgDefault}
                  pictureUrl="/images/dia_del_maestro_2_rb.png"/> 

<br />
              Es muy hermoso escuchar las distintas voces del arreglo viendo las diferencias visuales.
Todos los grados suelen admirar a los de séptimo y les encanta sentirse atendidos por ellos.
A su vez suele ser conmovedor ver en ese rol cariñoso a los que se están despidiendo de la escuela
primaria.
<br />
<br />
              </div>
             

            </div>
          </Container>
        </CollapsableSection>
        <CollapsableSection
          
          fixed={managedState}
          // opened={sectionSelected === "d"}
          /* toggle={() => {
            handleToggle("d");
          }}*/
          letter="D."
          title="Me encanta hacer algo distinto"
          pictureUrl="/images/letter_banner/d-me-encanta-hacer.png"
          pictureHoverUrl="/images/letter_banner/d-me-encanta-hacer-act.png"
        >
          <Container>
            <div className={css(styles.TextContentContainer)}>
              <div className={css(styles.TextContent)}>
                <div className={css(styles.BoldTitleNoMarginTop)}>Otras ideas de aplicación</div>


                <span className={css(styles.HighlightedItem)}>1.</span>{" "}
<span className={css(styles.SemiBoldText)}> Con otra canción  </span>{" "}<br />

El mismo tipo de arreglo vocal en base a: percusión corporal, palabras cantadas (ostinatos
melódicos), segunda voz en alguna frase, solistas y juego con el texto hablado, puede hacerse con
otra canción que conozcamos más que este tema de León Gieco.<br />
Por ejemplo, “Yo vengo a ofrecer mi corazón” de Fito Paez.<br />
<br />
Inventar los ostinatos melódicos con ritmo de chacarera<br />
“quieéén quién dijo” (en secreto)<br />
<br />
“chun chun ... chun chun....” (bien grave)<br />
<br />
 <span className={css(styles.Trasparente)}>“tun... tun... </span> <span className={css(styles.Visible)}> qui </span>   <span className={css(styles.Trasparente)}> tun”</span><br />
 <span className={css(styles.Visible)}>“tun... tun... <span className={css(styles.Trasparente)}> qui </span> tun”</span><br />
 <br />
 “co raa zón mi co raa zón” (bien agudo)
                <br />
                <br />


                <span className={css(styles.HighlightedItem)}>2.</span>{" "}
<span className={css(styles.SemiBoldText)}> Un rap inventado  </span>{" "}<br />
Una maestra de Santa Fé, Cecilia Giraudo, y sus alumnos inventaron este rap. Lo interpretaban con
voces y percusión, o utilizando un tecladito para hacer la base rítmica y armonía de “lambada” en la
segunda parte. Podemos inventar cantar algunas partes.<br />
<br />
Se enoja si no estudio<br />
Mi maestra<br />
Me grita cuando corro<br />
Mi maestra<br />
Se enoja si no estudio, cuando corro, cuando hablo<br />
Oh...oh… mi maestra<br />
Le gusta dar tareas<br />
A mi maestra<br />
Le encanta que investigue<br />
A mi maestra<br />
Le divierten las lecciones<br />
A mi maestra<br />
Le gusta dar tareas, que investigue, las lecciones<br />
Oh… oh… mi maestra<br />
<br />
Lambada (en teclado)<br />
Me cuida y me da mimos<br />
Mi maestra<br />
Me ayuda y me aconseja<br />
Mi maestra<br />
Me quiere, es mi amiga<br />
Mi maestra<br />
Me cuida, me da mimos, me ayuda y me aconseja<br />
Me quiere, es mi amiga y yo la quiero también<br />
Oh… oh… mi maestra<br />
Oh… oh… mi maestra<br />
<br />
Todos los chicos se ponen a leer en voz alta y desordenadamente textos de sus cuadernos de clase<br />
¡¡Basta!!! (gritará fuerte alguno)<br />
<br />
Oh… oh… mi maestra<br />
¡¡Quiero a mi maestra!!!<br />
<br />
<span className={css(styles.HighlightedItem)}>3.</span>{" "}
                <span className={css(styles.SemiBoldText)}>Baño sonoro</span>{" "}<br />
              
                
                El regalo más hermoso que podemos hacer es dar nuestra voz. Este juego que algunos docentes han
llamado “baño sonoro” es muy fácil de realizar en el aula. Nosotros, quizás ayudados por el maestro
de plástica, o la vicedirectora o quien quiera, pediremos a la maestra que se siente en el suelo o
acueste sobre una manta, con los ojos cerrados. Saldremos con todos los chicos afuera del aula y
allí, en secreto, les contaremos que cada uno cantará suave, alguna canción: ¿vos cuál? ¿vos ya sabés
qué querés cantar? Entraremos en puntas de pie, mezclando suavemente todas las canciones
mientras cada uno va cantando la suya. De la mano, iremos acercando a algunos al oído de la
maestra para que susurre su canción, como un mimo. Cuando algún chico se ríe fuerte, por la
emoción que esto produce, nuestro ayudante lo lleva despacito afuera y ¡sin retarlo! esperan juntos a
que se le pase la risa para volver a entrar. Recordamos comentarios de maestros y maestras como
un momento inolvidable de su docencia, cuando recibieron tanto cariño durante este ejercicio. 
<br />
<br />

<ImageBox
                  textContainerStyles={styles.PictureBoxContainerText}
                  //styles={styles.ImgDefault}
                  pictureUrl="/images/dia_del_maestro_3.png"
                  
                /> 
                <br />
                <br />


                <span className={css(styles.HighlightedItem)}>4.</span>{" "}
                <span className={css(styles.SemiBoldText)}> Anécdotas para acompañar el regalo</span>{" "}<br />

                Las familias suelen hacer un gran esfuerzo organizativo y económico, para comprar los regalos del
día del maestro. En estas reuniones en la puerta de la escuela y llamadas de celulares algunos se
encargarán de recoger anécdotas del amor, esa palabra maltratada, que a veces en la vergüenza o la
alegría de los enamoramientos quedó guardada en sus propias historias.<br />
Por ejemplo, una nieta chiquita que preparó el dibujo de San Martín y se lo quería entregar “al de
gimnasia adorado” que se llamaba Martín. Un preadolescente que aprendió a zapatear como un
experto, para que la hermosa maestra de danzas lo tuviera abrazado mientras le enseñaba y él
mostraba sus logros.<br />
Antes de entregarle el regalo, entre varios, leen estas historias anunciando: Les contaremos algunas
historias de amor a la maestra o al maestro. Si alguno está enamorado, no diga nada. Son amores
secretos.<br />
<br />
              </div>
            </div>
          </Container>
        </CollapsableSection>
        <CollapsableSection
          fixed={managedState}
          
          // opened={sectionSelected === "d"}
          /* toggle={() => {
            handleToggle("d");
          }}*/
          letter="E."
          title="Fiesta por pantalla"
          pictureUrl="/images/letter_banner/e-acto-por-pantalla.png"
          pictureHoverUrl="/images/letter_banner/e-acto-por-pantalla-act.png"
        >
          <Container>
            <div className={css(styles.TextContentContainer)}>
              <div className={css(styles.TextContent)}>
                <div className={css(styles.BoldTitleNoMarginTop)}>
                Acto veloz “sorpresa”
                </div>
                
             
                Esta propuesta la puede coordinar algún familiar de nuestro grado, o el docente de música, o
cualquier adulto que quiera homenajear a la maestra o maestro en su día.<br />
Concertaremos con la docente un permiso para entrar a la reunión por pantalla en el horario de
clase aclarando que se irá a descansar 20 minutos.<br />
Entonces pediremos a los chicos y chicas que nos cuenten cosas que les gustan de esta maestra:
...una vez que ayudó, cuando los deja hacer algo que pidieron, o que es elegante y tiene tranquilidad
para explicar, o que hace chistes, o que un día pasó que… etc. Iremos anotando algo de estas cosas.<br />
Cuando vuelve la maestra a la reunión ayudamos a los que quieran ir contándole lo que charlamos y
después le regalamos la canción Cola de Amor aquí grabada. Cuando terminamos de escucharla le
cantamos varias veces con el desorden ruidoso del sonido por pantalla: “y con un sueño en un lugar,
te espero”.<br />
                
                <br />
                <br />
             
                <span className={css(styles.SemiBoldText)}> Celebración a preparar en varias semanas </span>{" "}<br />
                <br />
                Si tenemos un poco de tiempo siempre será un gusto ensayar y editar nuestra versión del arreglo
coral. <br />
• Enviaremos a todo el grado la canción completa y las grabaciones de las voces que hayamos
elegido para enseñarles. Pueden ser algunas o todas. <br />
• Para que practiquen sentirse cantantes haremos un juego muy divertido, para cantar fuerte o suave
y agudo o grave, y afinar con más comodidad. <br />
Escuchen estas grabaciones: 
                <div className={css(styles.MarginTopButtom)}>
                  <AudioPlayer
                  clave={"las"}
                  path={"files/LASpinetta.mp4"}
                  description={"(Luis Alberto Spinetta)"}
                  title={"Audio Nº 1"}
                  />
                  <AudioPlayer
                  clave="nla"
                  path={"files/NLafourcade.mp3"}
                  description={"(Natalia Lafourcade)"}
                  title={"Audio Nº 2"}
                  />
                  <AudioPlayer
                  clave="mac"
                  path={"files/MCaballe.mp3"}
                  description={"(Monserrat Caballé)"}
                  title={"Audio Nº 3"}
                  />
                  <AudioPlayer
                  clave="wos"
                  path={"files/Wos.mp4"}
                  description={"(Wos)"}
                  title={"Audio Nº 4"}
                  />
                  <AudioPlayer
                  clave="sos"
                  path={"files/MSosa.mp4"}
                  description={"(Mercedes Sosa)"}
                  title={"Audio Nº 5"}
                  />
                  <AudioPlayer
                  clave="pap"
                  path={"files/Pappo.mp4"}
                  description={"(Pappo)"}
                  title={"Audio Nº 6"}
                  />
                </div> 

                Después jugaremos a las adivinanzas: ¿adivinen a quién copio?<br />
Interpretando solamente la frase “y con un sueño en un lugar”, imitaremos con los gestos y nuestra
voz a alguno de los cantantes. Los maestros somos fáciles para payasear en las clases y nos
animaremos a hacer las voces más raras para romper el hielo.<br />
Escuchen lo que probó Simona en el juego:<br />


<div className={css(styles.MarginTopButtom)}>
                  <AudioPlayer
                  clave={"aud7"}
                  path={"files/Audio7.mp4"}
                  description={""}
                  title={"Audio Nº 7"}
                  />
                  <AudioPlayer
                  clave="aud8"
                  path={"files/Audio8.mp4"}
                  description={""}
                  title={"Audio Nº 8"}
                  />
                </div>

                Pediremos que jueguen a las adivinanzas los que se van animando a hacer estas caricaturas
musicales. Siempre cantando aproximadamente la misma frase de la canción, se ríen, y liberan sus
voces logrando avances técnicos.<br />
Es interesante comprobar que a cada chico le llama la atención algo distinto. Alguien imita un sonido
larguísimo y agudo, alguien el clima de descontrol, alguien la pronunciación de una letra R como Y.<br />
<br />
Aunque lo hagan algunos nomás, ya sabemos que cada cual protegido en el silencio de su pantalla
estará practicando o imaginando su voz en esas distintas maneras de cantar.<br />
<br />
• Cada chico elegirá después lo que quiera grabar: el canto, una segunda voz, percusión corporal o el
texto hablado. Para que vayan logrando cantar ajustadamente, es muy importante que no se sientan
obligados sino que elijan lo que les gusta grabar.<br />
<br />
• Recibiremos las grabaciones que lograron realizar con sus celulares, y con este material, y si es
necesario, cantando nosotros también, editaremos esta canción y se la enviaremos a toda la escuela,
de regalo.<br />
<br />
<br />
<span className={css(styles.SemiBoldText)}> A todo el mundo le gustan las canciones de amor. ¿O no? </span>{" "}<br />
<br />
• Pediremos a las familias que elijan una canción que les guste mucho, antigua o nueva, para
mandarnos la grabación.<br />
• Si se animan, cantarán y nos enviarán su versión cantada sobre esta música grabada.<br />
• Y si se animan más aún, -porque algunas familias son muy musicales-, cantarán un pedacito sin
ninguna grabación: con una guitarra o con las voces solamente. <br />
<br />
Nosotros recibiremos todo ese material de los que pudieron o quisieron hacerlo, y editaremos un
“collage”: una canción que empieza, apareciendo luego un estribillo de otra, un poco más larga la que
quizás eligieron varios hogares, o agruparemos primero las más románticas y después las rítmicas…
lo que nos resulte más agradable.<br />
<br />
Posibles títulos del “collage” para enviar a todos y todas los y las docentes:<br />
“Todo nuestro amor para ustedes”<br />
“Vamos educadores, los queremos”<br />
“Aquí mandamos toda nuestra pasión de amor para ustedes” <br />
<br />
Y lograremos plantar una sonrisa y emoción en los rostros de nuestros a veces cansados maestros.
Este tipo de “collage de recuerdos musicales” despierta en quienes lo escuchan una profunda
conexión con situaciones queridas. Y logra acercar y armar puentes entre las distintas culturas de las
familias y la escuela.<br />
<br />
<br />
<br />
<br />
              </div>
            </div>
          </Container>
        </CollapsableSection>
      </div>
    </div>
  );
};

export default TeacherDayFestScreen;
