import React, { useEffect, useState } from "react";
import { StyleSheet, css } from "aphrodite";
import { Link } from "react-router-dom";

const Img = ({
  className,
  src,
  alt,
  onClick,
}: {
  className?: string;
  src: string;
  alt?: string;
  onClick?: () => void;
}) => {
  return (
    <img
      src={process.env.PUBLIC_URL + src}
      className={className}
      alt={alt}
      onClick={onClick}
    />
  );
};
export default Img;
