import React from "react";
import { StyleSheet, css } from "aphrodite";
import Img from "../ui/components/Img";

const styles = StyleSheet.create({
  root: {},
  ImgBottom: {
    top: 0,
    width: "100%",
    position: "relative",
  },
  Content: {
    flex: 1,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    "@media(max-width:795px)": {
      flexDirection: "column",
    },
  },
  Column: {
    textAlign: "center",
    //justifyContent: "center",
    alignItems: "center",
    fontFamily: "Work Sans",
    fontSize: "1rem",
    flex: 1,
    paddingTop: 41,
    paddingBottom: 5,
    paddingLeft: 15,
    paddingRight: 15,
    display: "flex",
    flexDirection: "column",
    zIndex: 2,
    "@media(max-width:795px)": {
      paddingTop: 20,
    },
  },
  TitleColumn: {
    fontWeight: 600,
    fontFamily: "Work Sans",
    fontSize: "1rem",
    marginBottom: 0,
  },
  Separator: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    "@media(min-width: 796px)": {
      padding: 5,
      height: 100,
      top: 20,
    },
  },
  SeparatorImg: {
    position: "relative",
    "@media(min-width: 796px)": {
      zIndex: 1,
      height: 160,
      top: 30,
    },
    "@media(max-width: 795px)": {
      zIndex: 1,
      width: "70%",
      maxHeight: 2,
      marginTop: 20,
      marginBottom: 0,
    },
  },
  Desktop: {
    display: "block",
    "@media(max-width: 795px)": {
      display: "none",
    },
  },
  Responsive: {
    display: "none",
    "@media(max-width: 795px)": {
      display: "block",
    },
  },
});

const Separator = () => {
  return (
    <div className={css(styles.Separator)}>
      <Img
        className={css(styles.SeparatorImg, styles.Desktop)}
        src="/images/separador_columna.png"
        alt=""
      />

      <Img
        className={css(styles.SeparatorImg, styles.Responsive)}
        src="/images/separador_columna_responsive.png"
        alt=""
      />
    </div>
  );
};
const BannerColumnas = () => {
  return (
    <div className={css(styles.root)}>
      <div className={css(styles.Content)}>
        <div className={css(styles.Column)}>
          <div className={css(styles.TitleColumn)}>
            Un lugar de reunión comunitaria:
          </div>
          <div>
            maestros, estudiantes, los abuelos, el portero, las madres, los
            padres, hermanos... compartiendo.
          </div>
        </div>
        <Separator />
        <div className={css(styles.Column)}>
          <div className={css(styles.TitleColumn)}>Momento de evaluación:</div>
          <div>
            “¿se luce mi nene?”, “esta docente de música es pésima”, “la maestra
            de séptimo no sabe hacer discursos”.
          </div>
        </div>
        <Separator />
        <div className={css(styles.Column)}>
          <div className={css(styles.TitleColumn)}>
          Espacio de reflexión sobre nuestra historia:
          </div>
          <div>
            ¿qué sentimos? ¿qué sabemos de estas fechas “feriado” del almanaque?
          </div>
        </div>
      </div>
      <Img
        className={css(styles.ImgBottom)}
        src="/images/linea_banner_rasgado.png"
        alt="banner"
      />
    </div>
  );
};
export default BannerColumnas;
