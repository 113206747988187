import React, { useEffect, useState } from "react";
import { StyleSheet, css } from "aphrodite";
import { Link, useLocation } from "react-router-dom";
import { UseClickOutsideHook } from "../../hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import Img from "../components/Img";

const styles = StyleSheet.create({
  root: {
    zIndex: 100,
    //flex: 1,
    display: "flex",
    backgroundColor: "white",
    alignItems: "center",
    padding: "1.375rem 0.75rem",
    height: "",
    //transition: "0.6s",
    
    "@media(min-width: 1024px)": {
      paddingLeft: "1.5rem",
      paddingRight: "1.5rem",
    },
    "@media(min-width: 1200px)": {
      paddingLeft: "3.5rem",
      paddingRight: "3.5rem",
    },
    "@media(min-width: 1360px)": {
      paddingLeft: "5.625rem",
      paddingRight: "5.625rem",
    },
    /* "@media(max-width: 1385px)": {
      paddingLeft: 50,
      paddingRight: 50,
    },
    "@media(max-width: 795px)": {
      paddingRight: 10,
      paddingLeft: 10,
    },
    "@media(max-width: 595px)": {
      paddingRight: 5,
      paddingLeft: 5,
    }, */
  },
  Scrolled: {
    height: 30,
    //transition: "0.6s",
    position: "fixed",
    backgroundColor: "rgba(255,255,255,.90)",
    top: 0,
    left: 0,
    right: 0,
    transition: "height 600ms",
     
  },
  Fiesta: {
    color: "blue",
    //fontSize: "2.5rem",
    //marginLeft: 50,
    cursor: "pointer",
    /* "@media(max-width: 1024px)": {
      marginLeft: 10,
    }, */
  },
  Nav: {
    //flex: 1,
    display: "flex",
    justifyContent:"flex-end",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    "@media(min-width: 1024px)": {
      //justifyContent: "space-between",
    },
  },
  NavBtn: {
    marginLeft: 10,
    justifyContent: "center",
    alignItems: "center",
    whiteSpace: "nowrap",
    "@media(min-width: 1024px)": {
      marginLeft: 35,
    },
    "@media(min-width: 1360px)": {
      marginLeft: 60,
    },
  },
  NavBtnContent: {
    fontFamily: "Work Sans",
    textDecoration: "none",
    color: "black",
    fontWeight: 400,
    marginLeft: 0,
    display: "none",
    fontSize: "1rem",
    textTransform: "uppercase",
    cursor: "pointer",
    ":hover": {
      color: "#1d75fd",
      fontWeight: "bold",
    },
     "@media(min-width: 1024px)": {
      display: "flex",
    },
  },
  NavBtnContentResponsive: {
    marginLeft: 0,
    fontSize: 12,
    cursor: "pointer",
    display: "block",
    "@media(min-width: 1024px)": {
      display: "none",
    },
  },
  ImgLogo: {
    height: 60,
    transition: "0.6s",
    "@media(min-width: 1024px)": {
      //transition: "all .5s ease-in",
      height: 65,
    },
    "@media(min-width: 1200px)": {
      //transition: "all .5s ease-in",
      height: 80,
    },
    "@media(min-width: 1360px)": {
      //transition: "all .5s ease-in",
      height: 100,
    }, 
  },
  ImgLogoScrolled: {
    height: 55,
    transition: "0.6s",
    //transition: "all .5s ease-in",
    /* "@media(min-width: 1024px)": {
      height: 60,
    }, */
  },
  SuriLogo: {
    height: 55,
    transform: "scaleX(-1)",
    transition: "0.6s",
    "@media(min-width: 1024px)": {
      transform: "scaleX(1)",
      height: 60,
      marginLeft: 40,
    }, 
    "@media(min-width: 1200px)": {
      height: 75,
    },
    "@media(min-width: 1360px)": {
      height: 85,
      marginLeft: 70,
    },
  },
  ScrolledSuriLogo: {
    height: 50,
    transform: "scaleX(-1)",
    transition: "0.6s",
    "@media(min-width: 1024px)": {
      transform: "scaleX(1)",
      marginLeft: 35,
    },
  },
  SuriLogoContainer: {
    //marginLeft: 70,
    //marginRight: 50,
    /* "@media(max-width: 1024px)": {
      marginLeft: 0,
      marginRight: 0,
    }, */
  },
  DropdownTitle: {
    //transition: "all .5s ease-in",
    top: 19,
  },
  ScrolledDropdownTitle: {
    top: 9,
  },
  Active: {
    color: "#1d75fd",
    fontWeight: "bold",
  },
  MenuResponsiveIcon: {
    height: 22,
    marginLeft: 10,
    marginRight: 10,
  },
  MenuResponsiveIconScrolled: {
    height: 22,
    marginLeft: 10,
    marginRight: 10,
  },
  ResponsiveNavBar: {
    display: "block",
    backgroundColor: "rgba(255,255,255,.97)",
    position: "fixed",
    left: "0px",
    width: "100%",
    height: "100%",
    top: 105,
    zIndex: "inherit",
    /* "@media(max-width: 1024px)": {
      display: "block",
    }, */
  },
  ResponsiveNavBarScrolled: {
    top: 70,
  },
  CloseBtn: {},
  CloseBtnMenu: {
    display: "flex",
    justifyContent: "flex-end",
    flex: 1,
    paddingRight: 30,
  },
});

const navDropdownStyles = StyleSheet.create({
  Root: {
    position: "relative",
    height: 0,
    marginRight: -85,
    width: "85px",
    flex: 1,
    left: -112,
    zIndex: 99,
  },
  Title: {
    top: 19,
    transition: "all .5s ease-in",
    position: "relative",
    width: "inherit",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  DropDown: {
    width: 455,
    padding: 15,
    top: 53,
    backgroundColor: "white",
    position: "relative",
  },
  DropDownScrolled: {
    top: 35,
  },
  Opened: {
    visibility: "visible",
  },
  Closed: {
    visibility: "hidden",
  },

  DropdownItem: {
    fontSize: "1rem",
    fontFamily: "Work Sans",
    padding: 10,
    fontWeight: 400,
    textTransform: "none",
    color: "#808080",
    cursor: "context-menu",
  },
  Available: {
    color: "#000",
    fontWeight: 400,
    cursor: "pointer",
    ":hover": {
      color: "#000",
    },
  },
});

const DropDownContent = ({
  opened,
  onClose,
  scrolled = false,
}: {
  opened: boolean;
  onClose: () => void;
  scrolled?: boolean;
}) => {
  const ref = UseClickOutsideHook(() => onClose());

  const scrollToTop = ()=>{
    console.log("estas en el scroll")
    window.scrollTo({
      top:0,
      left:0,
    })
    
  }
  const location = useLocation();

  return (
    <div
      ref={ref}
      className={css(
        navDropdownStyles.DropDown,
        scrolled ? navDropdownStyles.DropDownScrolled : undefined,
        opened ? navDropdownStyles.Opened : navDropdownStyles.Closed
      )}
      
    >
      <Link to='/fiesta_25_mayo'>
        <div
          className={css(
            navDropdownStyles.DropdownItem,
            navDropdownStyles.Available,
            location.pathname === "/fiesta_25_mayo"
            ? styles.Active
            : undefined 
          )}
          onClick={()=> scrollToTop()}
          //onTouchStart={()=> window.screen.width < 1040 && scrollToTop()}
        >
          25 de Mayo. Chacarera de la revolución
        </div>
      </Link>
      <Link to='/fiesta_20_junio'>
      <div className={css(navDropdownStyles.DropdownItem,
            navDropdownStyles.Available,location.pathname === "/fiesta_20_junio"? styles.Active
            : undefined )}
            onClick={()=>  scrollToTop()}
            //onTouchStart={()=> window.screen.width < 1040 && scrollToTop()}
        >
        20 de junio. Chamamé de la bandera
      </div>
      </Link>
      <Link to='/fiesta_9_julio'>
      <div className={css(navDropdownStyles.DropdownItem,
            navDropdownStyles.Available, location.pathname === "/fiesta_9_julio"
            ? styles.Active
            : undefined )} onClick={()=>  scrollToTop()} 
            //onTouchStart={()=> window.screen.width < 1040 && scrollToTop()}
            >
        9 de julio. Rap independiente
      </div>
      </Link>
      <div className={css(navDropdownStyles.DropdownItem)}>
        17 de agosto. Marcha de san lorenzo
      </div>
      <div className={css(navDropdownStyles.DropdownItem)}>
        11 de septiembre. Cola de amor
      </div>
      <div className={css(navDropdownStyles.DropdownItem)}>
        12 de octubre. Cuento "El cautivo" de J. L. Borges
      </div>
    </div>
  );
};

const Header = () => {
  let history = useHistory();

  const location = useLocation();

  const [scrolled, setScrolled] = React.useState(false);
  const [opened, setOpened] = useState(false);
  const [openedMenuResponsive, setOpenedMenuResponsive] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    
    if (offset > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  const scrollToTop = ()=>{
    console.log("estas en el scroll")
    window.scrollTo({
      top:0,
      left:0,
    })
  }

  return (
    <header
      className={css(styles.root, scrolled ? styles.Scrolled : undefined)}
    >
      <div className={css(styles.Fiesta)} 
      onClick={() =>{ history.push("/") 
      scrollToTop() }}>
        <Img
          className={css(
            
            scrolled ? styles.ImgLogoScrolled : styles.ImgLogo,
          )}
          src="/images/logo-y-sello.png"
          alt="logo"
        />
      </div>
      <div className={css(styles.Nav)}>
        <div
          className={css(
            styles.NavBtnContent,
            styles.NavBtn,
            location.pathname === "/" ? styles.Active : undefined
          )}
          onClick={()=>  scrollToTop()}
          //onTouchStart={()=> window.screen.width < 1040 && scrollToTop()}
        >
          <Link to='/'>El proyecto</Link>
        </div>

        <div
          className={css(
            styles.NavBtnContent,
            styles.NavBtn,
            //location.pathname === "/fiesta_25_mayo" ? styles.Active : undefined
          )}
        >
          <div 
          onClick={() => setOpened(!opened) }
          className={css(
            (location.pathname === "/fiesta_25_mayo")||(location.pathname === "/fiesta_20_junio")||(location.pathname === "/fiesta_9_julio") ? styles.Active : undefined
          )}
          >
            Las fiestas <FontAwesomeIcon icon={faAngleDown} />
          </div>
          <div className={css(navDropdownStyles.Root)}>
            {opened && (
              <DropDownContent
                onClose={() => setOpened(false)}
                scrolled={scrolled}
                opened={opened}
              />
            )}
          </div>
        </div>

        <div
          className={css(
            styles.NavBtnContent,
            styles.NavBtn,
            location.pathname === "/agradecimientos" ? styles.Active : undefined
          )}
          onClick={()=> scrollToTop()}
          //onTouchStart={()=> window.screen.width < 1040 && scrollToTop()}
        >
          <Link to='/agradecimientos'>Agradecimientos</Link>
        </div>
        <div
          className={css(
            styles.NavBtnContent,
            styles.NavBtn,
            location.pathname === "/contacto" ? styles.Active : undefined
          )}
          onClick={()=> scrollToTop()}
          //onTouchStart={()=> window.screen.width < 1040 && scrollToTop()}
        >
          <Link to='/contacto'>Contacto</Link>
        </div>

        <div className={css(styles.SuriLogoContainer)}>
          <Img
            className={css(
              scrolled ? styles.ScrolledSuriLogo : styles.SuriLogo
            )}
            src='/images/suri.png'
            alt='suri'
          />
        </div>
        <div className={css(styles.NavBtnContentResponsive, styles.NavBtn)}>
          <Img
            onClick={() => setOpenedMenuResponsive(!openedMenuResponsive)}
            src='/images/menu_responsive.png'
            className={css(
              scrolled
                ? styles.MenuResponsiveIconScrolled
                : styles.MenuResponsiveIcon
            )}
            alt='fiesta'
          />
        </div>
      </div>
      {openedMenuResponsive && (
        <div
          className={css(
            styles.ResponsiveNavBar,
            scrolled ? styles.ResponsiveNavBarScrolled : undefined
          )}
        >
          <div
            onClick={() => setOpenedMenuResponsive(false)}
            className={css(styles.CloseBtnMenu)}
          >
            <div className={css(styles.CloseBtn)}>X</div>
          </div>
          <div
            className={css(
              navDropdownStyles.DropdownItem,
              navDropdownStyles.Available,
              location.pathname === "/" ? styles.Active : undefined
            )}
            onClick={()=> scrollToTop()}
            //onTouchStart={()=> window.screen.width < 1040 && scrollToTop()}
          >
            <Link to='/'>EL PROYECTO</Link>
          </div>

          <div
            className={css(
              navDropdownStyles.DropdownItem,
              navDropdownStyles.Available,
              //location.pathname === "/fiesta_25_mayo"
                //? styles.Active
               // : undefined
            )}
          >
            <span className={css(
            (location.pathname === "/fiesta_25_mayo")||(location.pathname === "/fiesta_20_junio")||(location.pathname === "/fiesta_9_julio") ? styles.Active : undefined
          )}>LAS FIESTAS</span>
            <div
              className={css(
                navDropdownStyles.DropdownItem,
                navDropdownStyles.Available,
                location.pathname === "/fiesta_25_mayo"
                  ? styles.Active
                  : undefined 
              )}
              onClick={()=> scrollToTop()}
              //onTouchStart={()=> window.screen.width < 1040 && scrollToTop()}
            >
              <Link to='/fiesta_25_mayo'>
                25 de Mayo. Chacarera de la revolución
              </Link>
            </div>
            <div className={css(navDropdownStyles.DropdownItem,
            navDropdownStyles.Available,
             location.pathname === "/fiesta_20_junio"
              ? styles.Active
              : undefined )}
              onClick={()=>  scrollToTop()}
              //onTouchStart={()=> window.screen.width < 1040 && scrollToTop()}
              >
              <Link to='/fiesta_20_junio'>
              20 de junio. Chamamé de la bandera
              </Link>
            </div>
            <div className={css(navDropdownStyles.DropdownItem,
            navDropdownStyles.Available,
             location.pathname === "/fiesta_9_julio"
              ? styles.Active
              : undefined )}
              onClick={()=>  scrollToTop()}
              //onTouchStart={()=> window.screen.width < 1040 && scrollToTop()}
              >
              <Link to='/fiesta_9_julio'>
              9 de julio. Rap independiente
              </Link>
            </div>
            <div className={css(navDropdownStyles.DropdownItem)}>
              17 de agosto. Marcha de san lorenzo
            </div>
            <div className={css(navDropdownStyles.DropdownItem)}>
              11 de septiembre. Cola de amor
            </div>
            <div className={css(navDropdownStyles.DropdownItem)}>
              12 de octubre. Cuento "El cautivo" de J. L. Borges
            </div>
          </div>
          <div
            className={css(navDropdownStyles.DropdownItem,navDropdownStyles.Available,
              location.pathname === "/agradecimientos"
                ? styles.Active
                : undefined
            )}
            onClick={()=> scrollToTop()}
            //onTouchStart={()=> window.screen.width < 1040 && scrollToTop()}
          >
            <Link to='/agradecimientos'>AGRADECIMIENTOS</Link>
          </div>
          <div
            className={css(navDropdownStyles.DropdownItem,navDropdownStyles.Available,
              location.pathname === "/contacto" ? styles.Active : undefined
            )}
            onClick={()=> scrollToTop()}
            //onTouchStart={()=> window.screen.width < 1040 && scrollToTop()}
          >
            <Link to='/contacto'>CONTACTO</Link>
          </div>
        </div>
      )}
    </header>
  );
};
export default Header;
